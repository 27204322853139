import Types from "./types";

const INITIAL_STATE = {
  eng: {
    dates: [],
    loaded: false,
  },
  interview: {
    dates: [],
    loaded: false,
  },
  settingDate: false,
  errors: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_ENGDATES_SUCCESS:
      return {
        ...state,
        eng: { loaded: true, dates: action.payload },
        settingDate: false,
      };
    case Types.GET_ENGDATES_ERROR:
      return {
        ...state,
        eng: { dates: state.eng.dates, loaded: true },
        settingDate: false,
        errors: [...action.payload],
      };
    case Types.GET_ENGDATES_PROGRESS:
      return { ...state, eng: { loaded: false } };

    case Types.SET_DATES_PROGRESS:
      return { ...state, settingDate: true, errors: [] };

    case Types.GET_INTRDATES_SUCCESS:
      return {
        ...state,
        interview: { loaded: true, dates: action.payload },
        settingDate: false,
      };
    case Types.GET_INTRDATES_ERROR:
      return {
        ...state,
        interview: { loaded: true, dates: state.interview.dates },
        settingDate: false,
        errors: [...action.payload],
      };
    case Types.GET_INTRDATES_PROGRESS:
      return { ...state, interview: { loaded: false } };
    case Types.CLEAN_ERROR:
      return INITIAL_STATE;
    default:
      return state;
  }
};
