import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  settingMajor: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_MAJ_SUCCESS:
      return { errors: [], settingMajor: false };
    case Types.SET_MAJ_REQUEST:
      return { errors: [], settingMajor: true };
    case Types.SET_MAJ_ERROR:
      return { errors: [...action.payload], settingMajor: false };
    default:
      return state;
  }
};
