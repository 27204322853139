import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";
import Zoom from "@material-ui/core/Zoom";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { forgot } from "../../state/actions";
import { authRequest, cleanErrors } from "./../../state/actions";
import MuiTextField from "../../../common/components/muiTextField/MuiTextField";
import Logo from "../../../common/assets/images/Logo-sm.png";
import { useStyles } from "../login/loginStyles";
import { validate } from "./../../../common/helper/validate";
import { useTranslation } from "react-i18next";

const Forgot = ({
  handleSubmit,
  forgot,
  history,
  auth,
  authRequest,
  cleanErrors,
}) => {
  const classes = useStyles();
  const user = localStorage.getItem("user");
  // Language Control
  const [t, i18n] = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  useEffect(() => {
    cleanErrors();

    return () => {
      cleanErrors();
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    authRequest();
    forgot(values, () => history.push("/forgot/success"));
  };

  const renderForgot = () => (
    <div className={classes.login}>
      <Grid container justify="center">
        <Grid className={classes.inner}>
          <Zoom in={true} mountOnEnter unmountOnExit>
            <Paper elevation={3} className={classes.loginBox}>
              <div className={classes.div_lang}>
                <button
                  onClick={() => handleClick("en")}
                  className={`${classes.lang} ${selected === "en" && "selected"}`}
                >
                  EN
                </button>
                <button
                  onClick={() => handleClick("ar")}
                  className={`${classes.lang} ${selected === "ar" && "selected"}`}
                >
                  عربي
                </button>
              </div>
              <div className={classes.head}>
                <img className={classes.logo} src={Logo} alt="logo" />
              </div>

              <Typography
                align="center"
                variant="subtitle1"
                style={{ color: "gray", marginTop: "30px" }}
              >
                {t('forgot.title')}
              </Typography>
              <div className={classes.hrbreak}></div>
              <div className={classes.body}>
                {
                  <Typography variant="subtitle1" className={classes.error}>
                    {auth.error[0]}
                  </Typography>
                }

                <form
                  className={classes.form}
                  onSubmit={handleSubmit((values) => onSubmit(values))}
                >
                  <Field
                    name="email"
                    type="email"
                    label={t('forgot.email')}
                    required
                    component={MuiTextField}
                  />

                  <Field
                    name="national_id"
                    type="text"
                    required
                    label={t('forgot.national')}
                    component={MuiTextField}
                  />

                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      className={classes.submit}
                      color="primary"
                      disabled={auth.loading}
                      type="submit"
                    >
                      {t('forgot.submit')}
                    </Button>
                    {auth.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
              </div>

              <Link to={"/applicant/login"} className={classes.forgot}>
                {t('forgot.back')}
              </Link>
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
    </div>
  );

  const redirect = () => history.push("/");

  return <div>{user ? redirect() : renderForgot()}</div>;
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default compose(
  reduxForm({ form: "forgot", validate }),
  connect(mapStateToProps, { forgot, authRequest, cleanErrors })
)(Forgot);
