import Types from "./types";

const INITIAL_STATE = {
  fees: [],
  errors: [],
  loaded: false,
  settingFee: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_FEES_SUCCESS:
      return {
        ...state,
        loaded: true,
        fees: action.payload,
        settingFee: false,
        error: [],
      };
    case Types.GET_FEES_PROGRESS:
      return { ...state, loaded: false };
    case Types.GET_FEES_ERROR:
      return { ...state, loaded: true, errors: [...action.payload] };
    case Types.SET_FEES_REQUEST:
      return { ...state, loaded: true, errors: [], settingFee: true };
    case Types.SET_FEES_SUCCESS:
      // after set fee success push it to the fees array without duplicate
      // let filtredFees = state.fees.filter(
      //   (fee) => fee.payment_title !== action.payload.payment_title
      // );

      return {
        fees: action.payload,
        loaded: true,
        errors: [],
        settingFee: false,
      };
    case Types.SET_FEES_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingFee: false,
      };

    case Types.CLEAN_ERRORS:
      return { ...state, errors: [] };
    default:
      return state;
  }
};
