import axios from "axios";
import { API } from "../../../common/constants";

export const fetchStudents = async (filterParams) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/all/applicants?page=${filterParams.page + 1}&state=${
      filterParams.filter
    }&major=${filterParams.major}&semester=${filterParams.smster}&query=${
      filterParams.query
    }&gender=${filterParams.sex}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchAllEquations = async () => {
  const session = localStorage.getItem("user");

  const res = await axios.get(`${API}/equations`, {
    headers: { "auth-session": session },
  });

  return res;
};

export const deleteEquation = async (equation) => {
  const session = localStorage.getItem("user");

  const res = await axios.delete(`${API}/equations`, {
    data: equation,
    headers: { "auth-session": session },
  });

  return res;
};

export const editEquation = async (equation) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(`${API}/equations`, equation, {
    headers: { "auth-session": session },
  });

  return res;
};

export const newEquation = async (equation) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/equations`, equation, {
    headers: { "auth-session": session },
  });

  return res;
};

export const changeEquation = async (state) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(
    `${API}/equations/service/status?service_name=EQU&active=${state.active}`,
    state,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchEvalStudents = async (page, filter, query, major, smster) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/all/applicants?page=${
      page + 1
    }&state=${filter}&major=${major}&semester=${smster}&query=${query}&gender=all`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchUnReStudents = async (filterParams) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/all/unregistredapplicants?page=${
      +filterParams.page + 1
    }&semester=${filterParams.smster}&major=${
      filterParams.major
    }&contact_result=${filterParams.contactRes}&query=${filterParams.query}&gender=${filterParams.sex}`,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};

export const fetchAcceptedStudents = async (filterParams) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/applicant/finalAccept?page=${
      filterParams.page + 1
    }&state=${filterParams.filter}&query=${filterParams.query}&major=${
      filterParams.major
    }&semester=${filterParams.smster}&gender=${filterParams.sex}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchPaidStudents = async (filterParams) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/scholar/payment?page=${filterParams.page + 1}&from=${
      filterParams.payment.from
    }&to=${filterParams.payment.to}&gender=${filterParams.sex}&fees=${
      filterParams.fees
    }&query=${filterParams.query}&semester=${filterParams.smster}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchSpecialNeedStudents = async (filterParams) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/applicants/special-needs/?semester=${filterParams.smster}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchStudent = async (id) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(`${API}/admission/applicant?id=${id}`, {
    headers: { "auth-session": session },
  });

  return res;
};

export const fetchEvalStudent = async (id) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(`${API}/admission/applicant?id=${id}`, {
    headers: { "auth-session": session },
  });

  return res;
};

export const fetchUnReStudent = async (id) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/applicant/mailupdate?id=${id}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const fetchAcceptedApplicant = async (id) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(
    `${API}/admission/applicant/mailupdate?id=${id}`,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const review = async (files) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(`${API}/admission/applicant`, files, {
    headers: { "auth-session": session },
  });

  return res;
};

export const evaluate = async (data) => {
  const session = localStorage.getItem("user");

  if (+data.date_type === 2) {
    const res = await axios.post(`${API}/admission/grade/interview`, data, {
      headers: { "auth-session": session },
    });

    return res;
  } else {
    const res = await axios.post(`${API}/admission/grade/english`, data, {
      headers: { "auth-session": session },
    });
    return res;
  }
};

export const fetchDates = async (type, gender) => {
  console.log("the gender", gender);
  const session = localStorage.getItem("user");
if(gender){
  const res = await axios.get(`${API}/admission/dates/${type}?gender=${gender}`, {
    headers: { "auth-session": session },
  });
  return res;
}
else{
  const res = await axios.get(`${API}/admission/dates/${type}`, {
    headers: { "auth-session": session },
  });
  return res;
}
 


};

export const setNewDate = async (dates, type) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/admission/dates/save`, dates, {
    headers: { "auth-session": session },
  });

  return res;
};

export const delDate = async (date, type) => {
  const session = localStorage.getItem("user");

  const res = await axios.delete(`${API}/admission/dates/delete`, {
    data: date,
    headers: { "auth-session": session },
  });

  return res;
};

export const editCapacity = async (capacity, type) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(`${API}/admission/dates/update`, capacity, {
    headers: { "auth-session": session },
  });

  return res;
};

export const setContact = async (contact) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/admission/applicant`, contact, {
    headers: { "auth-session": session },
  });

  return res;
};

export const setExempt = async (data) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(
    `${API}/admission/applicant/equation/exempt/${data.id}`,
    data,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const editScores = async (scores) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(`${API}/admission/applicant/scores`, scores, {
    headers: { "auth-session": session },
  });

  return res;
};

export const saveNotes = async (notes) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/admission/applicant/notes`, notes, {
    headers: { "auth-session": session },
  });

  return res;
};


export const getNotes = async (id, type ) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(`${API}/admission/applicant/notes?id=${id}&type=${type}`, {
    headers: { "auth-session": session },
  });

  return res;
}
export const editPrios = async (scores) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(
    `${API}/admission/applicant/periorityupdate`,
    scores,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const email = async (body) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/admission/applicant/email`, body, {
    headers: { "auth-session": session },
  });

  return res;
};

export const updateApplicant = async (body) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(
    `${API}/admission/applicant/update?id=${body.id}`,
    body,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};

export const setAnnoun = async (data) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(`${API}/admission/anncmnt`, data, {
    headers: { "auth-session": session },
  });

  return res;
};

export const download = async () => {
  const session = localStorage.getItem("user");
  const res = await axios.get(`${API}/admission/applicant/export`, {
    headers: { "auth-session": session },
  });

  return res;
};

// create new user

export const addUser = async (values) => {
  const session = localStorage.getItem("user");
  const res = await axios.post(`${API}/admission/add/user`, values, {
    headers: { "auth-session": session },
  });

  return res;
};

//upload files

export const filesUpload = async (files) => {
  const id = files.get("id");
  files.delete("id");
  const session = localStorage.getItem("user");
  const res = await axios.post(
    `${API}/admission/applicant/upload?id=${id}`,
    files,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};

// upload the files missing
export const filesReUpload = async (files) => {
  const id = files.get("id");
  files.delete("id");
  const session = localStorage.getItem("user");
  const res = await axios.post(
    `${API}/admission/applicant/upload?id=${id}`,
    files,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};
// update existing files fromadmission
export const filesUpdation = async (files) => {
  const session = localStorage.getItem("user");
  const res = await axios.put(
    `${API}/admission/applicant/upload`,
    files,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};
//upload other files
export const UploadOtherFiles = async (
  files,
  otherExist,
  transcriptExist,
  editTranscript,
  editOthers,
  editEnglishCertif,
  englishExist
) => {
  const id = files.get("applicant_id");
  files.delete("applicant_id");
  const session = localStorage.getItem("user");
  if ((transcriptExist && editTranscript) || (otherExist && editOthers)) {
    const res = await axios.put(
      `${API}/admission/applicant/upload/other?applicant_id=${id}`,
      files,
      {
        headers: { "auth-session": session },
      }
    );
    return res;
  } else {
    const res = await axios.post(
      `${API}/admission/applicant/upload/other?id=${id}`,
      files,
      {
        headers: { "auth-session": session },
      }
    );
    return res;
  }
};

// document selection
export const documentSelection = async (docs) => {
  const session = localStorage.getItem("user");
  const res = await axios.post(
    `${API}/admission/certificate/add`,
    docs,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};

export const documentsGet = async (id) => {
  const session = localStorage.getItem("user");
  const res = await axios.get(
    `${API}/admission/certificate/submission?id=${id}`,
    {
      headers: { "auth-session": session },
    }
  );
  return res;
};

export const changeDocument = async (state) => {
  const session = localStorage.getItem("user");

  const res = await axios.put(
    `${API}/admission/certificate/submission`,
    state,
    {
      headers: { "auth-session": session },
    }
  );

  return res;
};