import Types from "./types";

const INITIAL_STATE = {
  students: false,
  loaded: false,
  error: "",
  cashListStudents:false,
  cashListStudentLoading: false,
  cashPaying:false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_PAID_STUDENTS_SUCCESS:
      return { ...state, loaded: true, students: action.payload, error: "" };
    case Types.GET_PAID_STUDENTS_ERROR:
      return { ...state, error: action.payload, loaded: true };
    case Types.GET_PAID_STUDENTS_PROGRESS:
      return { ...state, loaded: false };
    case Types.GET_STUDENT_BY_CASH_PROGRESS:
      return {...state,cashListStudentLoading: false }
      case Types.GET_STUDENT_BY_CASH_SUCCESS:
        return {...state, cashListStudentLoading: true, cashListStudents: action.payload}
      case Types.GET_STUDENTS_BY_CASH_FAILURE:
        return {...state, error: action.payload, cashListStudentLoading: true}
      case Types.PAID_BY_CASH_PROGRESS:
        return {...state,cashPaying: false }
      case Types.PAID_BY_CASH_SUCCESS:
        return {...state, cashPaying: true}
      case Types.PAID_BY_CASH_ERROR:
        return {...state, error: action.payload, cashPaying: true}
    case Types.CLEAN:
      return { ...state, loaded: false, students: false, error: "" };
    default:
      return state;
  }
};
