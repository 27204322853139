import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { useStyles } from "./dashBoardStyles";
import { withWidth } from "@material-ui/core";
// eslint-disable-next-line
import moment from "moment-timezone";
import "moment/locale/ar-sa";
import axios from 'axios';
import { heightGraph, currentView } from "./dashBoardAssist"
import Logo1 from "../../../../common/assets/images/1.png";
import Logo2 from "../../../../common/assets/images/2.png"
import { API } from "../../../../common/constants";
import { useTranslation } from 'react-i18next';

const SecondDashBoard = ({ width }) => {

  const classes = useStyles();
  const [date, setdate] = useState(new Date());
  const [data, setdata] = useState("");
  const { t, i18n } = useTranslation();

  let momentH = require("moment-hijri");
  momentH.locale("ar-sa");

  useEffect(() => {
    i18n.changeLanguage("ar");
    let time = setInterval(() => setdate(new Date()), 1000);
    axios.get(`${API}/system/report`).then(({ data }) => { setdata(data); });

    // Clean
    return () => {
      clearInterval(time);
    };
    // eslint-disable-next-line
  }, []);

  const dataDashBoard = (data) => {

    let Color = ["#6D8275", "#28a09f", "#3d5196", "#28a09f", "#3d5196", "#28a09f", "#3d5196", "#28a09f", "#3d5196", "#28a09f", "#3d5196", "#28a09f", "#6D8275"];
    let ColorTe = ["#6D8275", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#2A759A", "#6D8275",];
    let Data = [
      { name: t("dash.Dean_All"), n: 100, value: data["App_All"] },
      { name: t("dash.Dean_Ac"), n: 100, value: data["App_Ac"] },
      { name: t("dash.Dean_W"), n: 100, value: data["App_W"], },
      { name: t("dash.reg_CA"), n: 100, value: data["reg_CA"], },
      { name: t("dash.reg_IA"), n: 100, value: data["reg_IA"] },
      { name: t("dash.reg_UR"), n: 100, value: data["reg_UR"] },
      { name: t("dash.App_off_AC"), n: 100, value: data["App_off_AC"] },
      { name: t("dash.En_S"), n: 100, value: data["Eng_S_all"] },
      { name: t("dash.En_P"), n: 100, value: data["Eng_P"], },
      { name: t("dash.In_S"), n: 100, value: data["In_S"] },
      { name: t("dash.Paid_REG_S"), n: 100, value: data["Paid_REG_S"] },
      { name: t("dash.Paid_ERET_S"), n: 100, value: data["Paid_ERET_S"] },
      { name: t("dash.Paid_All"), n: 100, value: data["Paid_All"], },
    ]
    return ({ Data, Color, ColorTe });
  }

  let dataDash = dataDashBoard(data);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, color } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 200) * cos;
    const my = cy + (outerRadius + 200) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 20;
    const ey = my;

    return (
      <svg viewBox={currentView(width)} width="100%" height="100%">
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={0}
          endAngle={360}
          innerRadius={outerRadius + 29}
          outerRadius={outerRadius + 30}
          fill="#000000"
          opacity="0.01"
        />
        <Sector
          cx={mx + (cos >= 0 ? 1 : -1) * 90}
          cy={cy + (outerRadius + 200) * sin}
          startAngle={0}
          endAngle={360}
          innerRadius={42}
          outerRadius={52}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
          opacity={0.4}
        />
        <circle
          cx={mx + (cos >= 0 ? 1 : -1) * 20}
          cy={cy + (outerRadius + 200) * sin}
          r={5}
          fill={fill}
          stroke="none"
          opacity={0.3}
        />
        <text
          x={mx + (cos >= 0 ? 1 : -1) * 370}
          y={cy + (outerRadius + 240) * sin}
          dy={8}
          textAnchor="middle"
          className={classes.name}
          fill={color}
        >
          {payload.name}
        </text>
        <text
          x={mx + (cos >= 0 ? 1 : -1) * 90}
          y={cy + (outerRadius + 200) * sin}
          dy={8}
          textAnchor="middle"
          className={classes.value}
        >
          {payload.value}
        </text>
        <image
          x={cx - 35}
          y={cy - 105}
          href={Logo2}
          height={75}
          width={75}
        />
        <image
          x={cx - 75}
          y={cy - 98}
          href={Logo1}
          height="150px"
          width="150px"
        />
        <text x={cx - 60} y={cy + 30} width="200px" className={classes.time}>
          {momentH(date).tz("Asia/Riyadh").format("dddd h:mm:ss a")}
        </text>
        <text x={cx - 65} y={cy + 60} width="200px" className={classes.time}>
          {momentH(date).tz("Asia/Riyadh").format("DD/MMMM/YYYY")}
        </text>
        <text x={cx - 65} y={cy + 90} width="200px" className={classes.time}>
          {momentH(date).tz("Asia/Riyadh").format("iDD/iMMMM/iYYYY")}
        </text>
      </svg>
    );
  };

  return (
    <div className={classes.divContainer}>
      <ResponsiveContainer width={"100%"} height={heightGraph(width)}>
        <PieChart>
          <Pie
            activeIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            activeShape={renderActiveShape}
            data={dataDash.Data}
            animationDuration={1000}
            startAngle={270}
            endAngle={630}
            innerRadius={120}
            outerRadius={150}
            paddingAngle={3}
            dataKey="n"
          >
            {dataDash.Data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={dataDash.Color[index % dataDash.Color.length]} color={dataDash.ColorTe[index % dataDash.ColorTe.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default (withWidth()(SecondDashBoard));
