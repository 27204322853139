import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  checkoutReady: false,
  loading: false,
  resultLoaded: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.PAY_SUCCESS:
      return {
        errors: [],
        payment: action.payload,
        checkoutReady: true,
        loading: false,
      };
    case Types.PAY_REQUEST:
      return { errors: [], loading: true, checkoutReady: false };
    case Types.PAY_ERROR:
      return {
        errors: [...action.payload],
        checkoutReady: false,
        loading: false,
      };
    case Types.GET_PAY_SUCCESS:
      return {
        ...state,
        paymentResult: action.payload,
        resultLoaded: true,
      };
    case Types.GET_PAY_ERROR:
      return {
        ...state,
        resultLoaded: true,
        error: action.payload,
      };
    case Types.PAY_CLEAN:
      return INITIAL_STATE;

    default:
      return state;
  }
};
