export const heightGraph = (width) => {
  if (width === "xs") {
    return 300;
  } else if (width === "sm") {
    return 375;
  } else if (width === "md") {
    return 500;
  } else if (width === "lg") {
    return 700;
  }else return 935;
};

export const currentView = (width) => {
  if (width === "xs") {
    return "0 -500 350 1500";
  } else if (width === "sm") {
    return "0 -400 600 1100";
  } else if (width === "md") {
    return "0 -300 950 1000";
  }else if (width === "lg") {
    return "0 -200 1500 1250";
  } else return null;
};