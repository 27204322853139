import React, { Suspense } from "react";
import { unregister } from "./serviceWorker";
// import { register } from "./serviceWorker";
// import { Button, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./config/state/store";
import { BrowserRouter } from "react-router-dom";
import IndexRoutes from "./IndexRoutes.jsx";
import { BaseName } from "./common/constants";
import { theme } from "./common/assets/jss/appStyles";
// import i18n from "./i18n";
// import { toast, ToastContainer } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import "./common/assets/index.css";



ReactDOM.render(
  <BrowserRouter basename={BaseName}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <IndexRoutes />
        </Suspense>
      </Provider>
      <ToastContainer
        hideProgressBar={true}
        // limit={10}
        icon={true}
        closeButton={true}
      />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

unregister();

// const Msg = (props) => {
//   // props has more things to use
//   const isArabicLang = i18n.language === "ar";
//   return (
//     <div>
//       <Typography variant="body1">
//         {isArabicLang
//           ? "هناك نسخة جديدة من الموقع ,من فضلك اعد تحميل الصفحة للتحديث"
//           : "There is a new version of the app ready. Please reload to update."}
//       </Typography>
//       <Button variant="contained" style={{ backgroundColor: "#333542" }} onClick={() => window.location.reload(true)}>
//         {isArabicLang ? "تحديث" : "Update"}
//       </Button>
//     </div>
//   );
// };

// // Changes made here
// register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", (event) => {
//         if (event.target.state === "activated") {
//           // if (
//           //   window.confirm(
//           // i18n.language === "ar"
//           //   ? ".هناك نسخة جديدة من الموقع ,من فضلك اعد تحميل الصفحة للتحديث"
//           //   : "There is a new version of the app ready. Please reload to update."
//           //   )
//           // ) {
//           //   window.location.reload(true);
//           // }
//           //   window.location.reload(true);
//           toast.warn(<Msg />, {
//             autoClose: false,
//             closeOnClick: false,
//             pauseOnHover: true,
//             draggable: false,
//             closeButton: "false",
//           });
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   },
// });
