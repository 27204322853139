import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../common/assets/jss/appStyles";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Select } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Autocomplete } from "@material-ui/lab";
import { countryShortCode, phoneCode } from "../../helper/selecteLists";
// import classes from './PhoneInput.module.css';

const useStyls = makeStyles(() => ({
  root: {
    margin: "10px auto",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
  inputContainer: {
    maxWidth: 500,
  },
  fullWidth: {
    maxWidth: "100%",
  },
}));

const MuiTextField = ({
  label,
  type,
  input,
  disabled,
  required,
  placeholder,
  meta: { touched, invalid, error },

}) => {
  const [t] = useTranslation();
  const classes = useStyls();

  return (
    
      <Grid item xs={7}>
        <TextField
          placeholder={placeholder}
          className={classes.root}
          label={label}
          disabled={disabled}
          type={type}
          InputLabelProps={{
            shrink: true,
          }}
          required={required}
          margin="normal"
          variant="outlined"
          fullWidth
          helperText={touched && error && t(`validation.${error}`)}
          error={touched && invalid}
          {...input}
        />
       </Grid>
  );
};

export default MuiTextField;
