const GET_STUDENTS_SUCCESS = "get_intr_students_success";
const GET_STUDENTS_ERROR = "get_intr_students_error";
const GET_STUDENTS_PROGRESS = "get_intr_students_progress";

const GET_STUDENT_SUCCESS = "get_intr_student_success";
const GET_STUDENT_ERROR = "get_intr_student_error";
const GET_STUDENT_PROGRESS = "get_intr_student_progress";

const SET_GRADE_REQUEST = "set_interview_grades_request";
const SET_GRADE_ERROR = "set_interview_grades_error";
const SET_GRADE_SUCCESS = "set_interview_grades_success";

const CLEAN_ERROR = "clean_error";
const CLEAN = "clean";

export default {
  // all students
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // single student
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  SET_GRADE_REQUEST,
  SET_GRADE_ERROR,
  SET_GRADE_SUCCESS,
  // ===> CLEAN <===
  CLEAN_ERROR,
  CLEAN,
};
