import React from "react";
import { useTranslation } from "react-i18next";
// import fontCairo from "../../../../common/assets/fonts/Cairo/Cairo-Regular.ttf";
import font from "../../../common/assets/fonts/Amiri-Regular.ttf";
// import image from "../../../common/assets/images/1.png";
import image from "../../../common/assets/images/Logo-sm.png";
import { getSubMajors, getSubMajorsAr } from "../../helper/getMajorName";
// date
// eslint-disable-next-line
import moment from "moment-timezone";
import "moment/locale/ar-sa";

const RegisterationPDF = ({ doc, data, read, major }) => {
    console.log("the values", data, major)
    const [t, i18n] = useTranslation();
    let momentH = require("moment-hijri");
    if (i18n.language === "ar") {
        momentH.locale("ar-sa");
    } else {
        momentH.locale("en");
    }
    let xSize = 206;
    let ySize = 293;
    let alignDir = i18n.language === "ar" ? { align: 'right' } : { align: 'left' }
    let langXPositionTitle = i18n.language === "ar" ? 200 : 16;
    let langXPositionSubTitle = i18n.language === "ar" ? 200 : 16;
    let langXPositionSubTitle2 = i18n.language === "ar" ? 105 : 110;
    let yPositionStart = 42;
    let textWidth = 0;
    let mainTitleSize = () => doc.setFontSize(20);
    let subTitleSize = () => doc.setFontSize(12);
    let funMajor = i18n.language === "en" ? (f) => getSubMajors(f) : (f) => getSubMajorsAr(f);
    let signLang = (f) => i18n.language === "ar" ? (-f) : (+f);

    doc.addFont(font, "font", "normal");
    // doc.addFont(fontCairo, "fontCairo", "normal");

    const renderField = (FieldName, FieldValue, x, y) => {
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y, alignDir);
        doc.text(FieldValue ? "" + FieldValue : "", x + textWidth, yPositionStart + y, alignDir);
    }

    const renderSpokenField = (FieldName, FieldValue, x, y) => {
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y, alignDir);
        doc.text(FieldValue ? t(`pdf.${FieldValue}`) : "", x + textWidth, yPositionStart + y, alignDir);
    }

    const renderMobileField = (FieldName, FieldValue, x, y) => {
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y, alignDir);
        doc.text(read ? FieldValue ? `${FieldValue}` : "" : FieldValue ? FieldValue : "", x + textWidth, yPositionStart + y, alignDir);
    }

    const renderNotTagseer = (y) => {
        doc.text(t("register.labels.tagserQ") + " : " + t("register.labels.No"), langXPositionSubTitle, yPositionStart + y, alignDir);
    }

 const renderNotUniveristy = (y) => {
        doc.text(t("register.labels.preUniQ") + " : " + t("register.labels.No"), langXPositionSubTitle, yPositionStart + y, alignDir);
    }

    const renderTeagseerField = (y) => {
        // معهد التجسير
        textWidth = signLang(doc.getTextWidth(t("pdf.tagseer_institute")));
        doc.text(t("pdf.tagseer_institute"), langXPositionSubTitle, yPositionStart + 151 + y, alignDir);
        // doc.setFontSize(9);
        doc.text(data?.tagseer_institute, langXPositionSubTitle + textWidth, yPositionStart + 151 + y, alignDir);
        // subTitleSize();
        // تاريخ انتهاء التجسر
        renderField("pdf.tagseer_date", data?.tagseer_date, langXPositionSubTitle2, 151 + y);
        // المعدل التراكمي للتجسير
        renderField("pdf.tagseer_GPA", data?.tagseer_GPA, langXPositionSubTitle, 157 + y);
        // رقم تصنيف هيئة التخصصات
        renderField("pdf.tagseer_number", data?.tagseer_number, langXPositionSubTitle2, 157 + y);
        // سنة التخرج للتجسير
        renderField("pdf.tagseer_year", data?.tagseer_year, langXPositionSubTitle, 163 + y);
        // تخصص التجسير
        renderMajorField("pdf.tagseer_department", data?.tagseer_department, langXPositionSubTitle2, 163, y);
    }

    const renderUniveristyField = (y) => {
        // جامعة معهد كلية
        textWidth = signLang(doc.getTextWidth(t("pdf.ColUniv")));
        doc.text(t("pdf.ColUniv"), langXPositionSubTitle, yPositionStart + 145 + y, alignDir);
        // doc.setFontSize(9);
        doc.text(data?.previous_university ? "" + data?.previous_university : "", langXPositionSubTitle + textWidth, yPositionStart + 145 + y, alignDir);
        // subTitleSize();
        // نظام المعدل
        renderField("pdf.GPAType", data?.max_gpa, langXPositionSubTitle2, 145 + y);
        // المعدل
        renderField("pdf.previous_GPA", data?.previous_GPA, langXPositionSubTitle, 151 + y);
        // الحالة
        textWidth = signLang(doc.getTextWidth(t("pdf.State")));
        doc.text(t("pdf.State"), langXPositionSubTitle2, yPositionStart + 151 + y, alignDir);
        doc.text(data?.state_university ? `${t(`pdf.StateUniv.${data?.state_university}`)}` : "", langXPositionSubTitle2 + textWidth, yPositionStart + 151 + y, alignDir);
    }

    const renderRelation = (FieldName, FieldValue, x, y) => {
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y, alignDir);
        doc.text(FieldValue ? "" + t(`pdf.superior_relation_type.${FieldValue}`) : "", x + textWidth, yPositionStart + y, alignDir);
    }

    const renderMainTitle = (FieldName, y) => {
        mainTitleSize();
        doc.text(t(FieldName), langXPositionTitle, yPositionStart + y, alignDir);
        subTitleSize();
    }

    const renderMajorField = (FieldName, FieldValue, x, y0, y1) => {
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y0 + y1, alignDir);
        doc.text(FieldValue ? "" + funMajor(+FieldValue) : "", x + textWidth, yPositionStart + y0 + y1, alignDir);
    }

    const renderDateField = (FieldName, x, y0, y1) => {
        // هجري
        textWidth = signLang(doc.getTextWidth(t(FieldName)));
        doc.text(t(FieldName), x, yPositionStart + y0 + y1, alignDir);
        doc.text(momentH(Date.now()).tz("Asia/Riyadh").format("iDD-iMMMM-iYYYY"), x + textWidth, yPositionStart + y0 + y1 - 2, alignDir);
        // ميلادي
        doc.text(momentH(Date.now()).tz("Asia/Riyadh").format("DD-MMMM-YYYY"), x + textWidth, yPositionStart + y0 + y1 + 3, alignDir);
    }

    const renderPdf = () => {
        // border line
        doc.setLineWidth(0.5);
        doc.rect(2, 2, xSize, ySize);
        // almaarefa logo
        doc.addImage(image, "JPEG", 80, 15, 50, 15);
        // setup font
        doc.setFont("font");
        // المعلومات الشخصية
        renderMainTitle("pdf.personal", 0);
        // الاسم الاول 
        renderField("pdf.firstNameAr", data?.arabic_first_name, langXPositionSubTitle, 10);
        // الاسم الاوسط
        renderField("pdf.middleNameAr", data?.arabic_middle_name, langXPositionSubTitle2, 10);
        // الاسم الاخير 
        renderField("pdf.lastNameAr", data?.arabic_last_name, langXPositionSubTitle, 16);
        // الاسم العائلة
        renderField("pdf.familyNameAr", data?.arabic_family_name, langXPositionSubTitle2, 16);
        // الاسم الاول
        renderField("pdf.firstNameEn", data?.first_name, langXPositionSubTitle, 22);
        // الاسم الاوسط
        renderField("pdf.middleNameEn", data?.middle_name, langXPositionSubTitle2, 22);
        // الاسم الاخير 
        renderField("pdf.lastNameEn", data?.last_name, langXPositionSubTitle, 28);
        // الاسم العائلة
        renderField("pdf.familyNameEn", data?.family_name, langXPositionSubTitle2, 28);
        //  ألبريد 
        renderField("pdf.email", data?.email, langXPositionSubTitle, 34);
        // هاتف التليفون
        textWidth = signLang(doc.getTextWidth(t("pdf.homePhone")), "", "");
        doc.text(t("pdf.homePhone"), langXPositionSubTitle2, yPositionStart + 34, alignDir);
        doc.text(read ? data?.home_phone && data?.area_code ? `${data?.area_code}${data?.home_phone}` : "" : data?.home_phone ? `${data?.home_phone}` : "", langXPositionSubTitle2 + textWidth, yPositionStart + 34, alignDir);
        // تاريخ الميلاد 
        renderField("pdf.birthDate", data?.birth_date, langXPositionSubTitle, 40);
        // تاريخ الميلاد الهجري
        renderField("pdf.birthDateHegry", data?.birth_date_hegry, langXPositionSubTitle2, 40);
        //  الحالة الصحية
        textWidth = signLang(doc.getTextWidth(t(data?.health_state === "dis" ? "pdf.disa" : "pdf.notDisa")), "", "");
        doc.text(data?.health_state === "dis" ? t("pdf.disa") : t("pdf.notDisa"), langXPositionSubTitle, yPositionStart + 46, alignDir);
        doc.text(data?.health_state === "dis" ? t(`pdf.notok.${data?.health_type}`) : t("pdf.ok"), langXPositionSubTitle + textWidth, yPositionStart + 46, alignDir);
        // النوع
        textWidth = signLang(doc.getTextWidth(t("pdf.gender")), "", "");
        doc.text(t("pdf.gender"), langXPositionSubTitle2, yPositionStart + 46, alignDir);
        doc.text(t(`pdf.${data?.gender}`), langXPositionSubTitle2 + textWidth, yPositionStart + 46, alignDir);
        // مكان الميلاد 
        renderField("pdf.birthPlace", data?.birth_place, langXPositionSubTitle, 52);
        // الجنسية
        renderField("pdf.nationality", data?.nationality, langXPositionSubTitle2, 52);
        // جنسية الوالدة 
        renderField("pdf.motherNationality", data?.mother_nationality, langXPositionSubTitle, 58);
        // الحالة الوظيفية
        textWidth = signLang(doc.getTextWidth(t("pdf.WorkState")), "", "");
        doc.text(t("pdf.WorkState"), langXPositionSubTitle2, yPositionStart + 58, alignDir);
        doc.text(data?.employment_state === "work" ? t(`pdf.employState.${data?.employer}`) : t("pdf.nowork"), langXPositionSubTitle2 + textWidth, yPositionStart + 58, alignDir);
        //  ألحالة الاجتماعية 
        textWidth = signLang(doc.getTextWidth(t("pdf.martialStatus")));
        doc.text(t("pdf.martialStatus"), langXPositionSubTitle, yPositionStart + 64, alignDir);
        doc.text(t(`pdf.martialValue.${data?.marital_status}`), langXPositionSubTitle + textWidth, yPositionStart + 64, alignDir);
        // رقم الهوية
        renderField("pdf.national_id", data?.national_id, langXPositionSubTitle2, 64);
        //  رقم الجوال 
        renderMobileField("pdf.mobile_number", data?.phone, langXPositionSubTitle, 70);
        // الرمز البريدي
        renderField("pdf.postal_code", data?.postal_code, langXPositionSubTitle2, 70);
        // الرقم الاضافي 
        renderField("pdf.extra_code", data?.extra_code, langXPositionSubTitle, 76);
        // الرمز البريدي
        renderField("pdf.city", data?.city, langXPositionSubTitle2, 76);
        // رقم البناية 
        renderField("pdf.building_no", data?.building_no, langXPositionSubTitle, 82);
        // رقم الشارع
        renderField("pdf.street_no", data?.street_no, langXPositionSubTitle2, 82);
        // ناطق باللغة العربية
        data?.arabic_speaker && renderSpokenField("pdf.arabic_speaker", data?.arabic_speaker, langXPositionSubTitle, 88);

        if (major) {
            // التخصص
            renderMajorField("pdf.major", major, langXPositionSubTitle2, 6, 82);
        }
        // data?.arabic_speaker && renderSpokenField("pdf.arabic_speaker", data?.arabic_speaker, langXPositionSubTitle, 88);
        // extra break
        if (data?.previous_university && data?.tagseer_institute) yPositionStart = yPositionStart - 7
        // المعلومات الشهادة
        renderMainTitle("pdf.edu", 111);

        if(data?.degree === "UG"){
            renderField("pdf.secondaryCountry", data?.certificate_country, langXPositionSubTitle, 121);
            // مدينة الشهادة
            renderField("pdf.high_school_city", data?.high_school_city, langXPositionSubTitle2, 121);
            // المنطقة التعليمية
            renderField("pdf.education_area", data?.education_area, langXPositionSubTitle, 127);
                // اسم المدرسة
            renderField("pdf.high_school_name", data?.high_school_name, langXPositionSubTitle2, 127);    
            // معدل الثانوية العامة
             renderField("pdf.high_school_GPA", data?.high_school_GPA, langXPositionSubTitle, 133);
            // نوع الثانوية
            renderField("pdf.secondary_type", data?.secondary_type, langXPositionSubTitle2, 133);
            // درجة اختبار القدرات
            renderField("pdf.qiyas_aptitude", data?.qiyas_aptitude, langXPositionSubTitle, 139);
            // درجة اختبار التحصيلي
            renderField("pdf.qiyas_achievement", data?.qiyas_achievement, langXPositionSubTitle2, 139);
        }
        else{
        // دولة شهادة الثانوية 
        renderField("pdf.secondaryCountry", data?.certificate_country, langXPositionSubTitle, 121);
        // مدينة الشهادة
        renderField("pdf.high_school_city", data?.high_school_city, langXPositionSubTitle2, 121);
        // المنطقة التعليمية
        renderField("pdf.education_area", data?.education_area, langXPositionSubTitle, 127);
          }
          // ليس طالب محول او تجسير
        if (!data?.previous_university && !data?.tagseer_institute && data?.degree === "UG") {
            // ليس طالب محول
            renderNotUniveristy(145);
            // ليس طالب تجسير
            renderNotTagseer(151);
        }
        if(data?.degree === "PG"){
            // doc.text("te test")
            // حقل الجامعة

            renderField("pdf.previous_GPA", data?.previous_GPA, langXPositionSubTitle2, 127);
            // نوع الثانوية
            renderField("pdf.ColUniv", data?.previous_university ? "" + data?.previous_university : "", langXPositionSubTitle2, 133);
            // درجة اختبار القدرات
            renderField("pdf.GPAType", data?.max_gpa, langXPositionSubTitle, 133);
        }        
        // طالب محول و ليس تجسير
        if (data?.previous_university && !data?.tagseer_institute && data?.degree === "UG") {
            // حقل الجامعة
            renderUniveristyField(0);
            // ليس طالب تجسير
            renderNotTagseer(157);
        }
        // طالب تجسير و ليس محول
        if (data?.tagseer_institute && !data?.previous_university && data?.degree === "UG") {
            // ليس طالب محول
            renderNotUniveristy(145);
            // حقل التجسيير
            renderTeagseerField(0);
        }
        if (data?.previous_university && data?.tagseer_institute && data?.degree === "UG") {
            // حقل الجامعة
            renderUniveristyField(0);
            // حقل التجسيير
            renderTeagseerField(6);
        }
        if (data?.previous_university && data?.tagseer_institute && data?.degree === "UG") yPositionStart = yPositionStart + 7
        // بيانات ولي الامر
       if(data?.degree === "PG") renderMainTitle("pdf.parent", 179);
       if(data?.degree === "UG") renderMainTitle("pdf.parent_UG",179)
        // الاسم
        renderField("pdf.superior_name", data?.superior_name, langXPositionSubTitle2, 189);
        // رقم الهوية
        renderField("pdf.superior_nationalID", data?.superior_nationalID, langXPositionSubTitle, 189);
        // رقم الجوال
        renderMobileField("pdf.mobile_number", data?.superior_phone, langXPositionSubTitle2, 195);
         // درجة القرابة
        renderRelation("pdf.superior_relation", data?.superior_relation, langXPositionSubTitle, 195);
        // الاسم للطواري
        renderField("pdf.emergency_name", data?.emergency_name, langXPositionSubTitle2, 201);
        // رقم الهوية
        renderField("pdf.emergency_nationalID", data?.emergency_nationalID, langXPositionSubTitle, 201);
        // الجوال للطواري
        renderMobileField("pdf.emergency_phone", data?.emergency_phone, langXPositionSubTitle2, 207);
           // درجة القرابة للطواري
        renderRelation("pdf.emergency_relation", data?.emergency_relation, langXPositionSubTitle, 207);
        // اقرار
        let split = doc.splitTextToSize(t("register.labels.terms"), 190);
        doc.text(split, langXPositionSubTitle, yPositionStart + 226, alignDir);
        // الاسم
        doc.text(t("pdf.Name"), langXPositionSubTitle, yPositionStart + 241, alignDir);
        // التوقيع
        doc.text(t("pdf.Signature"), langXPositionSubTitle2, yPositionStart + 241, alignDir);
        // if (major) {
        //     // التخصص
        //     renderMajorField("pdf.major", major, langXPositionSubTitle, 10, - 40);
        // }
        // Date now
        renderDateField("pdf.date",i18n.language==="ar"? 50 : 155 , 10, - 40);
    }

    return (
        <>
            {renderPdf()}
        </>
    );
};

export default RegisterationPDF;
