import React from "react";
import { makeStyles } from "@material-ui/core";
import { dangerColor } from "../../../common/assets/jss/appStyles";
// import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import LottieAnimation from "../../../app/common/lottie/LottieAnimation";
import error from "../../../common/assets/images/lottie/connection-error.json";
import loading from "../../../common/assets/images/lottie/loading.json";

const useStyles = makeStyles(() => ({
  loading: {
    width: "100%",
    height: "100vh",
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: dangerColor,
    fontWeight: 600
  },
}));

const Loading = ({ errors = [] }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        {errors.length === 0 && <LottieAnimation lotti={loading} width={150} height={150} />}
        {/*<Loader type="Oval" color={primaryColor} height={80} width={80} />*/}
        {errors.length > 0 && <LottieAnimation lotti={error} width={250} height={250} />}
        {i18n.language === "ar" ? errors[1] : errors[0]}
      </div>
    </div>
  );
};

export default Loading;
