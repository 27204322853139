import { reducer as formReducer } from "redux-form";
import AuthReducer from "../../auth/state/reducer";
import studentReducer from "../../app/applicant/state/";
import adminReducer from "../../app/admission/state/";
import EngDeptsReducer from "../../app/englishDept/state";
import EngConfsReducer from "../../app/englishConf/state";
import deanReducer from "../../app/deans/state";
import hodReducer from "../../app/hod/state";
import supervisorReducer from "../../app/supervisor/state";
import scholarReducer from "../../app/scholarship/state";
import regviewReducer from "../../app/regView/state";
import equationReducer from "../../app/equation/state";
import linkReducer from "../../auth/state/linkReducer";
import updateReducer from "../../auth/state/updateReducer";
import phoneReducer from "../../auth/state/phoneReducer";
import {
  APPLICANT_UPDATE_SUCCESS,
  APPLICANT_MODAL_MOUNT,
} from "../../app/admission/state/actions";

export default {
  form: formReducer.plugin({
    applicantInfo: (state, action) => {
      switch (action.type) {
        case APPLICANT_MODAL_MOUNT:
          return { ...state, initial: action.payload, values: action.payload };
        case APPLICANT_UPDATE_SUCCESS:
          return undefined;
        default:
          return state;
      }
    },
  }),

  auth: AuthReducer,
  // Users
  student: studentReducer,
  admin: adminReducer,
  engDept: EngDeptsReducer,
  engConf: EngConfsReducer,
  dean: deanReducer,
  hod: hodReducer,
  supervisor: supervisorReducer,
  scholar: scholarReducer,
  regview: regviewReducer,
  equation: equationReducer,
  // functions
  update: updateReducer,
  email: linkReducer,
  phoneValidate: phoneReducer,
};
