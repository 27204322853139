export const getMajorName = (major) => {
  switch (major) {
    case "M":
      return "Medicine";
    case "PH":
      return "Pharmacy";
    case "AS":
      return "Applied Science";
    default:
      return major;
  }
};

export const getSubMajors = (subMajor) => {
  switch (subMajor) {
    case 1:
      return "Medicine & Surgery";
    case 2:
      return "Pharmacy";
    case 3:
      return "Nursing";
    case 4:
      return "Respiratory Therapy";
    case 5:
      return "Emergency Medical Services";
    case 6:
      return "Anaesthesia Technology";
    case 7:
      return "Health Information Systems";
    case 8:
      return "Information Systems";
    case 9:
      return "Computer Science";
    case 10:
      return "Industrial Engineering";
    case 11:
      return "General science & English";
      case 13:
      return "Master of Emergency and Disaster Nursing";
    case 14:
      return "Master of Clinical Pharmacy";
    case 15:
      return "Master of Cyber Security";
    default:
      return subMajor;
  }
};

export const getSubMajorsAr = (subMajor) => {
  switch (subMajor) {
    case 1:
      return "طب و جراحة";
    case 2:
      return "دكتور صيدلة";
    case 3:
      return "التمريض";
    case 4:
      return "الرعاية التنفسية";
    case 5:
      return "الخدمات الطبية الطارئة";
    case 6:
      return "تقنية التخدير";
    case 7:
      return "نظم المعلومات الصحية";
    case 8:
      return "نظم المعلومات";
    case 9:
      return "علوم الحاسب";
    case 10:
      return "الهندسة الصناعية";
    case 13:
      return "ماجستير تمريض الطوارئ والكوارث ";
    case 14:
      return "ماجستير الصيدلة الإكلينيكية";
    case 15:
      return "ماجستير  الأمن السيبراني";
    default:
      return subMajor;
  }
};

export const getMajorRoutes = (major) => {
  switch (major) {
    case 1:
      return "medicine";
    case 2:
      return "pharmD";
    case 3:
      return "nursing";
    case 4:
      return "respiratory";
    case 5:
      return "emergency";
    case 6:
      return "anaesthesia";
    case 7:
      return "health";
    case 8:
      return "information";
    case 9:
      return "computer";
    case 10:
      return "industrial";
    case 11:
      return "general";
      case 13:
        return "masterOfEmergencyAndDisasterNursing";
      case 14:
        return "mastersOfCyberSecurity";
      case 15:
        return "mastersOfPharmacy";
    default:
      return major;
  }
};
