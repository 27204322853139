const UPDATE_SUCCESS = "update_applicant_info_success";
const UPDATE_ERROR = "update_applicant_info_error";
const UPDATE_REQUEST = "update_applicant_info_request";
const UPDATE_CLEAN = "update_applicant_info_clean";

const SET_MAJORS_SUCCESS = "set_major_success";
const SET_MAJORS_ERROR = "set_major_error";
const SET_MAJORS_REQUEST = "set_major_request";

const SET_MAJ_SUCCESS = "set_maj_success";
const SET_MAJ_ERROR = "set_maj_error";
const SET_MAJ_REQUEST = "set_maj_request";
const SET_CANCEL_SUCCESS = "set_CANCEL_success";
const SET_CANCEL_ERROR = "set_CANCEL_error";
const SET_CANCEL_REQUEST = "set_CANCEL_request";
const SET_UNCANCEL_REQUEST = 'set_UNCANCEL_request'

const UPLOAD_SUCCESS = "upload_success";
const UPLOAD_PROGRESS = "upload_progress";
const UPLOAD_ERROR = "upload_error";

const PAY_SUCCESS = "app_pay_success";
const PAY_ERROR = "app_pay_error";
const PAY_REQUEST = "app_pay_request";

const GET_PAY_SUCCESS = "get_app_pay_success";
const GET_PAY_ERROR = "get_app_pay_error";
const GET_PAY_REQUEST = "get_app_pay_error";

const PAY_CLEAN = "app_pay_clean";

const UPLOAD_ERROR_CLEAN = "upload_error_clean";

const GET_STUD_ENGDATES_SUCCESS = "get_student_eng_dates_success";
const GET_STUD_ENGDATES_ERROR = "get_student_eng_dates_error";
const GET_STUD_ENGDATES_PROGRESS = "get_student_eng_dates_progress";

const SET_STUD_ENGDATES_SUCCESS = "set_student_eng_dates_success";
const SET_STUD_ENGDATES_ERROR = "set_student_eng_dates_error";
const SET_STUD_ENGDATE_PROGRESS = "set_student_eng_date_progress";

const GET_STUD_INTRDATES_SUCCESS = "get_student_intr_dates_success";
const GET_STUD_INTRDATES_ERROR = "get_student_intr_dates_error";
const GET_STUD_INTRDATES_PROGRESS = "get_student_intr_dates_progress";

const SET_STUD_INTRDATES_SUCCESS = "set_student_intr_dates_success";
const SET_STUD_INTRDATES_ERROR = "set_student_intr_dates_error";
const SET_STUD_INTRDATE_PROGRESS = "set_student_intr_date_progress";

const STUD_OFFER_SUCCESS = "set_student_offer_success";
const STUD_OFFER_ERR = "set_student_offer_error";
const STUD_OFFER_REQUEST = "set_student_offer_request";

const STUD_PLEDGE_SUCCESS = "set_student_pledge_success";
const STUD_PLEDGE_ERR = "set_student_pledge_error";
const STUD_PLEDGE_REQUEST = "set_student_pledge_request";

const SET_EQUATION_PROGRESS = "SET_EQUATION_PROGRESS";
const SET_EQUATION_SUCCESS = "SET_EQUATION_SUCESS";
const SET_EQUATION_ERROR = "SET_EQUATION_ERROR";

const CLEAN_ERRORS = "clean_stud_dates_errors";

const SET_PAYMENT_PROGRESS = "SET_PAYMENT_PROGRESS";
const SET_PAYMNET_SUCCESS = "SET_PAYMENT_SUCCESS";
const SET_PAYMENT_ERROR = "SET_PAYMENT_ERROR";
const CANCELLING_ENGLISH_EXAM = "CANCELLING_ENGLISH_EXAM";
const CANCELED_ENGLISH_EXAM = "CANCELED_ENGLISH_EXAM";

export default {
  // CANCEL ENGLISH EXAM
  CANCELLING_ENGLISH_EXAM,
  CANCELED_ENGLISH_EXAM,
  // PAYMENT MODE,
  SET_PAYMENT_PROGRESS,
  SET_PAYMNET_SUCCESS,
  SET_PAYMENT_ERROR,
  // Update applicant info
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  UPDATE_REQUEST,
  UPDATE_CLEAN,
  // payment
  PAY_SUCCESS,
  PAY_ERROR,
  PAY_REQUEST,
  GET_PAY_SUCCESS,
  GET_PAY_ERROR,
  GET_PAY_REQUEST,
  PAY_CLEAN,
  // majors select
  SET_MAJORS_SUCCESS,
  SET_MAJORS_ERROR,
  SET_MAJORS_REQUEST,
  // major select
  SET_MAJ_SUCCESS,
  SET_MAJ_ERROR,
  SET_MAJ_REQUEST,
  SET_CANCEL_SUCCESS,
  SET_CANCEL_ERROR,
  SET_CANCEL_REQUEST,
  SET_UNCANCEL_REQUEST,
  // upload
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPLOAD_PROGRESS,
  UPLOAD_ERROR_CLEAN,
  // dates
  // english
  GET_STUD_ENGDATES_SUCCESS,
  GET_STUD_ENGDATES_ERROR,
  GET_STUD_ENGDATES_PROGRESS,
  SET_STUD_ENGDATES_SUCCESS,
  SET_STUD_ENGDATES_ERROR,
  SET_STUD_ENGDATE_PROGRESS,
  // interview
  GET_STUD_INTRDATES_SUCCESS,
  GET_STUD_INTRDATES_ERROR,
  GET_STUD_INTRDATES_PROGRESS,
  SET_STUD_INTRDATES_SUCCESS,
  SET_STUD_INTRDATES_ERROR,
  SET_STUD_INTRDATE_PROGRESS,
  // university Offer
  STUD_OFFER_SUCCESS,
  STUD_OFFER_ERR,
  STUD_OFFER_REQUEST,
  // pledge
  STUD_PLEDGE_SUCCESS,
  STUD_PLEDGE_ERR,
  STUD_PLEDGE_REQUEST,
  // equation
  SET_EQUATION_PROGRESS,
  SET_EQUATION_SUCCESS,
  SET_EQUATION_ERROR,
  // clean
  CLEAN_ERRORS,
};
