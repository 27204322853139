import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  uploading: false,
  done: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.UPLOAD_OTHER_FILES_PROGRESS:
      return { uploading: true, errors: [], done: "" };
    case Types.UPLOAD_OTHER_FILES_SUCCESS:
      return { errors: [], uploading: false, done: true };
    case Types.UPLOAD_OTHER_FILES_ERROR:
      return { errors: [...action.payload], uploading: false, done: false };
    case Types.UPLOAD_OTHER_FILES_ERROR_CLEAN:
      return { errors: action.payload, uploading: false, done: false };
    default:
      return state;
  }
};
