import { makeStyles } from "@material-ui/core";
import {
  primaryColor,
  dangerColor,
} from "../../../common/assets/jss/appStyles";

export const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    position: "relative",
  },
  clear: {
    position: "absolute",
    right: -30,
    cursor: "pointer",
    color: dangerColor,
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      right: -23,
    },
  },
  root: {
    margin: "10px auto",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
  hiddenInput: {
    display: "none",
  },
  icon: {
    color: primaryColor,
    cursor: "pointer",
  },
}));
