import Types from "./types";

const INITIAL_STATE = {
  eng: {
    dates: [],
    loaded: false,
    errors: [],
    settingDate: false,
  },
  interview: {
    dates: [],
    loaded: false,
    errors: [],
    settingDate: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_STUD_ENGDATES_SUCCESS:
      return {
        ...state,
        eng: { loaded: true, errors: [], dates: action.payload },
      };
    case Types.GET_STUD_ENGDATES_ERROR:
      return {
        ...state,
        eng: { ...state.eng, loaded: true, errors: [...action.payload] },
      };
    case Types.GET_STUD_ENGDATES_PROGRESS:
      return { ...state, eng: { loaded: false } };
    case Types.SET_STUD_ENGDATES_ERROR:
      return {
        ...state,
        eng: {
          ...state.eng,
          loaded: true,
          errors: [...action.payload],
          settingDate: false,
        },
      };
    case Types.SET_STUD_ENGDATES_SUCCESS:
      return {
        ...state,
        eng: { ...state.eng, loaded: true, errors: [], settingDate: false },
      };

    case Types.GET_STUD_INTRDATES_SUCCESS:
      return {
        ...state,
        interview: { loaded: true, errors: [], dates: action.payload },
      };
    case Types.GET_STUD_INTRDATES_ERROR:
      return {
        ...state,
        interview: {
          ...state.interview,
          loaded: true,
          errors: [...action.payload],
        },
      };
    case Types.GET_STUD_INTRDATES_PROGRESS:
      return { ...state, interview: { loaded: false } };
    case Types.SET_STUD_INTRDATES_ERROR:
      return {
        ...state,
        interview: {
          ...state.interview,
          loaded: true,
          errors: [...action.payload],
          settingDate: false,
        },
      };
    case Types.SET_STUD_INTRDATES_SUCCESS:
      return {
        ...state,
        interview: { ...state.interview, loaded: true, error: [], settingDate: false },
      };
    case Types.SET_STUD_ENGDATE_PROGRESS:
      return { ...state, eng: { ...state.eng, settingDate: true } };
    case Types.SET_STUD_INTRDATE_PROGRESS:
      return { ...state, interview: { ...state.interview, settingDate: true } };
    case Types.CLEAN_ERRORS:
      return {
        interview: { ...state.interview, dates: [], errors: [], loaded: false },
        eng: { ...state.eng, dates: [], errors: [], loaded: false },
      };
    case Types.SET_PAYMENT_PROGRESS:
      return {...state, settingPaymentMode: true}
    case Types.SET_EQUATION_SUCCESS:
      return {
        ...state, settingPaymentMode: false
      }
      case Types.SET_PAYMENT_ERROR:
        return{
          ...state,
          settingPaymentMode:false,
          errors:[...action.payload]
        }
    default:
      return state;
  }
};
