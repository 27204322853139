import { database , studentDatabase} from "./firebase";
import { API  } from "../common/constants";

let applicantNotify = "";
let chat = "";
let state = "";
let constants = "";
let getStudentConstants = "";

if (API.includes("https://my.um.edu.sa/backend")) {
  applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`prod/${semester}/${user.gender}/${user.role}/${user.user_major}/applicantNotify`);
    else if (user.role === 1) return database.ref(`prod/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`prod/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`prod/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
  applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`prod/${semester}/${user.gender}/${user.role}/" "/applicantNotify`);
    else if (user.role === 1) return database.ref(`prod/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`prod/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`prod/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
  chat = (semester, gender, id) => database.ref(`prod/${semester}/${gender}chat/${id}`);
  state = (semester, gender, id) => database.ref(`prod/${semester}/${gender}state${id ? `/${id}` : ""}`);
  constants = () => database.ref("prod/Constants");
  getStudentConstants = () => studentDatabase.ref("prod/Constants");

} else if (API.includes("http://37.76.245.93/backend")) {
  applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`backend/${semester}/${user.gender}/${user.role}/${user.user_major}/applicantNotify`);
    else if (user.role === 1) return database.ref(`backend/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`backend/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`backend/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
  applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`backend/${semester}/${user.gender}/${user.role}/" "/applicantNotify`);
    else if (user.role === 1) return database.ref(`backend/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`backend/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`backend/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
  chat = (semester, gender, id) => database.ref(`backend/${semester}/${gender}chat/${id}`);
  state = (semester, gender, id) => database.ref(`backend/${semester}/${gender}state${id ? `/${id}` : ""}`);
  constants = () => database.ref("backend/Constants");

  getStudentConstants = () => studentDatabase.ref("backend/Constants");
} 
else {
  applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`test/${semester}/${user.gender}/${user.role}/${user.user_major}/applicantNotify`);
    else if (user.role === 1) return database.ref(`test/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`test/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`test/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
    applicantNotify = (semester, user) => {
    if ([14, 15].includes(+user.role)) return database.ref(`test/${semester}/${user.gender}/${user.role}/" "/applicantNotify`);
    else if (user.role === 1) return database.ref(`test/${semester}/${user.gender}/${user.role}/${user.id}/applicantNotify`);
    else if (user.role === 6) return database.ref(`test/${semester}/${user.gender}/1/applicantNotify`);
    else return database.ref(`test/${semester}/${user.gender}/${user.role}/applicantNotify`);
  };
  chat = (semester, gender, id) => database.ref(`test/${semester}/${gender}chat/${id}`);
  state = (semester, gender, id) => database.ref(`test/${semester}/${gender}state${id ? `/${id}` : ""}`);
  constants = () => database.ref("test/Constants");
  getStudentConstants = () => studentDatabase.ref("test/Constants");
}

class DataService {
  ////////////chat////////////////////
  getAllChat(user, semester) {
    return chatDB(user.gender, semester, user.id);
  }

  newMessage(user, msg, semester) {
    return chatDB(user.gender, semester, user.id).push(msg);
  }

  //////////////CurrentState//////////////
  getAllStatus(user, semester, limit) {
    if (user?.id) return stateDB(user.gender, semester, user.id);
    else return stateDB(user.gender, semester, user.id).orderByChild("seenFromAdmin").equalTo(false).limitToFirst(limit);
  }

  newState(user, state, semester) {
    return stateDB(user.gender, semester, user.id).update(state);
  }

  /////////////Notifications//////////////
  getAllApplicantNotify(semester, user, limit) {
    return applicantNotify(semester, user).orderByChild("action").equalTo(false).limitToFirst(limit);
  }

  createApplicantNotify(notify, semester, user) {
    const req = { selected: false, toestify: false, badge: false, action: false, time: Date.now(), actionByUser: "", ...notify };
    return applicantNotify(semester, user).push(req);
  }

  updateApplicantNotify(key, value, semester, user) {
    return applicantNotify(semester, user).child(key).update(value);
  }
  updateConstants(value){
    getStudentConstants().update({RegistrationOpen: value})
    constants().update({RegistrationOpen: value})
 }
  ///////////////Constants////////////////
  getConstants() {
    return constants();
  }
}

function chatDB(gender, semester, id) {
  return chat(semester, gender, id);
}

function stateDB(gender, semester, id) {
  return state(semester, gender, id);
}

export default new DataService();
