import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { reduxForm, change as changeFieldValue } from "redux-form";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import EngDocumentState from "./EngDocumentState";

const HealthDocuments = ({  submits,  form, next }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [scoreType, setScoreType] = useState(form.register.values?.scoreType?form.register.values?.scoreType:"");
  const dispatch = useDispatch();




  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(changeFieldValue("register", "applicant_type","HD"))
    next()
  };

  const addFile = (name, file) => {
    dispatch(changeFieldValue("register", name,file))
  }

  const removeFile = (name) => {
    dispatch(changeFieldValue("register", name,undefined))
  }

  const addScore = (e) => {
    dispatch(changeFieldValue("register", "english_certf_score",e.target.value))
  }
   

  const removeScore = () => {
    dispatch(changeFieldValue("register", "english_certf_score",undefined))

  }
  const changeScoreType = (event) => {
    dispatch(changeFieldValue("register", "scoreType", event.target.value));
    setScoreType(event.target.value);
  };

  return (
    <div className={classes.form}>
      <form onSubmit={onSubmit} className={classes.formInner}>
        {/*<Typography align="center" variant="h5" className={classes.title}>
          {t("applicant.uploadForm.titles.HD")}
        </Typography>*/}

        <MuiUploadField
          add={addFile}
          remove={removeFile}
          required
          name="national_file"
          label={t("applicant.uploadForm.inputs.labels.ssn")}
          description={t("applicant.uploadForm.inputs.tooltips.ssn")}
          file={form.register.values?.national_file}
        />

        <MuiUploadField
          required
          name="high_school"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.school")}
          description={t("applicant.uploadForm.inputs.tooltips.school")}
          file={form.register.values?.high_school}

        />

        {/* {form.register.values?.scoreType === 1 && (
          <>
            <MuiUploadField
              add={addFile}
              remove={removeFile}
              name="GAT"
              label="GAT"
              // label={t("applicant.uploadForm.inputs.labels.score")}
              description={t("applicant.uploadForm.inputs.tooltips.score")}
          file={form.register.values?.SAT}

            />
            <MuiUploadField
              add={addFile}
              remove={removeFile}
              name="SAAT"
              label="SAAT"
              // label={t("applicant.uploadForm.inputs.labels.score")}
              description={t("applicant.uploadForm.inputs.tooltips.score")}
          file={form.register.values?.SAAT}

            />
          </>
        )}
        {form.register.values?.scoreType === 2 && (
          <MuiUploadField
            add={addFile}
            remove={removeFile}
            name="SAT"
            label="SAT"
            // label={t("applicant.uploadForm.inputs.labels.score")}
            description={t("applicant.uploadForm.inputs.tooltips.score")}
          file={form.register.values?.SAT}

          />
        )} */}

        {/* <MuiUploadField
          add={addFile}
          remove={removeFile}
          name="score"
          label={t("applicant.uploadForm.inputs.labels.score")}
          description={t("applicant.uploadForm.inputs.tooltips.score")}
        /> */}

        <MuiUploadField
          required
          add={addFile}
          remove={removeFile}
          name="diploma"
          label={t("applicant.uploadForm.inputs.labels.diploma")}
          description={t("applicant.uploadForm.inputs.tooltips.diploma")}
          file={form.register.values?.diploma}

        />

        <MuiUploadField
          required
          name="transcript"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.transcript")}
          file={form.register.values?.transcript}
          description={t("applicant.uploadForm.inputs.tooltips.transcript")}
        />

        <MuiUploadField
          name="courses"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.courses")}
          description={t("applicant.uploadForm.inputs.tooltips.courses")}
          file={form.register.values?.courses}

        />

        <MuiUploadField
          required
          name="SCFHS"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.SCFHS")}
          description={t("applicant.uploadForm.inputs.tooltips.SCFHS")}
          file={form.register.values?.SCFHS}

        />

        {form.register?.values?.employment_state === "work" && (
          <MuiUploadField
            // required
            add={addFile}
            remove={removeFile}
            name="employee_card"
            label={t("applicant.uploadForm.inputs.labels.employee_card")}
            description={t("applicant.uploadForm.inputs.tooltips.employee_card")}
          file={form.register.values?.employee_card}

          />
        )}
<MuiUploadField
            required
            add={addFile}
            remove={removeFile}
            name="photo"
            label={t("applicant.uploadForm.inputs.labels.photo")}
            description={t("applicant.uploadForm.inputs.tooltips.photo")}
          file={form.register.values?.photo}

          />

{(form.register.values?.scoreType === 1 ) && (
          <><MuiUploadField
                  add={addFile}
                  remove={removeFile}
                  
                  name="quadrant_file"
                  label={"Quadrat File"}
                  description={t('applicant.uploadForm.inputs.tooltips.qudrantFileUpload')}
                  file={form.register?.values?.quadrant_file} />
                  
                  <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    // required
                    name="tahisli_file"
                    label={"Tahsili File"}
                    description={t('applicant.uploadForm.inputs.tooltips.tahsiliFileUpload')}
                    file={form.register?.values?.tahisli_file} /></>
        )}
        {form.register.values?.scoreType === 2 && (
          <MuiUploadField
            add={addFile}
            remove={removeFile}
            name="SAT"
            label="SAT"
            // label={t("applicant.uploadForm.inputs.labels.score")}
            description={t("applicant.uploadForm.inputs.tooltips.score")}
          file={form.register.values?.SAT}

          />
        )}

        {/* <MuiUploadField
          add={addFile}
          remove={removeFile}
          name="mother_id"
          label="Mother`s Valid ID"
          description="for non Saudi Students from Saudi mothers"
        /> */}

        <EngDocumentState add={addFile} remove={removeFile} addScore={addScore} removeScore={removeScore} form={form} />

        <button type="submit" ref={submits} className={classes.sub}></button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ form }) => ({ form });

// export default connect(mapStateToProps)(withRouter(HealthDocuments));
export default reduxForm({
    form: "register",
    destroyOnUnmount: false,
  })(connect(mapStateToProps)(HealthDocuments));