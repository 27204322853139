import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
    lang: {
        margin: 5,
        color: "#f1f2f7",
        backgroundColor: "#333542",
        border: "none",
        cursor: "pointer",
        width: "30px",
        height: "25px",
        borderRadius: "4px",
        fontFamily: "BahijJanna",
        "&.selected": {
          backgroundColor: "#1c9ca9",
        },
        [theme.breakpoints.down("xs")]: {
          width: 20,
          height: 20,
          fontSize: 12,
        },
      },

}))