import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import Logo from '../../assets/images/Logo-xl.png'
import { Grid, Paper,Zoom } from "@material-ui/core";
import { toast } from 'react-toastify';
import { useStyles } from "../../../auth/components/login/loginStyles";

const NotFound = () => {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  // Language Control
  const handleClick = (lang) => {
    let msg = lang === "ar" ? "تم تغير اللغة الي" : "Language Changed to";
    let language = lang === "ar" ? "العربية" : "English"
    toast.success(`${msg} ${language}`, { autoClose: 3000, });
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  return (

<div className={classes.login}>
<Grid container justify="center">
  <Grid className={classes.inner}>
    <Zoom in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} className={classes.loginBox}>
        <div className={classes.div_lang}>
          <button
            onClick={() => handleClick("en")}
            className={`${classes.lang} ${selected === "en" && "selected"}`}
          >
            EN
          </button>
          <button
            onClick={() => handleClick("ar")}
            className={`${classes.lang} ${selected === "ar" && "selected"}`}
          >
            عربي
        </button>
        </div>
        <div className={classes.head}>
          <img className={classes.logo} src={Logo} alt="logo" />
        </div>
        <div className={classes.hrbreak}></div>
        <div className={classes.body}>
          <Typography variant="h6" align="center" gutterBottom className={classes.header} style={{marginTop:25}}>
            {t("warning.msg.welcome")}
          </Typography>
          <div className={classes.wrapper} style={{marginTop: 100,display: 'flex',flexWrap: 'wrap',justifyContent: 'space-around'}}>
            <Link to={"/applicant/login"}>
              <Button variant="contained" color="primary" className={classes.submit} style={{margin:"auto"}}>
                {t("warning.buttons.login")}
              </Button>
            </Link>
            <Link to={"/applicant/register"}>
              <Button variant="contained" color="primary" className={classes.submit} style={{margin:"auto"}}>
                {t("warning.buttons.register")}
              </Button>
            </Link>
          </div>
        </div>
      </Paper>
    </Zoom>
  </Grid>
</Grid>
</div>);
};

export default NotFound;


// import React from "react";
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import { makeStyles, Typography, Button } from "@material-ui/core";
// // import WarningIcon from "@material-ui/icons/Warning";
// import { warnColor, whiteColor } from "../../../common/assets/jss/appStyles";
// import Logo from '../../assets/images/Logo-xl.png'
// // import LottieAnimation from "../../../app/common/lottie/LottieAnimation";
// // import Document from "../../../common/assets/images/lottie/question-mark.json";
// import backgroundImage from "../../../../src/common/assets/images/bg3.png";


// const useStyles = makeStyles((theme) => ({

//   container: {
//     width: "100%",
//     height: '100vh',
//     minHeight: 300,
//     textAlign: "center",
//     position: 'relative',
//     backgroundColor: whiteColor,
//     [theme.breakpoints.up('sm')]: {
//       background: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${backgroundImage})`,
//       backgroundRepeat: "no-repeat",
//       backgroundSize: "cover",
//     }

//   },
//   container__inner: {
//     backgroundColor: whiteColor,
//     minWidth: "300px",
//     maxWidth: "450px",
//     position: "absolute",
//     minHeight: 400,
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%,-50%)",
//     textAlign: "center",
//     borderRadius: 8,
//     padding: 20
//   },
//   imgContainer: {
//     height: 220,
//     padding: 25,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },

//   img: {
//     width: 200,
//     margin: "25px auto",
//     display: "block",
//     [theme.breakpoints.up('sm')]: {
//       width: 280,

//     }
//   },
//   redirect: {
//     height: 30,
//     margin: "15px auto",
//     textTransform: "capitalize",
//   },
//   link: {
//     display: "inline-block",
//     margin: "auto",
//   },
//   button: {
//     // margin: 25,
//   },
//   warn: {
//     width: 200,
//     height: 200,
//     color: warnColor,
//   },
//   actions: {
//     marginTop: 25,
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',

//   },
//   header: {
//     marginBottom: 50
//   }
// }));

// const NotFound = () => {
//   const classes = useStyles();
//   const { t } = useTranslation();

//   return (
//     <div className={classes.container}>
//       <div className={classes.container__inner}>
//         <div className={classes.imgContainer}>
//           {/*<WarningIcon className={classes.warn} />*/}
//           {/* <LottieAnimation lotti={Document} width={250} height={250} /> */}
//           <img src={Logo} alt="logo" className={classes.img} />
//         </div>
//         {/* <Typography variant="h4" align="center" gutterBottom>
//         {t("warning.titles.hello")}
//       </Typography> */}
//         <Typography variant="h6" align="center" gutterBottom className={classes.header}>
//           {t("warning.msg.welcome")}
//         </Typography>

//         <div className={classes.actions}>
//           <Link to={"/applicant/login"}>
//             {/* <Link to={"/applicant/login"}> */}
//             <Button variant="contained" color="primary" className={classes.button}>
//               {t("warning.buttons.login")}
//             </Button>
//           </Link>

//           <Link to={"/applicant/register"}>
//             {/* <Link to={"/applicant/login"}> */}
//             <Button variant="contained" color="primary" className={classes.button}>
//               {t("warning.buttons.register")}
//             </Button>
//           </Link>
//         </div>
//         {/* <Link to={"/admin/login"}>
//         <Button variant="contained" color="primary" className={classes.button}>
//           Admin
//         </Button>
//       </Link> */}
//       </div>
//     </div>
//   );
// };

// export default NotFound;
