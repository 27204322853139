import Types from "./types";

const INITIAL_STATE = {
  error: [],
  sending: false,
  sent: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEND_INVITE_SUCCESS:
      return { sent: true, error: [], sending: false };
    case Types.SEND_INVITE_ERROR:
      return { sent: false, error: action.payload, sending: false };
    case Types.SEND_INVITE_REQUEST:
      return { sent: false, error: [], sending: true };
    case Types.INVITE_CLEAN:
      return {...state, error: []};
    default:
      return state;
  }
}
