import Types from "./types";

const INITIAL_STATE = {
  loading: false,
  errors: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_ANNCMNT_SUCCESS:
      return { loading: false, errors: [] };
    case Types.SET_ANNCMNT_ERROR:
      return { loading: false, errors: [...action.payload] };
    case Types.SET_ANNCMNT_REQUEST:
      return { errors: [], loading: true };
    case Types.CLEAN:
      return INITIAL_STATE;
    default:
      return state;
  }
};
