import Types from "./types";

const INITIAL_STATE = {
  error: [],
  loading: false,
  user: false,
  anncmnt: "",
  setPw: {
    error: [],
    loading: false
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AUTH_USER:
      return { user: action.payload, error: [], loading: false, setPw: {error: [], loading: false} };
    case Types.AUTH_ERROR:
      return { error: [...action.payload], user: false, loading: false };
    case Types.AUTH_CLEAN:
      return { error: [], user: false, loading: false };
    case Types.AUTH_REQUEST:
      return { error: [], user: false, loading: true };
    case Types.SET_PW_ERROR:
      return { ...state, setPw: { error: [...action.payload], loading: false } };
    case Types.SET_PW_REQUEST:
      return { ...state, setPw: { error: [], loading: true } };
    default:
      return state;
  }
}
