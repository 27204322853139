const GET_FEES_SUCCESS = "get_fees_success";
const GET_FEES_PROGRESS = "get_fees_progress";
const GET_FEES_ERROR = "get_fees_error";

const SET_FEES_SUCCESS = "set_fees_success";
const SET_FEES_REQUEST = "set_fees_request";
const SET_FEES_ERROR = "set_fees_error";

// get list of paid students
const GET_PAID_STUDENTS_SUCCESS = "get_paid_students_success";
const GET_PAID_STUDENTS_ERROR = "get_paid_students_error";
const GET_PAID_STUDENTS_PROGRESS = "get_paid_students_progress";

const GET_STUDENT_SUCCESS = "get_student_success";
const GET_STUDENT_ERROR = "get_student_error";
const GET_STUDENT_PROGRESS = "get_student_progress";

const CLEAN_ERRORS = "clean_errors";
const CLEAN = "clean_students table";

const GET_STUDENTS_SUCCESS = "get_students_success";
const GET_STUDENTS_ERROR = "get_students_error";
const GET_STUDENTS_PROGRESS = "get_students_progress";


const GET_STUDENT_BY_CASH_PROGRESS = " get_student_by_cash";
const GET_STUDENT_BY_CASH_SUCCESS ="get_student_by_cash_success";
const GET_STUDENTS_BY_CASH_FAILURE = "get_student_by_cash_failure";

const PAID_BY_CASH_PROGRESS = "paid_by_cash_progress";
const PAID_BY_CASH_SUCCESS = "paid_by_cash_success";
const PAID_BY_CASH_ERROR = "paid_by_cash_error"
 export default {
  // cash paid
  PAID_BY_CASH_PROGRESS,
  PAID_BY_CASH_SUCCESS,
  PAID_BY_CASH_ERROR,
  
  // cash mode
  GET_STUDENT_BY_CASH_PROGRESS,
  GET_STUDENT_BY_CASH_SUCCESS,
  GET_STUDENTS_BY_CASH_FAILURE,
  
  GET_FEES_SUCCESS,
  GET_FEES_PROGRESS,
  GET_FEES_ERROR,
  // fetch single applicant
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  SET_FEES_REQUEST,
  SET_FEES_SUCCESS,
  SET_FEES_ERROR,
  CLEAN_ERRORS,
  CLEAN,
  // fetch paid students
  GET_PAID_STUDENTS_SUCCESS,
  GET_PAID_STUDENTS_ERROR,
  GET_PAID_STUDENTS_PROGRESS,
  
    // fetch applicant
    GET_STUDENTS_SUCCESS,
    GET_STUDENTS_ERROR,
    GET_STUDENTS_PROGRESS,
};
