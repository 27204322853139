import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.navigateAway();
    }

    componentDidUpdate() {
      this.navigateAway();
    }

    navigateAway() {
      // const { history } = this.props;
      // const user = localStorage.getItem("user");
      // if (!user) {
      //   history.push("/admin/login");
      // }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { user: state.auth.user };
  }

  return compose(connect(mapStateToProps, {}), withRouter)(ComposedComponent);
};
