import React, { useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";
import { toast } from 'react-toastify';
// Actions
import { adminSignIn } from "../../state/actions";
import { authRequest } from "./../../state/actions";
// @Material-UI
import Zoom from "@material-ui/core/Zoom";
import { Grid, Paper, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// assets
import MuiTextField from "../../../common/components/muiTextField/MuiTextField";
import Logo from "../../../common/assets/images/Logo-sm.png";
import { validate } from "./../../../common/helper/validate";
// styles
import { useStyles } from "./adminLoginStyles";

const Login = ({ handleSubmit, adminSignIn, history, auth, authRequest }) => {
  const classes = useStyles();
  const user = localStorage.getItem("user");

  // Language Control
  const [t, i18n] = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  const handleClick = (lang) => {
    let msg = lang === "ar" ? "تم تغير اللغة الي" : "Language Changed to";
    let language = lang === "ar" ? "العربية" : "English"
    toast.success(`${msg} ${language}`, { autoClose: 3000, });
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  const onSubmit = (values) => {
    authRequest();
    adminSignIn(values, () => history.push("/"));
  };

  const renderAdminLogin = () => (
    <div className={classes.login}>
      <Grid container justify="center">
        <Grid className={classes.inner}>
          <Zoom in={true} mountOnEnter unmountOnExit>
            <Paper elevation={3} className={classes.loginBox}>
              <div className={classes.div_lang}>
                <button
                  onClick={() => handleClick("en")}
                  className={`${classes.lang} ${selected === "en" && "selected"}`}
                >
                  EN
                </button>
                <button
                  onClick={() => handleClick("ar")}
                  className={`${classes.lang} ${selected === "ar" && "selected"}`}
                >
                  عربي
              </button>
              </div>
              <div className={classes.head}>
                <img className={classes.logo} src={Logo} alt="logo" />
              </div>
              <div className={classes.hrbreak}></div>
              <div className={classes.body}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit((values) => onSubmit(values))}
                >
                  <Field name="email" type="email" label={t('admission.labels.email')} required component={MuiTextField} />
                  <Field name="password" type="password" label={t('admission.labels.password')} required component={MuiTextField} />

                  <div className={classes.wrapper}>
                    <Button variant="contained"
                      className={classes.submit}
                      color="primary"
                      disabled={auth.loading}
                      type="submit"
                    >
                      {t('login.login')}
                    </Button>
                    {auth.loading && (<CircularProgress size={24} className={classes.buttonProgress} />)}
                  </div>
                </form>
              </div>
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
    </div>
  );

  return <div>{user ? history.push("/") : renderAdminLogin()}</div>;
};

const mapStateToProps = ({ auth }) => ({ auth })

export default compose(
  reduxForm({ form: "login", validate }),
  connect(mapStateToProps, { adminSignIn, authRequest })
)(Login);
