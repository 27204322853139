import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles, Typography, Button } from "@material-ui/core";
import LottieAnimation from "../../../app/common/lottie/LottieAnimation";
import Document from "../../../common/assets/images/lottie/404-animation.json";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    minHeight: 300,
    textAlign: "center",
    color: "gray",
    paddingTop: "5vh",
  },
  imgContainer: {
    height: 270,
    width: "100%",
    // padding: 25,
  },
  warn: {
    width: 400,
    height: 400,
    color: "gray",
  },
  redirect: {
    height: 30,
    margin: "15px auto",
    textTransform: "capitalize",
  },
  link: {
    display: "inline-block",
    margin: "auto",
  },
  button: {
    margin: 25,
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <LottieAnimation lotti={Document} width={270} height={270} />
      </div>
      <Typography variant="h6" align="center">
        {t("warning.msg.notFound")}
      </Typography>

      <Typography variant="subtitle1" align="center">
        {t("warning.msg.notExist")}
      </Typography>

      <Link to={"/"}>
        <Button variant="contained" color="primary" className={classes.button}>
          {t("warning.buttons.back")}
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
