const GET_STUDENTS_SUCCESS = "get_students_success";
const GET_STUDENTS_ERROR = "get_students_error";
const GET_STUDENTS_PROGRESS = "get_students_progress";

const GET_EQUATIONS_SUCCESS = "GET_EQUATIONS_SUCCESS";
const GET_EQUATIONS_ERROR = "GET_EQUATIONS_ERROR";
const GET_EQUATIONS_PROGRESS = "GET_EQUATIONS_PROGRESS";
const DEL_EQUATION_SUCCESS = "DEL_EQUATION_SUCCESS";
const EDIT_EQUATION_SUCCESS = "EDIT_EQUATION_SUCCESS";
const NEW_EQUATION_SUCCESS = "NEW_EQUATION_SUCCESS";
const EQUATION_DONE = "EQUATION_DONE";
const EQUATION_PROGRESS = "EQUATION_PROGRESS";

const GET_STUDENT_SUCCESS = "get_student_success";
const GET_STUDENT_ERROR = "get_student_error";
const GET_STUDENT_PROGRESS = "get_student_progress";

const SET_EQUATION_STATE_REQUEST = "set_equation_state_request";
const SET_EQUATION_STATE_ERROR = "set_equation_state_error";
const SET_EQUATION_STATE_SUCCESS = "set_equation_state_success";

const CLEAN_ERROR = "clean_error";
const CLEAN = "clean";

export default {
  // all students
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // single student
  // Equations
  GET_EQUATIONS_SUCCESS,
  GET_EQUATIONS_ERROR,
  GET_EQUATIONS_PROGRESS,
  DEL_EQUATION_SUCCESS,
  EDIT_EQUATION_SUCCESS,
  NEW_EQUATION_SUCCESS,
  EQUATION_PROGRESS,
  EQUATION_DONE,
  // single student
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  SET_EQUATION_STATE_REQUEST,
  SET_EQUATION_STATE_ERROR,
  SET_EQUATION_STATE_SUCCESS,
  // ===> CLEAN <===
  CLEAN_ERROR,
  CLEAN,
};
