import Types from "./types";

const INITIAL_STATE = {
  creatingUser: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_NEW_USER_SUCCESS:
      return { creatingUser: false };
    case Types.SET_NEW_USER_ERROR:
      return { creatingUser: false };
    case Types.SET_NEW_USER_REQUEST:
      return { creatingUser: true };
    default:
      return state;
  }
};
