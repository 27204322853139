import React, { useState } from "react";
import "./Ramadan_mubarkCSS.css";
import { Grid, Button, Typography } from "@material-ui/core";
import * as htmlToImage from "html-to-image";

const RamadanMubark = () => {
  const [userName, setuserName] = useState(" ");

  const printDocument = () => {
    htmlToImage
      .toJpeg(document.getElementById("eid"), { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${userName}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <section>
      <div className="EIDmubark">
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Grid id="eid" className="EidAladha">
              <div className="userName">
                <Typography variant="h3" align="center">
                  {userName}
                </Typography>
              </div>
            </Grid>

            <div className="form">
              <label className="label">اكتب الاسم</label>
              <input
                onChange={(event) => setuserName(event.target.value)}
                className="input"
              />
              <Button
                variant="contained"
                className="save"
                type="submit"
                onClick={() => printDocument()}
              >
                صناعة الصورة
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};
export default RamadanMubark;
