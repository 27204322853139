import { combineReducers } from "redux";
import uploadReducer from "./uploadReducer";
import datesReducer from "./datesReducer";
import offerReducer from "./offerReducer";
import updateReducer from "./updateReducer.js";
import majorsReducer from "./majorsReducer";
import majorReducer from "./majorReducer";
import paymentReducer from "./paymentReducer";
import pledgeReducer from "./pledgeReducer";
import equationReducer from "./equationReducer";

export default combineReducers({
  dates: datesReducer,
  upload: uploadReducer,
  offer: offerReducer,
  info: updateReducer,
  majors: majorsReducer,
  major: majorReducer,
  payment: paymentReducer,
  pledge: pledgeReducer,
  equation: equationReducer,
});
