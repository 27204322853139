const AUTH_USER = "AUTH_USER";
const AUTH_ERROR = "AUTH_ERROR";
const AUTH_CLEAN = "AUTH_CLEAN";
const AUTH_REQUEST = "AUTH_REQUEST";

const GET_ANCCMNT = "get_anncmnt";

const GET_CODE_REQUEST = "get_code_request";
const GET_CODE_SUCCESS = "get_code_success";
const GET_CODE_ERROR = "get_code_error";
const GET_CODE_CLEAN = "get_code_error";

const SET_PW_ERROR = "set_pw_error";
const SET_PW_REQUEST = "set_pw_request";

const SEND_INVITE_SUCCESS = "send_invite_success";
const SEND_INVITE_ERROR = "send_invite_error";
const SEND_INVITE_REQUEST = "send_invite_request";
const INVITE_CLEAN = "invite_CLEAN";

const UPDATE_REQUEST = "update_request";
const UPDATE_CLEAN = "update_clean";
const UPDATE_ERROR = "update_error";
const UPDATE_SUCCESS = "update_success";
const GET_ALL_MAJORS="GET_ALL_MAJORS";

const GET_ALL_LOOKUP = "get_all_lookups"
const ADD_LOOKUP = "add_lookup"

export default {
  GET_ALL_LOOKUP,
  GET_ANCCMNT,
  AUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAN,
  AUTH_REQUEST,
  GET_CODE_REQUEST,
  GET_CODE_SUCCESS,
  GET_ALL_MAJORS,
  GET_CODE_ERROR,
  GET_CODE_CLEAN,
  SET_PW_ERROR,
  SET_PW_REQUEST,
  // Zoom Meeting Link
  SEND_INVITE_SUCCESS,
  SEND_INVITE_ERROR,
  SEND_INVITE_REQUEST,
  INVITE_CLEAN,

  UPDATE_REQUEST,
  UPDATE_CLEAN,
  UPDATE_ERROR,
  UPDATE_SUCCESS,
};
