import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { InputLabel, FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { primaryColor } from "../../assets/jss/appStyles";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    margin: "10px auto",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
}));

const  MuiSelectField = ({ label, input, children, required, meta: { touched, invalid, error } }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <FormControl variant="outlined" className={classes.root} error={touched && invalid}>
      <InputLabel>{label}</InputLabel>
      <Select label={label}  {...input}  required={required}>
        {children}
      </Select>

      <FormHelperText>{touched && error && t(`validation.${error}`)}</FormHelperText>
    </FormControl>
  );
};

export default MuiSelectField;
