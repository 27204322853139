import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { primaryColor } from "../../../common/assets/jss/appStyles";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

const useStyls = makeStyles(() => ({
    textContainer: {
        position: 'relative',
        height: 60
    },
    root: {
        margin: "10px auto",
        "& label.Mui-focused": {
        color: primaryColor,
        },
        "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: primaryColor,
        },
    },
    },
    loading: {
        position: 'absolute',
        top: 23,
        right: 10,
        width: '30px !important',
        height: '30px !important'
    }
}));

const MuiTextField = ({ label, type, name, input, disabled, required, placeholder, meta: { touched, asyncValidating, error, invalid } }) => {
  const classes = useStyls();
  const [t] = useTranslation();
  const handlePaste = (e) => {
    e.preventDefault();
  };
  return (
    <div className={classes.textContainer}>
    <TextField
    onPaste={handlePaste}
    name={name}
    placeholder={placeholder}
    className={classes.root}
      label={label}
      disabled={disabled}
      type={type}
      required={required}
      margin="normal"
      variant="outlined"
      fullWidth
      helperText={touched && error && t(`validation.${error}`)}
      error={touched && invalid}
      {...input}
    />

    {asyncValidating && <CircularProgress  className={classes.loading}/>}
    </div>
  );
};

export default MuiTextField;
