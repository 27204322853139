import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { Tooltip, InputAdornment } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useStyles } from "./uploadFieldStyle";
import { toast } from "react-toastify";

const MuiUploadField = ({
  label,
  name,
  description,
  add,
  required,
  remove,
  file
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const [filename, setFilename] = useState(file?file?.name:"");
  const handleClick = () => inputEl.current.click();
  
  const handleChange = (e) => {
    const file = e.target.files[0];
    // size in Kb
    const fileSize = file?.size / 1000
    const fileType = file?.type

    if (file) {
     console.log("the lengt", file.name.length)
      // dont accept the file if its size > 5 Mb
      if (fileSize > 1000) {
        inputEl.current.value = "";
        return toast.error(t("validation.wrongFileSize"))
      }
      // dont accept the file if its not image or pdf
      if (!fileType.includes('image') && !fileType.includes('pdf')) {
        inputEl.current.value = "";
        return toast.error(t("validation.wrongFile"))
      }
      if(file.name.length > 100){
        inputEl.current.value = "";
        return toast.error("File name length should be less than 100")
      }
     // if(!/^[A-Za-z0-9_.\u0600-\u06FF]+$/i.test(file.name)){
        //inputEl.current.value = "";
        //return toast.error("File name should be not contain special character")
      //}}

      add(name, file);
      setFilename(`${e.target.files[0].name}`);
    }
  };

  const handleRemove = (e) => {
    inputEl.current.value = "";
    remove(name);
    setFilename("");
  };

  return (
    <div className={classes.inputContainer}>
      <Tooltip title={description} placement="top-end">
        <TextField
          name={name}
          className={classes.root}
          value={filename}
          label={label}
          required={required}
          margin="normal"
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloudUploadIcon
                  className={classes.icon}
                  onClick={handleClick}
                />
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
      {filename?.length > 0 && (
        <Tooltip title={t("applicant.buttons.remove")} placement="top-end">
          <DeleteForeverIcon onClick={handleRemove} className={classes.clear} />
        </Tooltip>
      )}
      <TextField
        type="file"
        
        inputProps={{accept: "image/jpeg,image/jpg,image/png,application/pdf",}}
        inputRef={inputEl}
        onChange={(e) => handleChange(e)}
        className={classes.hiddenInput}
      />
    </div>
  );
};
export default MuiUploadField;
