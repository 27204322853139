import Types from "./types";

const INITIAL_STATE = {
  student: false,
  loaded: false,
  errors: [],
  settingReview: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_STUDENT_SUCCESS:
      return {
        ...state,
        loaded: true,
        student: action.payload,
        settingReview: false,
      };
    case Types.GET_STUDENT_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingReview: false,
      };
    case Types.GET_STUDENT_PROGRESS:
      return { loaded: false };
    case Types.SET_GRADE_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingReview: false,
      };
    case Types.SET_GRADE_REQUEST:
      return { ...state, settingReview: true };
    case Types.CLEAN_ERROR:
      return {
        student: false,
        loaded: false,
        errors: [],
        settingReview: false,
      };
    default:
      return state;
  }
};
