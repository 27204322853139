import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { connect, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../common/assets/jss/appStyles";
import { addLookUp } from "../../../auth/state/actions";

const filter = createFilterOptions();

const useStyls = makeStyles(() => ({
  root: {
    margin: "10px auto",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
  inputContainer: {
    maxWidth: 500,
  },
  fullWidth: {
    maxWidth: "100%",
  },
}));

export default function MuiAutoCompleteRegistration({
  typename,
  recall,
  val,
  eng_type,
  arabic_type,
  options,
  label,
  name,
  input,
  placeholder,
  disabled,
  fullWidth,
  required,
  meta: { touched, invalid, error },
}) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyls();
  let selectedOption =
    val &&
    options?.filter((op) => {
      return Object.values(op).includes(val.label);
    });
  const allLookUp = (type) => {
    recall(type);
  };
  const [option, setOption] = useState(
    selectedOption && selectedOption.length > 0
      ? selectedOption[0]
      : val || { label: "" }
  );
  return (
    <Autocomplete
      options={options ? options : []}
      getOptionSelected={(option, value) => {
        return true;
      }}
      value={option}
      selectOnFocus
      onChange={(e, newOption) => setOption(newOption)}
      getOptionLabel={(option) =>
        (i18n.language === "en"
          ? option[eng_type]
            :
            option[arabic_type]
        ) || " "
      }
      className={fullWidth ? classes.fullWidth : classes.inputContainer}
      renderInput={(params) => (
        <TextField
          className={classes.root}
          variant="outlined"
          {...params}
          placeholder={placeholder}
          required={required}
          label={label}
          disabled={disabled}
          helperText={touched && error && t(`validation.${error}`)}
          error={touched && invalid}
          {...input}
        />
      )}
    />
  );
}
