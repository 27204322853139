// import { toast } from "react-toastify";
import {
  getcityCode,
  getcountryCode,
  getschoolCode,
  getzoneCode,
} from "./selecteLists";

export const validate = (values) => {
  const errors = {};
  const arabicFields = [
    "arabic_first_name",
    "arabic_middle_name",
    "arabic_last_name",
    "arabic_family_name",
    "superior_name",
  ];

  const arabicWithNumFields = ["arabic_name", "arabic_code"];
  const dateField = ["birth_date"];
  const englishFields = [
    "first_name",
    "middle_name",
    "last_name",
    "family_name",
    "confirm_name",
    "entry_user",
    "english_certf_entry_user",
  ];
  const maxSix = ["high_school_GPA", "previous_GPA"];
  const requiredSelect = [
    "health_state",
    "final_state",
    "health_type",
    "birth_place",
    "nationality",
    "mother_nationality",
    "employment_state",
    "employer",
    "apply_semester",
    "superior_relation",
    "emergency_relation",
    "certificate_country",
    "high_school_city",
    "education_area",
    "high_school_name",
    "secondary_type",
    "eng_type",
    "previous",
    "max_gpa",
    "state_university",
    "tagsir",
    "tagseer_department",
    "tagseer_institute",
    "first_questionare",
    "second_questionare",
    "result",
    "english_certf_result",
    "test_type",
    "score",
    "sat_score",
    "confirm_name",
    "entry_name",
    "marital_status",
    "university",
    "equivalent_to",
    "init_state",
    "final_state",
    // "city",
    "arabic_speaker",
    
  ];
  const requiredText = [
    "first_name",
    "middle_name",
    "arabic_first_name",
    "arabic_middle_name",
    // "building_no",
    // "street_no",
    // "district",
    // "postal_code",
    // "postal_code",
  ];
  const requiredText1 = [
    "arabic_family_name",
    "family_name",
    "last_name",
    "arabic_last_name",
    "name",
    "code",
    "hours",
  ];
  const interviewFields = [
    "outlook",
    "personality",
    "interest",
    "knowledge",
    "fitness",
    "english",
    "comment",
  ];
  const numberValues = [
    // "high_school_GPA",
    // "qiyas_aptitude",
    // "qiyas_achievement",
    "area_code",
    "sat_score",
    // "phone",
    "home_phone",
    "superior_phone",
    "building_no",
    "extra_code",
    "postal_code",
    "street_no",
  ];
  const interviewNumberValues = [
    "outlook",
    "personality",
    "interest",
    "knowledge",
    "fitness",
    "english",
  ];
  let date = new Date();
  // Positive Numbers validation validation
  for (let i = 0; i < numberValues.length; i++) {
    if (
      values[numberValues[i]] &&
      !/^[0-9]\d*$/i.test(values[numberValues[i]])
    ) {
      errors[numberValues[i]] = "invalid";
    }
  }

  // interview Values
  for (let i = 0; i < interviewNumberValues.length; i++) {
    if (
      values[interviewNumberValues[i]] &&
      !/^[0-9]\d*$/i.test(values[interviewNumberValues[i]])
    ) {
      errors[interviewNumberValues[i]] = "invalid";
    }
    if (
      values[interviewNumberValues[i]] &&
      values[interviewNumberValues[i]] > 10
    ) {
      errors[interviewNumberValues[i]] = "Value Between 0 & 10";
    }
  }

  // Arabic chars validation
  for (let i = 0; i < arabicFields.length; i++) {
    if (
      !/^[\u0621-\u064A ]+$/i.test(values[arabicFields[i]]) &&
      /^[^<>%$]+$/i.test(values[arabicFields[i]])
  
    ) {
      errors[arabicFields[i]] = "arOnly";
    }
  }
  if(values?.employer_name?.length > 50){
    errors.employer_name="employeer_validation"
  }

  if(!values.national_file){
    // console.log("the data under file", values)
  }

  // pattern=

  // Arabic chars validation
  for (let i = 0; i < arabicWithNumFields.length; i++) {
    if (
      !/^[\u0621-\u064A0-9 ]+$/i.test(values[arabicWithNumFields[i]]) &&
      /^[^<>%$]+$/i.test(values[arabicWithNumFields[i]])
      && values.degree === "UG"
    ) {
      errors[arabicWithNumFields[i]] = "arOnly";
    }
  }

  // English chars validation
  for (let i = 0; i < englishFields.length; i++) {
    // if (!/^[a-z][a-z\s]*$/i.test(values[englishFields[i]])) {
    if (!/^[a-zA-Z\s]*$/i.test(values[englishFields[i]])) {
      errors[englishFields[i]] = "enOnly";
    }
  }

  for (let i = 0; i < dateField.length; i++) {
    if (
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/i.test(
        values[dateField[i]]
      )
    ) {
      errors[dateField[i]] = "invalidDate";
    }
  }

  // max 6 digits validation
  for (let i = 0; i < maxSix.length; i++) {
    if (
      !/^[0-9]\d*(\.\d+)?$/i.test(values[maxSix[i]]) ||
      values[maxSix[i]] / 100 > 1
    ) {
      errors[maxSix[i]] = "invalid";
    }
    if (values[maxSix[i]]?.length > 6) {
      errors[maxSix[i]] = "mx6";
    }
  }

  // min 2 digits validation
  for (let i = 0; i < requiredText.length; i++) {
    if (values[requiredText[i]]?.length <= 1) {
      errors[requiredText[i]] = "mn2";
    }
  }
  // min 1 digits validation
  for (let i = 0; i < requiredText1.length; i++) {
    if (values[requiredText1[i]]?.length < 1) {
      errors[requiredText1[i]] = "mn1";
    }
  }

  // max 4 digits validation
  for (let i = 0; i < maxSix.length; i++) {
    if (values[maxSix[i]]?.length > 6) {
      errors[maxSix[i]] = "mx6";
    }
  }

  // required select fields
  for (let i = 0; i < requiredSelect.length; i++) {
    if (!values[requiredSelect[i]] && values.degree === "UG") {
      errors[requiredSelect[i]] = "required";
    }
  }

  // Interview Fields Validation
  for (let i = 0; i < interviewFields.length; i++) {
    if (!values[interviewFields[i]]) {
      errors[interviewFields[i]] = "required";
    }
  }

  // max 4 digits validation
  // for (let i = 0; i < maxFour.length; i++) {
  //   if(values[maxFour[i]]?.length > 6) {
  //     errors[maxFour[i]] = "maximum digits is 4";
  //   }
  // }

  for (let i = 0; i < 150; i++) {
    if (!values[`equation_${i}`]) {
      errors[`equation_${i}`] = "required";
    }
  }

  for (let i = 0; i < 150; i++) {
    if (!values[`equation_x_${i}`]) {
      errors[`equation_x_${i}`] = "required";
    }
  }

  if (values.high_school_city) {
    // let cityCode = getcityCode(values.high_school_city)?.code;
    // let countryCode = getcountryCode(values.certificate_country)?.code;

    // if (cityCode !== countryCode) errors.high_school_city = "wrongCity";
  }

  if (values.high_school_name) {
    // let cityCode = getschoolCode(values.high_school_name)?.code;
    // let countryCode = getcountryCode(values.certificate_country)?.code;

    // if (cityCode !== countryCode) errors.high_school_name = "wrongSchool";
  }

  if(values.district?.length > 20){
    errors.district = "districtLength"
  }
  if (values.education_area) {
    // let zoneCode = getzoneCode(values.education_area)?.code;
    // let countryCode = getcountryCode(values.certificate_country)?.code;

    // if (values.education_area === "غير محدد") return;
    // if (zoneCode !== countryCode) errors.education_area = "wrongArea";
  }
  if(values.high_school_year > date.getFullYear()){
    errors.high_school_year = "hghSchoolYear"
  }

  // set Password
  if (
    values.password1 &&
    values.password2 &&
    values.password1 !== values.password2
  ) {
    errors.password2 = "pwMatch";
  }

  // register
  let isSaudi =
    values.nationality === "سعودي" || values.nationality === "Saudi";
  if (isSaudi && !nationalIdValidate(values.national_id)) {
    errors.national_id = "invalidID";
  }
  if (isSaudi &&values.national_id?.length!==10) {
    errors.national_id = "invalidID";
  }
  if (!isSaudi &&values.national_id?.length>18) {
    errors.national_id = "invalidID";
  }
  if (values.phone_code?.length !== 4) {
    errors.phone_code = "phoneCode";
  }
  if (!values.email) {
    errors.email = "emailFormat";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "invalidEmail";
  }
  if (!values.verify_email) {
    errors.verify_email = "emailFormat";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.verify_email = "invalidEmail";
  }else if(values.verify_email!==values.email){
    errors.verify_email = "emailVerify";

  }

  if (!values.email_code) {
    errors.email_code = "emailFormat";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_code)) {
    errors.email_code = "invalidEmail";
  }
  
  if(!/^[A-Za-z]+$/.test(values.first_name)){
    errors.first_name = "No spaces are allowed"
  }
  if(!/^[A-Za-z]+$/.test(values.middle_name)){
    errors.middle_name = "No spaces are allowed"
  }
  if(!/^[A-Za-z]+$/.test(values.last_name)){
    errors.last_name = "No spaces are allowed"
  }
  if(!/^[A-Za-z]+$/.test(values.family_name)){
    errors.family_name = "No spaces are allowed"
  }
  // if (!nationalIdValidate(values.superior_nationalID)) {
  //   errors.superior_nationalID = "invalidID";
  // }
  if (values.superior_nationalID === values.national_id) {
    errors.superior_nationalID = "idMatch";
  }
  // if (values.superior_phone === values.phone) {
  //   errors.superior_phone = "phoneMatch";
  // }
  if (!values.gender) {
    errors.gender = "gender";
  }
  if (!values.password) {
    errors.password = "pw";
  }
  if (!values.password) {
    errors.password = "pw";
  }
  if (
    values.emergency_nationalID &&
    (values.emergency_nationalID === values.national_id ||
      values.emergency_nationalID === values.superior_nationalID)
      && values.degree === "UG"
  ) {
    errors.emergency_nationalID = "idProvided";
  }
  if (
    values.emergency_phone &&
    (values.emergency_phone === values.phone ||
      values.emergency_phone === values.superior_phone)
  ) {
    // errors.emergency_phone = "phoneMatch";
  }
  if (values.postal_code && values.postal_code?.length !== 5) {
    errors.postal_code = "postCode";
  }
  if (values.extra_code && values.extra_code?.length !== 4) {
    errors.extra_code = "len4";
  }
  if (values.building_no && values.building_no?.length !== 4) {
    errors.building_no = "buildingNo";
  }
  if (
    (values.home_phone || values.area_code?.length === 2) &&
    values.home_phone?.length !== 7
  ) {
    // errors.home_phone = "invalidPhone";
  }
  // if (values.home_phone === values.phone?.substring(2)) {
  //   errors.home_phone = "phoneMatch";
  // }
  if (
    (values.area_code || values.home_phone?.length === 7) &&
    values.area_code?.length !== 2
  ) {
    errors.area_code = "invalidPhone";
  }
  // superior phone number
const superiorCode = values?.superior_code?.slice(1);

  // if (values.superior_phone) {
    // if (+values.superior_phone[0] !== 5) {
    //   errors.superior_phone = "phone5";
    // } else if (values.superior_phone.length !== 9) {
    //   errors.superior_phone = "phone9";
    // }
    if(values?.superior_phone?.length>0 &&  superiorCode==="966" )
    {
    if ( values?.superior_phone[0] !== "5" && values.degree === "UG") {
      errors.superior_phone = "phone5";
    
    }}
 
    if ( superiorCode==="966"&& values.superior_phone?.length !== 9 && values.degree === "UG") {
      errors.superior_phone = "phone9";

    }
    // else 
    // if (values.superior_phone?.length < 1) {
    //   errors.superior_phone = "phone12";

    // }
    // if (values.superior_phone && superiorCode!=="966"&&  (values.superior_phone?.length > 12 || values.superior_phone?.length < 10)) {
    //   errors.superior_phone = "phone12";
    // }
  // }
  // emergency phone number
const emergencyCode = values?.emergency_code?.slice(1);

  // if (values.emergency_phone) {
    // if (+values.emergency_phone[0] !== 5) {
    //   errors.emergency_phone = "phone5";
    // } else if (values.emergency_phone.length !== 9) {
    //   errors.emergency_phone = "phone9";
    // }
    if(values?.emergency_phone?.length>0 &&  emergencyCode==="966" )
{
if ( values?.emergency_phone[0] !== "5" && values.degree === "UG") {
  errors.emergency_phone = "phone5";

}}
  
    if ( emergencyCode==="966"&& values.emergency_phone?.length !== 9 && values.degree === "UG") {
      errors.emergency_phone = "phone9";

    }
    // else
    // if (values.emergency_phone?.length < 1) {
    //   errors.emergency_phone = "phone12";

    // }
    // if (values.emergency_phone && emergencyCode!=="966"&&  (values.emergency_phone?.length > 12 || values.emergency_phone?.length < 10)) {
    //   errors.emergency_phone = "phone12";
    // }
  // }
const areaCode = values?.code?.slice(1);
if(values?.phone?.length>0 &&  areaCode==="966" )
{
if ( values?.phone[0] !== "5") {
  errors.phone = "phone5";

}}
    if ( areaCode==="966"&& values.phone?.length !== 9) {
      errors.phone = "phone9";

    }
    // if (values.phone&& areaCode!=="966"&&  (values.phone?.length > 12 || values.phone?.length < 10)) {
    //   errors.phone = "phone12";
    // }
  
  if (+values.previous_GPA > +values.max_gpa) {
    errors.previous_GPA = "invaldGPA";
  }
  if((+values.tagseer_GPA > +values.tagseer_max_gpa)){
    errors.tagseer_GPA = "invaldGPA"
  }
  if (values.high_school_GPA > 100) {
    errors.high_school_GPA = "High School GPA Between 0 & 100";
  }
  
  if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i.test(values.high_school_GPA))
  {
    errors.high_school_GPA = "Please enter proper GPA"
  }
  if (values.tagsir) {
    if (values.tagsir !== "yes" && !values.qiyas_achievement) {
      errors.qiyas_achievement = "required";
    }
    if (values.tagsir !== "yes" && !values.qiyas_aptitude) {
      errors.qiyas_aptitude = "required";
    }
  }
  if (values.qiyas_achievement > 100) {
    errors.qiyas_achievement = "Qiyas Achievment Between 0 & 100";
  }
  if (values.qiyas_aptitude > 100) {
    errors.qiyas_aptitude = "Qiyas Aptitude Between 0 & 100";
  }
  if(values.sat_score < 400){
    console.log("the value", values.sat_score)
    errors.sat_score = "SAT score should be greater than 400";
  }
  if (values.secondary_type === "ادبي") {
    errors.secondary_type = "sorry";
  }
  // emergency ar validate
  if (values.emergency_name) {
    if (
      !/^[\u0621-\u064A ]+$/i.test(values.emergency_name) &&
      /^[^<>%$]+$/i.test(values.emergency_name)
    ) {
      errors.emergency_name = "arOnly";
    }
  }
  // emergency fields
  if (
    values.emergency_phone ||
    values.emergency_nationalID ||
    values.emergency_name ||
    values.emergency_relation
  ) {
    let arr = [
      "emergency_phone",
      "emergency_nationalID",
      "emergency_name",
      "emergency_relation",
    ];
    for (let i of arr) {
      if (!values[i] && values.degree === "UG") {
        errors[i] = "required";
      }
    }
  }
  if (
    values.high_school_year?.length !== 4 ||
    values.high_school_year > date.getFullYear() ||
    values.high_school_year < 1950 ||
    !/^[0-9]*$/i.test(values.high_school_year)
  ) {
    errors.high_school_year = "invalidYR";
  }

  if(!/^[0-9]*$/i.test(values.qiyas_aptitude)){
    errors.qiyas_aptitude = "invalidMark"
  }
  if(!/^[0-9]*$/i.test(values.qiyas_achievement)){
    errors.qiyas_achievement = "invalidMark"
  }
  if(!/^[0-9]*$/i.test(values.sat_score)){
    errors.sat_score = "invalidMark"
  }
  // tagseer_year
  if (
    values.tagseer_year?.length !== 4 ||
    values.tagseer_year > date.getFullYear() ||
    values.tagseer_year < 1950 ||
    !/^[0-9]*$/i.test(values.tagseer_year)
  ) {
    errors.tagseer_year = "invalidYR";
  }

  if (values?.tagseer_GPA > 5) errors.tagseer_GPA = "notGreaterthan5";

  return errors;
};

function isEven(value) {
  if (value % 2 === 0) {
    return true;
  } else {
    return false;
  }
}

function toNumbers(chars) {
  let numbers = chars.map((char) => +char);
  return numbers;
}

function nationalIdValidate(id) {
  if (id) {
    const idLength = 10;
    let chars = Object.values(id);
    if (chars.length < idLength) {
      //the length is always 10 digits
      return false;
    }

    let digits = toNumbers(chars);
    if ((digits[0] === 1 || digits[0] === 2) === false) {
      //n1 is always 1
      return false;
    } else {
      let T1 = 0;
      let T2 = 0;

      for (let i = 0; i < digits.length; i++) {
        if (isEven(i)) {
          //for (n1, n3, n5, n7, n9) do the following:
          //a.	if nx W 2 > 9 then Vx =  (nx W 2)   9
          //b.	else then Vx = (nx W 2)
          //c.	T1 = V1 + V3 + V5 + V7 + V9
          let Vx = 0;
          if (+digits[i] * 2 > 9) {
            Vx = +digits[i] * 2 - 9;
          } else {
            Vx = +digits[i] * 2;
          }
          T1 += Vx;
        } else {
          //for (n2, n4, n6, n8, n10) do the following:
          //a.	T2 = n2 + n4 + n6 + n8
          if (i !== digits.length - 1) {
            T2 += digits[i];
          }
        }
      }
      //T3 = mod[T1 + T2 / 10 ].    (example mod (1.3)=3, mod(0.4)=4)
      let T3 = (T1 + T2) % 10;

      //if T3 = 0  then  n10 = 0    ( check )
      if (T3 === 0) {
        if (digits[9] === 0) {
          return true;
        } else {
          return false;
        }
      } //else then n10 = 10-T3    	 ( check )
      else {
        if (digits[9] === 10 - T3) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}
