import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./config/locales/en/translation.json";
import translationAR from "./config/locales/ar/translation.json";

const languages = ["en", "ar"];

// the translations
const resources = {
  en: {translation: translationEN,},
  ar: {translation: translationAR,},
};

i18n
  .use(Backend)

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: localStorage.getItem("i18nextLng") || "ar",
    lng: localStorage.getItem("i18nextLng") || "ar",
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
