import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, reduxForm ,change as changeFieldValue } from "redux-form";
import { parentInfo, parentInfoMasters } from "../../../../common/helper/getAuthFields";
import { validate } from "../../../../common/helper/validate";
import { useStyles } from "../registerStyles";

// Material UI
import { MenuItem, Grid, Typography } from "@material-ui/core";
import MuiTextFieldPhoneCode from "../../../../common/components/muiTextField/MuiPhoneCode";
import { useDispatch } from "react-redux";
import { getAllMajors } from "../../../state/actions";

const ParentInfo = ({ handleSubmit, initialValues,submitRef, next , form}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const dispatch=useDispatch()
  // useEffect(() => {
  //   dispatch(getAllMajors());
  // }, []);
  console.log("the values inparent info", initialValues, form)
  const isArabicLang = i18n.language === "ar";
  const submit = (values) => {
    next();
  };
  const [superirorCode, setSuperirorCode] = useState("+966");
  const [emergencyCode, setEmergencyCode] = useState("+966");
  useEffect(() => {
    dispatch(changeFieldValue('register', 'superior_code', superirorCode)) 
    dispatch(changeFieldValue('register', 'emergency_code', emergencyCode)) 

  },[superirorCode,emergencyCode]);

  const renderFields = (arr, typeofperson) => {
    return arr.map((fieldData, index) => {
      return (
        <Grid item xs={10} sm={6} lg={3} key={index}>
          {initialValues?.degree === "UG"  ? (
            <>
             {fieldData.type === "select" ? (
            <Field
              name={fieldData.name}
              type={fieldData.type}
              label={t(`register.labels.${fieldData.name}`)}
              required={fieldData.required}
              component={fieldData.component}
            >
              {fieldData.items.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {i18n.language === "en" ? item.label : item.arabic_label}
                
                  </MenuItem>
                );
              })}
              {/* <MenuItem className={classes.clear} value="">
                {t("register.labels.clear")}
              </MenuItem> */}
            </Field>
          ) : fieldData.name === "superior_phone" ||
            fieldData.name === "emergency_phone" ? (
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              // wrap="wrap"
              // spacing={10}
             
            >
              <Field
                option={fieldData.name === "superior_phone"?superirorCode:emergencyCode}
                name={fieldData.name === "superior_phone"?"superior_code":"emergency_Code"}
                type={"select"}
                component={MuiTextFieldPhoneCode}
                onInputChange={(e) =>fieldData.name === "superior_phone"?setSuperirorCode(e):setEmergencyCode(e)}
              />

              <Field
                name={fieldData.name}
                type={fieldData.type}
                placeholder={
                  fieldData.placeholder &&
                  t(`validation.${fieldData.placeholder}`)
                }
                label={t(`register.labels.${fieldData.name}`)}
                required={ fieldData.required}
                component={fieldData.component}
              />
            </Grid>
          ) : (
            <Field
              name={fieldData.name}
              type={fieldData.type}
              placeholder={
                fieldData.placeholder &&
                t(`validation.${fieldData.placeholder}`)
              }
              label={t(`register.labels.${fieldData.name}`)}
              required={fieldData.required}
              component={fieldData.component}
            />
          )}
            </>
          ): 
          (
<>
{fieldData.type === "select" ? (
            <Field
              name={fieldData.name}
              type={fieldData.type}
              label={t(`register.labels.${fieldData.name}`)}
              required={typeofperson === "parent" ? true: false}
              component={fieldData.component}
            >
              {fieldData.items.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {i18n.language === "en" ? item.label : item.arabic_label}
                
                  </MenuItem>
                );
              })}
              {/* <MenuItem className={classes.clear} value="">
                {t("register.labels.clear")}
              </MenuItem> */}
            </Field>
          ) : fieldData.name === "superior_phone" ||
            fieldData.name === "emergency_phone" ? (
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              // wrap="wrap"
              // spacing={10}
             
            >
              <Field
                option={fieldData.name === "superior_phone"?superirorCode:emergencyCode}
                name={fieldData.name === "superior_phone"?"superior_code":"emergency_Code"}
                type={"select"}
                component={MuiTextFieldPhoneCode}
                onInputChange={(e) =>fieldData.name === "superior_phone"?setSuperirorCode(e):setEmergencyCode(e)}
              />

              <Field
                name={fieldData.name}
                type={fieldData.type}
                placeholder={
                  fieldData.placeholder &&
                  t(`validation.${fieldData.placeholder}`)
                }
                label={t(`register.labels.${fieldData.name}`)}
                required={typeofperson === "parent" ? true: false}
                component={fieldData.component}
              />
            </Grid>
          ) : (
            <Field
              name={fieldData.name}
              type={fieldData.type}
              placeholder={
                fieldData.placeholder &&
                t(`validation.${fieldData.placeholder}`)
              }
              label={t(`register.labels.${fieldData.name}`)}
              required={typeofperson === "parent" ? true: false}
              component={fieldData.component}
            />
          )}
</>
          )}
         
        </Grid>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit((values) => submit(values))}>
      <Typography variant="h6">{t("register.titles.parentInfo")}</Typography>
     {initialValues?.degree === "UG" ? (
      <>
       <Grid
        direction={isArabicLang ? "row-reverse" : "row"}
        container
        justify="center"
        spacing={2}
      >
        {renderFields(parentInfo.fileds, "parent")}
      </Grid>
      <Typography variant="h6">{t("register.titles.emerInfo")}</Typography>
      <Grid
        direction={isArabicLang ? "row-reverse" : "row"}
        container
        justify="center"
        spacing={2}
      >
        {renderFields(parentInfo.emergency, "parent")}
      </Grid>
      </>
     )
    :
    (
     <>
      <Grid
      direction={isArabicLang ? "row-reverse" : "row"}
      container
      justify="center"
      spacing={2}
    >
      {renderFields(parentInfo.fileds, "parent")}
    </Grid>
    <Typography variant="h6">{t("register.titles.emerInfo")}</Typography>
    <Grid
      direction={isArabicLang ? "row-reverse" : "row"}
      container
      justify="center"
      spacing={2}
    >
      {renderFields(parentInfo.emergency, "emergency")}
    </Grid>
     </>
    )
    }
      <button ref={submitRef} style={{ display: "none" }}></button>
    </form>
  );
};

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
})(ParentInfo);
// "parentInfo": "Parent / Guardian Info",
// "parentInfo": "بيانات ولي الامر",