import { combineReducers } from "redux";
import getStudentReducer from "./getStudentReducer";
import getAllStudentsReducer from "./getAllStudentsReducer";
import getCertfStudentsReducer from "./getCertfStudentsReducer";
import getCertfStudentReducer from "./getCertfStudentReducer";

export default combineReducers({
  student: getStudentReducer,
  certf_student: getCertfStudentReducer,
  allStudents: getAllStudentsReducer,
  certf_students: getCertfStudentsReducer,
});
