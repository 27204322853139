import Types from "./types";

const INITIAL_STATE = {
  data: false,
  loaded: false,
  errors: [],
  settingFState: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_EQUATION_SUCCESS:
      return { ...state, loaded: true, data: action.payload, settingFState: false };
    case Types.GET_EQUATION_ERROR:
      return { ...state, loaded: true, errors: [...action.payload] };
    case Types.GET_EQUATION_PROGRESS:
      return { loaded: false };
    case Types.SET_EQUATION_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingFState: false,
      };
    case Types.SET_EQUATION_REQUEST:
      return { ...state, settingFState: true };
    case Types.CLEAN_ERROR:
      return {
        data: false,
        loaded: false,
        errors: [],
        settingFState: false,
      };
    default:
      return state;
  }
};
