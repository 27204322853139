import Types from "./types";

const INITIAL_STATE = {
  student: false,
  loaded: false,
  errors: [],
  settingGrade: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_STUDENT_SUCCESS:
      return {
        loaded: true,
        student: action.payload,
        errors: [],
        settingGrade: false,
      };
    case Types.GET_STUDENT_ERROR:
      return { ...state, loaded: true, errors: [...action.payload] };
    case Types.GET_STUDENT_PROGRESS:
      return { ...state, loaded: false };
    case Types.SET_GRADE_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingGrade: false,
      };
    case Types.SET_GRADE_REQUEST:
      return { ...state, settingGrade: true };
    case Types.CLEAN_ERROR:
      return { student: false, loaded: false, errors: [], settingGrade: false };
    default:
      return state;
  }
};
