import { makeStyles } from "@material-ui/core";
import {
  primaryColor,
  dangerColor,
  whiteColor,
} from "../../../common/assets/jss/appStyles";
import backgroundImage from "../../../../src/common/assets/images/bg3.png";

export const useStyles = makeStyles((theme) => ({
  login: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover'
  },
  loginBox: {
    padding: 10,
    position: "relative",
  },
  inner: {
    minWidth: "300px",
    maxWidth: "350px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
  },
  head: {
    width: "100%",
    maxHeight: "100px",
    padding: "20px",
  },
  logo: {
    width: "250px",
    margin: "auto",
    marginTop: "50px",
    display: "block",
  },
  hrbreak: {
    width: "150px",
    margin: "40px auto 0",
    border: ".5px solid #e3e3e3",
  },
  body: {
    width: "100%",
    height: "300px",
    textAlign: "center",
  },
  form: {
    padding: "20px",
  },
  submit: {
    width: "150px",
    backgroundColor: "#1DA1F2",
    margin: "20px",
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },
  error: {
    paddingTop: "20px",
    color: dangerColor,
  },
  buttonProgress: {
    color: primaryColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  // language
  div_lang: {
    position: "absolute",
    left: 10,
  },
  lang: {
    margin: 5,
    color: whiteColor,
    backgroundColor: "#333542",
    border: "none",
    cursor: "pointer",
    width: "30px",
    height: "25px",
    borderRadius: "4px",
    fontFamily: "BahijJanna",
    "&.selected": {
      backgroundColor: "#2196f3",
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      fontSize: 12,
    },
  },
}));
