import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { verifyPhone, phoneRequest } from "../../../state/actions";
import asyncValidate from "../../../../common/helper/asyncValidate";
import { Field, reduxForm, change as changeFieldValue } from "redux-form";
import { basicInfo } from "../../../../common/helper/getAuthFields";
import { validate } from "../../../../common/helper/validate";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Button, Typography, MenuItem, Slide } from "@material-ui/core";
import { dangerColor } from "../../../../common/assets/jss/appStyles";
import { useStyles } from "../registerStyles";

import MuiTextFieldPhoneCode from "../../../../common/components/muiTextField/MuiPhoneCode";
import MuiTextField from "../../../../common/components/muiTextField/MuiTextField";
import { countryNationality, countryShortCode } from "../../../../common/helper/selecteLists";

const PerosnalInfo = ({
  handleSubmit,
  submitRef,
  next,
  form,
  phoneValidate,
  verifyPhone,
  phoneRequest,
  initialValues,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isArabicLang = i18n.language === "ar";
  const dispatch = useDispatch();
  const [option, setOption] = useState("+966");
  // get the initial value from local storage or redux to make sure that the user make successfull phone verify only one time!
  const [valid, setValid] = useState(
    phoneValidate.code || initialValues.phone_code
  );
  const [match, setMatch] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [resendCode, setResendCode] = useState({
    state: false,
    message: t("register.btn.resend"),
  });
  let phone = form.register.values.phone;
  // send code if phone is 12 digits only!
  const cleanedPhoneNumber = form.register.values?.phone
    ?.replace(/\s+/g, "")
    ?.replace("+", "");
  const areaCode = form.register.values?.code?.slice(1);
  let disableCode =
    !phone ||
    phone[0] !== "5" ||
    (areaCode === "966"
      ? cleanedPhoneNumber?.length !== 9
      : !(
          cleanedPhoneNumber?.length >= 10 && cleanedPhoneNumber?.length <= 12
        )) ||
    phoneValidate.loading ||
    resendCode.state;
  let email = form.register.values.email_code;
  let disableEmailCode =
    !email ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      form.register.values.email_code
    ) ||
    phoneValidate.loading ||
    resendCode.state;
  // check the provided verification code
  function checkCode(val) {
    if (val.length === 4) {
      setWrongCode(false);
      if (+val === +phoneValidate.code) {
        setValid(true);
        setMatch(true);
      } else {
        setWrongCode(true);
        setMatch(false);
      }
    }
  }

  const getCode = () => {
    let userPhone = {
      phone: `${form.register.values.code?.slice(1)}${
        form.register.values.phone
      }`,
    };
    let userEmail = {
      email: form.register.values?.email_code,
    };
    phoneRequest();
    verifyPhone(
      form.register.values.code?.slice(1) === "966" ? userPhone : userEmail
    );
    let count = 60;
    let handleResendCountdown = setInterval(() => {
      setResendCode({
        state: true,
        message: `${t("register.btn.resend")} ${count - 1}`,
      });
      count = count - 1;
    }, 1000);
    setTimeout(() => {
      setResendCode({ state: false, message: t("register.btn.resend") });
      clearInterval(handleResendCountdown);
      handleResendCountdown = 0;
    }, 60000);
  };
  useEffect(() => {
   if(i18n.language === 'ar'){
  const selectedNationalityArabic =  basicInfo.nationality.options && basicInfo.nationality.options.filter((item) => item.arabic_label === form.register.values.nationality)
  const countryData= countryNationality.filter((item)=>item.nationality=== selectedNationalityArabic[0]?.label)
  console.log("hte ata", selectedNationalityArabic, countryData)
  const country=countryData[0]?.country
  const phoneCode=countryShortCode.filter((item)=>item.name===country)
   if(phoneCode[0]?.dial_code){
     setOption(phoneCode[0]?.dial_code)
   }else{
     setOption("+966")
   }
   }
   else{
    const countryData= countryNationality.filter((item)=>item.nationality=== form.register.values.nationality)
    const country=countryData[0]?.country
    console.log("hte ata",  countryData)
    const phoneCode=countryShortCode.filter((item)=>item.name===country)
     if(phoneCode[0]?.dial_code){
       setOption(phoneCode[0]?.dial_code)
     }else{
       setOption("+966")
     }
   }
  }, [form.register.values.nationality]);
  useEffect(() => {
    dispatch(changeFieldValue("register", "code", option));
  }, [option]);
  const submit = () => {
    valid && next();
  };

  const renderPhoneField = () => {
    return (
      <div style={{ marginTop: 35 }}>
        {/* Phone FIELD */}
        {/* <Typography align="left" style={{ color: dangerColor }}>{phoneValidate.error}</Typography> */}
        <Grid
          container
          justify="left"
          alignItems="flex-start"
          wrap="wrap"
          spacing={2}
        >
          <div style={{ marginTop: "8px", marginLeft: "8px" }}>
            <Field
              option={option}
              name={"code"}
              type={"select"}
              component={MuiTextFieldPhoneCode}
              onInputChange={(e) => setOption(e)}
            />
          </div>
          <Field
            name={basicInfo.phone.name}
            type={basicInfo.phone.type}
            label={t(`register.labels.${basicInfo.phone.name}`)}
            placeholder={t("validation.phonePlaceHolder")}
            required
            disabled={!phoneValidate.change}
            component={basicInfo.phone.component}
          />

          {form.register.values?.code?.slice(1) === "966" && (
            <Grid item xs={3}>
              <div className={classes.wrapper}>
                {!match && !valid && (
                  <Button
                    onClick={getCode}
                    style={{ textTransform: "capitalize" }}
                    disabled={disableCode}
                    variant="contained"
                    color="primary"
                  >
                    {phoneValidate.code
                      ? resendCode.message
                      : t(`register.btn.send`)}
                  </Button>
                )}
                {phoneValidate.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
        {form.register.values?.code?.slice(1) !== "966" && (
          <Grid
            container
            justify="left"
            alignItems="flex-start"
            wrap="wrap"
            spacing={2}
          >
            <Grid item xs={6}>
              {!match && !valid && (
                <Field
                  name="email_code"
                  label={t("adminTable.email")}
                  type="email"
                  required
                  disabled={!phoneValidate.change}
                  component={MuiTextField}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <div className={classes.wrapper}>
                {!match && !valid && (
                  <Button
                    onClick={getCode}
                    style={{ textTransform: "capitalize" }}
                    disabled={disableEmailCode}
                    variant="contained"
                    color="primary"
                  >
                    {phoneValidate.code
                      ? resendCode.message
                      : t(`register.btn.send`)}
                  </Button>
                )}
                {phoneValidate.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {/* Confirm Phone FIELD */}
        {wrongCode && (
          <Typography
            variant="subtitle2"
            align="left"
            style={{ color: dangerColor }}
          >
            Wrong Code!
          </Typography>
        )}
        {!match && phoneValidate.code && !valid && (
          <Field
            name={basicInfo.confirm.name}
            type={basicInfo.confirm.type}
            label={t(`register.labels.${basicInfo.confirm.name}`)}
            onChange={(e, val) => checkCode(val)}
            required
            component={basicInfo.confirm.component}
          />
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit((values) => submit(values))}>
      <Grid
        direction={isArabicLang ? "row-reverse" : "row"}
        container
        justify="center"
        spacing={2}
      >
        {localStorage.getItem("true") === "true" && "Helloooooo"}
        <Grid item xs={12} sm={7}>
          {/* Nationality */}
          <Field
            required
            fullWidth
            name={basicInfo.nationality.name}
            label={t(`register.labels.${basicInfo.nationality.name}`)}
            val={{
              label: form.register?.values[basicInfo.nationality.name] || "",
            }}
            options={
              basicInfo.nationality.options && basicInfo.nationality.options
            }
            component={basicInfo.nationality.component}
          >
            {basicInfo.nationality.items &&
              basicInfo.nationality.items.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {i18n.language === "en" ? item.label : item.arabic_label}
                </MenuItem>
              ))}
          </Field>

          {/* National ID FIELD */}
          {form.register.values.nationality && (
            <Slide direction="right" in>
              <Field
                name={basicInfo.id.name}
                type={basicInfo.id.type}
                label={t(`register.labels.${basicInfo.id.name}`)}
                required
                component={basicInfo.id.component}
              />
            </Slide>
          )}

          {!initialValues.phone_code && renderPhoneField()}

          <button
            type="submit"
            ref={submitRef}
            style={{ display: "none" }}
          ></button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = ({ form, phoneValidate }) => ({ form, phoneValidate });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
  asyncValidate: asyncValidate.asyncIDValidate,
  asyncBlurFields: ["national_id"],
})(connect(mapStateToProps, { verifyPhone, phoneRequest })(PerosnalInfo));
