import {
  dangerColor,
  whiteColor,
} from "../../../../common/assets/jss/appStyles";
import { makeStyles } from "@material-ui/core/styles";
import { successColor } from "../../../../common/assets/jss/appStyles";

export const useStyles = makeStyles((theme) => ({
  majors: {
    width: "100%",
    height: "100%",
    padding: 25,
    textAlign: "center",
  },
  majorsInner: {
    maxWidth: 800,
    minHeight: 400,
    margin: "25px auto",
    padding: 25,
  },
  majorHeader: {
    margin: "auto",
    padding: 25,
  },
  form: {
    maxWidth: 400,
    margin: "auto",
    padding: 10,
  },
  button: {
    margin: 25,
    textTransform: "capitalize",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  select: {
    maxWidth: 350,
    margin: "10px auto 30px",
  },
  formControl: {
    width: "100%",
  },
  clear: {
    color: dangerColor,
  },
  // Sweet Alert
  cancel: {
    backgroundColor: dangerColor,
    margin: 15,
    textTransform: "capitalize",
    color: whiteColor,
    "&:hover": {
      backgroundColor: "#911e15",
    },
  },
  confirm: {
    margin: 15,
    textTransform: "capitalize",
  },
  addPrio: {
    display: "block",
    margin: "auto",
  },
  add: {
    display: "block",
    color: "#285e2b",
    "&:hover": {
      color: successColor,
    },
  },
  disableAdd: {
    color: "gray",
  },
  selectContainer: {
    position: "relative",
  },
  delete: {
    position: "absolute",
    right: -35,
    top: "58%",
    color: dangerColor,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
