import React from "react";
import {  makeStyles, MenuItem, Select } from "@material-ui/core";
import { countryShortCode } from "../../helper/selecteLists";
import { primaryColor } from "../../assets/jss/appStyles";
const useStyls = makeStyles(() => ({
  root: {
  
    marginTop: "10px",
    marginBottom: "10px",
    display:"flex",
    justifyContent:"flex-start",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
  inputContainer: {
    maxWidth: 500,
  },
  fullWidth: {
    maxWidth: "100%",
  },
  
}));


const MuiTextFieldPhoneCode = ({onInputChange,option}) => {
  const classes = useStyls();
  return (
    <Select
    value={option}
      className={classes.root}
      variant="outlined"
      placeholder={""}
      required={false}
      label={""}
      disabled={false}
      sx={{
        width: 50,
        height: 50,
      }}
      onChange={(e)=>onInputChange(e.target.value)}
      renderValue={(selected) => {
        console.log("the value ",option )
        const selectedCountry = countryShortCode.find((c) => c.dial_code === selected);
        return (
          <div>
            {selectedCountry.dial_code}
          </div>
        );
      }}
    >
      {countryShortCode.map((item,index) => (
        <MenuItem key={index} value={item.dial_code}>
        <div>{item.name}&nbsp;({item.dial_code})</div>
        </MenuItem>
      ))}
    </Select>
     
   
  );
};

export default MuiTextFieldPhoneCode;
