import Types from "./types";

const INITIAL_STATE = {
  error: [],
  loading: false,
  code: null,
  change: true,
  majors:[],
  countries: [],
  cities: [],
  zones:[],
  schools:[]
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CODE_SUCCESS:
      return { code: action.payload, error: [], loading: false, change: false };
    case Types.GET_CODE_ERROR:
      return { error: [...action.payload], code: false, loading: false, change: true };
    case Types.GET_CODE_CLEAN:
      return { error: [], code: null, loading: false,  change: true };
    case Types.GET_CODE_REQUEST:
      return { error: [], code: false, loading: true,  change: false };
      case Types.GET_ALL_MAJORS:
        return {...state, majors:action.payload};
      case Types.GET_ALL_LOOKUP:
        return{...state, [action.payload.name]:action.payload.data}
    default:
      return state;
  }
}
