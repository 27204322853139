import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  offerRes: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.STUD_OFFER_SUCCESS:
      return { errors: [], offerRes: false };
    case Types.STUD_OFFER_REQUEST:
      return { errors: [], offerRes: true };
    case Types.STUD_OFFER_ERR:
      return { errors: [...action.payload], offerRes: false };
    // case Types.STUD_OFFER_CLEAN:
    //   return { errors: [], uploading: false, done: false };
    default:
      return state;
  }
};
