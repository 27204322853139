import Types from "./types";

const INITIAL_STATE = {
  data: false,
  loaded: false,
  error: "",
  progress: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_EQUATIONS_SUCCESS:
      return { loaded: true, data: action.payload, error: "", progress: false };
    case Types.GET_EQUATIONS_ERROR:
      return { ...state, error: action.payload, loaded: true, progress: false };
    case Types.GET_EQUATIONS_PROGRESS:
      return { ...state, loaded: false };
    // edit / remove actions progress
    case Types.EQUATION_PROGRESS:
      return { ...state, progress: true };
    case Types.EQUATION_DONE:
      return { ...state, progress: false };
    case Types.NEW_EQUATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          equivilant_courses: [...state.data.equivilant_courses, action.payload],
        },
        progress: false,
      };
    case Types.DEL_EQUATION_SUCCESS:
      let newData = state.data.equivilant_courses.filter((equation) => +equation.id !== +action.payload.id);
      return { ...state, data: { ...state.data, equivilant_courses: newData }, progress: false };
    case Types.EDIT_EQUATION_SUCCESS:
      let newDataArr = state.data.equivilant_courses;
      const equationIndex = state.data.equivilant_courses.findIndex((equation) => +equation.id === +action.payload.id);
      newDataArr[equationIndex] = action.payload;
      return { ...state, data: { ...state.data }, progress: false };
    case Types.CLEAN:
      return { loaded: false, data: false, error: "" };
    default:
      return state;
  }
};
