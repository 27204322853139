import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  settingEquation: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_EQUATION_SUCCESS:
      return { errors: [], settingEquation: false };
    case Types.SET_EQUATION_PROGRESS:
      return { errors: [], settingEquation: true };
    case Types.SET_EQUATION_ERROR:
      return { errors: [...action.payload], settingEquation: false };
    default:
      return state;
  }
};
