const GET_STUDENTS_SUCCESS = "get_all_students_success";
const GET_STUDENTS_ERROR = "get_all_students_error";
const GET_STUDENTS_PROGRESS = "get_all_students_progress";

const GET_STUDENT_SUCCESS = "get_all_student_success";
const GET_STUDENT_ERROR = "get_all_student_error";
const GET_STUDENT_PROGRESS = "get_all_student_progress";

const SET_GRADE_REQUEST = "set_student_final_state_request";
const SET_GRADE_ERROR = "set_student_final_state_error";

const GET_EQUATIONS_SUCCESS = "get_all_equations_success";
const GET_EQUATIONS_ERROR = "get_all_equations_error";
const GET_EQUATIONS_PROGRESS = "get_all_equations_progress";

const GET_EQUATION_SUCCESS = "get_all_equation_success";
const GET_EQUATION_ERROR = "get_all_equation_error";
const GET_EQUATION_PROGRESS = "get_all_equation_progress";

const SET_EQUATION_REQUEST = "set_equation_request";
const SET_EQUATION_ERROR = "set_equation_error";

const CLEAN_ERROR = "clean_error";
const CLEAN = "clean";

export default {
  // all students
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // single student
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  SET_GRADE_REQUEST,
  SET_GRADE_ERROR,
  // equations
  GET_EQUATIONS_SUCCESS,
  GET_EQUATIONS_ERROR,
  GET_EQUATIONS_PROGRESS,
  GET_EQUATION_SUCCESS,
  GET_EQUATION_ERROR,
  GET_EQUATION_PROGRESS,
  SET_EQUATION_REQUEST,
  SET_EQUATION_ERROR,
  // clean after component unmount
  CLEAN_ERROR,
  CLEAN,
};
