export const HOST = window.location.origin;
export let API = "";
export let APIUniv = "";
export let APIRegis = "";
export let BaseName = "";
export let SystemType = "";
export let FILEAPI =""
export let currentSemester = +localStorage.getItem("semester") || 241;
export let otherAdminsSemester = 241;
export let currentSemesterApplicant = 241;
export let openBachelorCourse = false;
export let filterSemester = JSON.parse(localStorage.getItem("filterSemester")) || [202, 211, 212, 221, 222,231,232,241];

if (process.env.PUBLIC_URL.includes("my.um.edu.sa")) {
  // production server
  BaseName = "portal";
  APIRegis = `${HOST}/${BaseName}`;
  API = "https://my.um.edu.sa/backend";
  APIUniv = "https://my.um.edu.sa/university/portal";
  FILEAPI="https://my.um.edu.sa/backend";
  SystemType = "Production";
}
else if(process.env.PUBLIC_URL.includes("uat.um.edu.sa")){
// production server
BaseName = "portal";
APIRegis = `${HOST}/${BaseName}`;
API = "https://uat.um.edu.sa/backend";
APIUniv = "https://uat.um.edu.sa/university/portal";
FILEAPI="https://uat.um.edu.sa/backend";
SystemType = "UAT";
}
else if (process.env.PUBLIC_URL.includes("37.76.245.93") && !process.env.PUBLIC_URL.includes("37.76.245.93/test")) {
  // staging server
  BaseName = "portal";
  APIRegis = `${HOST}/${BaseName}`;
  API = "http://37.76.245.93/backend";
  APIUniv = "http://37.76.245.93/university/portal";
  SystemType = "Staging";
} else {
  // develop server
  BaseName = "test/portal";
  APIRegis = `${HOST}/${BaseName}`;
  API ="https://uat.um.edu.sa/backend";
  // API = "http://dev.um.edu.sa/backend";
  // API = "http://127.0.0.1:8000";
  APIUniv = "https://uat.um.edu.sa/backend/university/test/portal";
  FILEAPI="https://uat.um.edu.sa/backend";
  SystemType = "Test";
}
if(process.env.PUBLIC_URL.includes("dev.um.edu.sa")){
// devops server
BaseName = "portal";
APIRegis = `${HOST}/${BaseName}`;
API = "http://dev.um.edu.sa/backend";
APIUniv = "http://dev.um.edu.sa/university/portal";
FILEAPI="http://dev.um.edu.sa/backend";
SystemType = "Devops";
}

export const ROLES = {
  1: "applicant",
  2: "admission",
  3: "english",
  4: "scholar",
  5: "supervisor",
  6: "admission",
  7: "deanPH",
  8: "communication",
  9: "deanM",
  10: "deanAS",
  11: "regview",
  12: "englishconf",
  13: "supervisor",
  14: "equation",
  15: "hod",
  90: "super",
  16:"registration",
  17:"deanGS"
};
