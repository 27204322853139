import React, { Suspense } from "react";
import Dashboard from "./SecondDashBoard";
import Loading from "../../loading";

const DBRedirect = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Dashboard />
    </Suspense>
  );
};

export default DBRedirect;
