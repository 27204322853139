import { makeStyles } from "@material-ui/core";
import {
  primaryColor,
  dangerColor,
  lightGrayColor,
  whiteColor,
  successColor
} from "../../../common/assets/jss/appStyles";

export const useStyles = makeStyles((theme) => ({
  register: {
    width: "100%",
    padding: "10px",
    backgroundColor: lightGrayColor,
  },
  inner: {
    margin: "50px auto",
    minWidth: "300px",
    maxWidth: 1350,
    minHeight: "400px",
    position: "relative",
    // top: "50px",
  },
  paper: {
    paddingBottom: 60,
  },
  head: {
    width: "100%",
    maxHeight: "100px",
    padding: "20px",
  },
  logo: {
    width: "250px",
    margin: "auto",
    display: "block",
  },
  hrbreak: {
    width: "150px",
    margin: "20px auto 0",
    border: ".5px solid #e3e3e3",
  },
  body: {
    width: "100%",
    minHeight: "300px",
    textAlign: "center",
  },
  form: {
    padding: "20px",
  },
  submit: {
    width: "100px",
    textTransform: "capitalize",
    fontWeight: "700",
    backgroundColor: "#1DA1F2",
    margin: "20px",
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },
  submit_next: {
    // position: "absolute",
    bottom: 0,
    right: 0,
  },

  submit_back: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  save: {
    width: 190,
    textTransform: "capitalize",
    fontWeight: "700",
    margin: "20px",
  },
  download: {
    width: 190,
    textTransform: "capitalize",
    fontWeight: "700",
    margin: "20px",
    backgroundColor: "#CE2E2B",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#CE2E20",
    },
  },
  formControl: {
    width: "100%",
  },
  error: {
    color: dangerColor,
  },
  buttonProgress: {
    color: primaryColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  preForm: {
    width: "100%",
    height: "280px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "380px !important",
    },
  },
  preForm__inner: {},
  preForm__buttons: {
    width: 175,
    height: 55,
    textTransform: "capitalize",
    fontWeight: "700",
    backgroundColor: "#1DA1F2",
    margin: "20px",
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },
  hijri__div: {
    height: "100%",
    width: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  hijri__typo: {
    fontWeight: "200",
    fontSize: "15px",
    opacity: "0.6",
  },
  hijri: {
    height: "50px",
    width: "200px",
    opacity: "0.5",
    borderRadius: "5px",
    paddingLeft: "10px",
    marginLeft: "5px",
    fontSize: "15px",
    fontWeight: "200px",
  },
  // language
  lang: {
    margin: 5,
    color: whiteColor,
    backgroundColor: "#333542",
    border: "none",
    cursor: "pointer",
    width: "30px",
    height: "25px",
    borderRadius: "4px",
    fontFamily: "BahijJanna",
    "&.selected": {
      backgroundColor: "#2196f3",
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      fontSize: 12,
    },
  },
  modal: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    width: "900px",
    margin: "auto",
    position: "absolute",
    top: "10%",
    left: "10%",
    height: "100%",
    maxHeight: 1000,
    display: "block",
  },
  paper_modal: {
    backgroundColor: theme.palette.background.paper,
    border: "3px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  clear: {
    color: dangerColor,
  },
  announcmentIcon: {
    color: "#1c9ca9",
    marginTop: "10px",
    fontSize: "22px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
    },
  },
  oldRecordNote: {
    fontSize: "25px",
    margin: "5px 5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      margin: "5px 1px",
    },
  },
  oldRecordQuestion: {
    fontSize: "20px",
    margin: "15px 5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      margin: "15px 15px",
    },
  },
  add: {
    display: "block",
    color: "#285e2b",
    "&:hover": {
      color: successColor,
    },
  },
  disableAdd: {
    color: "gray",
  },
}));
