import Types from "./types";

const INITIAL_STATE = {
  documents: false,
  loaded: false,
  error: "",
  pending: false,
  add: false
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_DOCUMENT_SUCCESS:
      return { loaded: true, documents: action.payload, error: "",pending: false };
    case Types.GET_DOCUMENT_ERROR:
      return { ...state, error: action.payload, loaded: false,pending:false };
    case Types.GET_DOCUMENT_PROGRESS:
      return { ...state, loaded: false, pending:true };
    case Types.GET_DOCUMENT_ERROR_CLEAN:
      return { loaded: false, error: "" };
      case Types.ADD_DOCUMENT_SUCCESS:
        return { add: true, error: "" };
      case Types.ADD_DOCUMENT_ERROR:
        return { ...state, error: false, add: false };
      case Types.ADD_DOCUMENT_PROGRESS:
        return { ...state, add: false };
      case Types.ADD_DOCUMENT_ERROR_CLEAN:
        return { add: false, error: "" };
        case Types.EDIT_DOCUMENT_SUCCESS:
          return { loaded: true, error: "" };
        case Types.EDIT_DOCUMENT_ERROR:
          return { ...state, error: false, loaded: false };
        case Types.EDIT_DOCUMENT_PROGRESS:
          return { ...state, loaded: false };
        case Types.EDIT_DOCUMENT_ERROR_CLEAN:
          return { loaded: false, error: "" };
    default:
      return state;
  }
};
