const GET_STUDENTS_SUCCESS = "get_students_success";
const GET_STUDENTS_ERROR = "get_students_error";
const GET_STUDENTS_PROGRESS = "get_students_progress";

const GET_PAID_STUDENTS_SUCCESS = "get_paid_students_success";
const GET_PAID_STUDENTS_ERROR = "get_paid_students_error";
const GET_PAID_STUDENTS_PROGRESS = "get_paid_students_progress";

const GET_STUDENT_SUCCESS = "get_student_success";
const GET_STUDENT_ERROR = "get_student_error";
const GET_STUDENT_PROGRESS = "get_student_progress";

const CHANGE_APP_STATE_ERROR = "change_applicant_state_error";
const CHANGE_APP_INFO_SUCCESS = "change_applicant_information_success";
const CHANGE_APP_STATE_SUCCESS = "change_applicant_state_success";
const CHANGE_APP_FILES_SUCCESS = "change_applicant_files_success";
const CHANGE_APP_SCORES_SUCCESS = "change_applicant_scores_success";
const CHANGE_APP_PRIOS_SUCCESS = "change_applicant_prios_success";
const CHANGE_APP_NOTES_SUCCESS = "change_applicant_notes_success";
const CHANGE_APP_STATE_REQUEST = "change_applicant_state_request";

const GET_NOTES_SUCCESS = "get_notes_success"

const GET_ENGDATES_SUCCESS = "get_eng_dates_success";
const GET_ENGDATES_ERROR = "get_eng_dates_error";
const GET_ENGDATES_PROGRESS = "get_eng_dates_progress";

const GET_INTRDATES_SUCCESS = "get_intrview_dates_success";
const GET_INTRDATES_ERROR = "get_intrview_dates_error";
const GET_INTRDATES_PROGRESS = "get_intrview_dates_progress";

const SET_NEW_USER_REQUEST = "set_new_user_request";
const SET_NEW_USER_SUCCESS = "set_new_user_success";
const SET_NEW_USER_ERROR = "set_new_user_error";

const SET_DATES_PROGRESS = "set_dates_progress";

const SET_ANNCMNT_REQUEST = "set_announcement_request";
const SET_ANNCMNT_SUCCESS = "set_announcement_success";
const SET_ANNCMNT_ERROR = "set_announcement_error";

const GET_EQUATIONS_SUCCESS = "GET_EQUATIONS_SUCCESS";
const GET_EQUATIONS_ERROR = "GET_EQUATIONS_ERROR";
const GET_EQUATIONS_PROGRESS = "GET_EQUATIONS_PROGRESS";
const DEL_EQUATION_SUCCESS = "DEL_EQUATION_SUCCESS";
const EDIT_EQUATION_SUCCESS = "EDIT_EQUATION_SUCCESS";
const NEW_EQUATION_SUCCESS = "NEW_EQUATION_SUCCESS";
const EQUATION_DONE = "EQUATION_DONE";
const EQUATION_PROGRESS = "EQUATION_PROGRESS";


const UPLOAD_SUCCESS = "upload_success";
const UPLOAD_PROGRESS = "upload_progress";
const UPLOAD_ERROR = "upload_error";


const UPLOAD_ERROR_CLEAN = "upload_error_clean";
const GET_DOCUMENT_SUCCESS = "get_document_success";
const GET_DOCUMENT_PROGRESS = "get_document_progress";
const GET_DOCUMENT_ERROR = "get_document_error";


const GET_DOCUMENT_ERROR_CLEAN = "get_document_error_clean";
const ADD_DOCUMENT_SUCCESS = "ADD_document_success";
const ADD_DOCUMENT_PROGRESS = "ADD_document_progress";
const ADD_DOCUMENT_ERROR = "ADD_document_error";


const ADD_DOCUMENT_ERROR_CLEAN = "ADD_document_error_clean";
const EDIT_DOCUMENT_SUCCESS = "EDIT_document_success";
const EDIT_DOCUMENT_PROGRESS = "EDIT_document_progress";
const EDIT_DOCUMENT_ERROR = "EDIT_document_error";


const EDIT_DOCUMENT_ERROR_CLEAN = "EDIT_document_error_clean";

const UPLOAD_OTHER_FILES_SUCCESS = "upload_other_files_success";
const UPLOAD_OTHER_FILES_PROGRESS = "upload_other_files_progress";
const UPLOAD_OTHER_FILES_ERROR = "upload_other_files_error";
const UPLOAD_OTHER_FILES_ERROR_CLEAN ="upload_other_files_error_clean";

const GET_SPECIAL_NEED_STUDENT_SUCCESS = "sucess_get_special_need_student";
const GET_SPECIAL_NEED_STUDENT_ERROR ="error_get_special_need_student";

const CLEAN_ERROR = "clean_error";
const CLEAN = "clean_students table";



export default {
  GET_SPECIAL_NEED_STUDENT_SUCCESS,
  GET_SPECIAL_NEED_STUDENT_ERROR,
  GET_NOTES_SUCCESS,
  // *==> Students <==*
  // fetch applicant
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // fetch paid applicants
  GET_PAID_STUDENTS_SUCCESS,
  GET_PAID_STUDENTS_ERROR,
  GET_PAID_STUDENTS_PROGRESS,
  // fetch single applicant
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  // change applicant information
  CHANGE_APP_INFO_SUCCESS,
  // change applicant initial state
  CHANGE_APP_STATE_ERROR,
  CHANGE_APP_STATE_SUCCESS,
  CHANGE_APP_FILES_SUCCESS,
  CHANGE_APP_SCORES_SUCCESS,
  CHANGE_APP_PRIOS_SUCCESS,
  CHANGE_APP_NOTES_SUCCESS,
  CHANGE_APP_STATE_REQUEST,
  // *==> Equations <==*
  GET_EQUATIONS_SUCCESS,
  GET_EQUATIONS_ERROR,
  GET_EQUATIONS_PROGRESS,
  DEL_EQUATION_SUCCESS,
  EDIT_EQUATION_SUCCESS,
  EDIT_DOCUMENT_SUCCESS,
  NEW_EQUATION_SUCCESS,
  EQUATION_DONE,
  EQUATION_PROGRESS,
  // *==> Dates <==*
  SET_DATES_PROGRESS,

  // English
  GET_ENGDATES_SUCCESS,
  GET_ENGDATES_ERROR,
  GET_ENGDATES_PROGRESS,

  // Interview
  GET_INTRDATES_SUCCESS,
  GET_INTRDATES_ERROR,
  GET_INTRDATES_PROGRESS,

  // *==> Announcement <==*
  SET_ANNCMNT_REQUEST,
  SET_ANNCMNT_SUCCESS,
  SET_ANNCMNT_ERROR,

  // *==> Create New User <==*
  SET_NEW_USER_REQUEST,
  SET_NEW_USER_SUCCESS,
  SET_NEW_USER_ERROR,

  // upload
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPLOAD_PROGRESS,
  UPLOAD_ERROR_CLEAN,

  // get
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_ERROR,
  GET_DOCUMENT_PROGRESS,
  GET_DOCUMENT_ERROR_CLEAN,

  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  ADD_DOCUMENT_PROGRESS,
  ADD_DOCUMENT_ERROR_CLEAN,

  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_ERROR,
  EDIT_DOCUMENT_PROGRESS,
  EDIT_DOCUMENT_ERROR_CLEAN,

  // upload other files
  UPLOAD_OTHER_FILES_SUCCESS,
  UPLOAD_OTHER_FILES_ERROR,
  UPLOAD_OTHER_FILES_PROGRESS,
  UPLOAD_OTHER_FILES_ERROR_CLEAN,

  // *==> Clean <==*
  CLEAN_ERROR,
  CLEAN,
};
