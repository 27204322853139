import { combineReducers } from "redux";
import getAllStudentReducer from "./getAllStudentsReducer";
import getStudentReducer from "./getStudentReducer";
import equationsReducer from "./equationsReducer";

export default combineReducers({
  studentData: getStudentReducer,
  allStudents: getAllStudentReducer,
  equations: equationsReducer,
});
