import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStyles } from "../../majors/majorsStyles";

const ScoreUnavailableConfirm = ({ open, cancel, confirm , form}) => {
  const classes = useStyles();
  const { t , i18n} = useTranslation();

  return (
    <SweetAlert
      show={open}
      info
      showCancel
      confirmBtnText="Confirm Date"
      confirmBtnBsStyle="danger"
      title={t("applicant.majors.alert.title")}
      onConfirm={confirm}
      onCancel={cancel}
      customButtons={
        <React.Fragment>
          <Button
            className={classes.cancel}
            variant="contained"
            onClick={cancel}
          >
            {t("applicant.majors.alert.cancel")}
          </Button>
          <Button
            className={classes.confirm}
            color="primary"
            variant="contained"
            onClick={confirm}
          >
            {t("applicant.majors.alert.confirm")}
          </Button>
        </React.Fragment>
      }
    >
      {i18n.language === "en" ? t("applicant.messages.scoreUnavailability"): (form?.register?.values?.gender === "F" ? t("applicant.messages.scoreUnavailabilityFemale"): t("applicant.messages.scoreUnavailabilityMale")) }
    </SweetAlert>
  );
};

export default ScoreUnavailableConfirm;
