import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { reduxForm, change as changeFieldValue, Field } from "redux-form";
import { validate } from "../../../../common/helper/validate";
// import { useStyles } from "../registerStyles";

import NewDocuments from "./NewDocuments";
// Material UI
import { Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import _ from 'lodash';
import { toast } from "react-toastify";
import {
  PRIOS,
  requiredPrios,
  requiredPrios_masters
} from "../../../../app/applicant/components/majors/majorList";
import SelectMenu from "../../../../app/applicant/components/majors/Select";
import { AddCircle, DeleteForever } from "@material-ui/icons";
import ConfirmMajors from "../../../../app/applicant/components/majors/ConfirmMajors";
import asyncValidate from "../../../../common/helper/asyncValidate";
import { useStyles } from "../../../../app/applicant/components/majors/majorsStyles";
import { getAllMajors } from "../../../state/actions";
import HealthDocuments from "./HealthDocuments";
import TransferDocuments from "./TransferDocuments";
import MastersDocuments from "./MastersDocuments";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";

const UploadDocumentsForm = ({
  handleSubmit,
  phoneValidate,
  submitRef,
  next,
  form,
  disableButton,
}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const [systemOpenMajors, setSystemOpenMajors] = useState(0);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [error, setError] = useState();

  const submit = (values) => {
    if (form.register.values?.applicantEducationState === 1) {
      if (
        form.register?.values?.national_file?.name &&
        form.register.values?.high_school?.name &&
        form.register.values?.photo?.name
      ) {
        if (
          form?.register?.values?.scoreType === 1 &&
          form.register?.values?.engState === "close"
        ) {
          if (
            form?.register?.values?.quadrant_file?.name &&
            form.register?.values?.tahisli_file?.name
          ) {
            setError(" ");
            next();
          }
        } else if (
          form?.register?.values?.scoreType === 1 &&
          form.register?.values?.engState === "open" &&
         ( form.register.values?.english_certf_score > 0 )
        ) {
          if (
            form?.register?.values?.quadrant_file?.name &&
            form.register?.values?.tahisli_file?.name &&
            (form.register?.values?.academic_ielts?.name ||
              form.register?.values?.tofel?.name ||
              form.register?.values?.step?.name)
          ) {
            setError(" ");
            next();
          }
        } else if (
          form?.register?.values?.scoreType === 2 &&
          form.register?.values?.engState === "close"
          && form?.register?.values?.SAT?.name
        ) {
          setError(" ");
          next();
        } else if (
          form?.register?.values?.scoreType === 2 &&
          form.register?.values?.engState === "open" &&
          (form.register.values?.english_certf_score > 0)
          && form?.register?.values?.SAT?.name
        ) {
         
          if (
            form.register?.values?.academic_ielts?.name ||
            form.register?.values?.tofel?.name ||
            form.register?.values?.step?.name
          ) {
            setError(" ");
            next();
          }
        }
      } else {
        setError("Please upload the required files");
      }
    }
    if (form.register.values?.applicantEducationState === 2) {
      if (
        form.register?.values?.national_file?.name &&
        form.register.values?.high_school?.name &&
        form.register.values?.transcript?.name &&
        form.register.values?.photo?.name
      ) {
       
        if (
          form.register?.values?.engState === "open" &&
          (form.register?.values?.academic_ielts?.name ||
            form.register?.values?.tofel?.name ||
            form.register?.values?.step?.name) && (form.register.values?.english_certf_score > 0)
        ) {
        
          setError(" ");
          next();
        } else if (form.register?.values?.engState === "close") {
          setError(" ");
          next();
        }
      } else {
        setError("Please upload the required files");
      }
    }
    if (form.register.values?.applicantEducationState === 3) {
      if (
        form.register?.values?.national_file?.name &&
        form.register.values?.high_school?.name &&
        form.register.values?.transcript?.name &&
        form.register.values?.diploma?.name &&
        form.register.values?.SCFHS?.name &&
        form.register.values?.photo?.name
      ) {
        if (
          form.register?.values?.engState === "open" &&
          (form.register?.values?.academic_ielts?.name ||
            form.register?.values?.tofel?.name ||
            form.register?.values?.step?.name) &&
            (form.register.values?.english_certf_score > 0)
        ) {
          setError(" ");
          next();
        } else if (form.register?.values?.engState === "close") {
          setError(" ");
          next();
        }
      } else {
        setError("Please upload the required files");
      }
    }
    if(form.register.values?.degree === "PG"){
      if(selectedPrios.first_periority === 13 || selectedPrios.first_periority === 14){
       
        if (
          form.register?.values?.bachelor_degree_certificate?.name &&
          form.register?.values?.first_recommendation?.name &&
          form.register?.values?.national_file?.name &&
          form.register.values?.transcript?.name  && (form.register?.values?.engState === "open" ? (
            (form.register?.values?.academic_ielts?.name ||
              form.register?.values?.tofel?.name ||
              form.register?.values?.step?.name) 
          ): true) 
        ) {
       
          setError(" ");
          next();
        }
        else{
          toast.error("Please upload the required files")
          // setError("Please upload the required files");
        }
      }
      if(selectedPrios.first_periority === 15){
        if (
          form.register?.values?.bachelor_degree_certificate?.name &&
          form.register?.values?.first_recommendation?.name &&
          form.register?.values?.national_file?.name &&
          form.register.values?.transcript?.name &&  (form.register?.values?.engState === "open" ? (
            (form.register?.values?.academic_ielts?.name ||
              form.register?.values?.tofel?.name ||
              form.register?.values?.step?.name) 
          ): true)
        ) {
          setError(" ");
          next();
        }
        else{
          toast.error("Please upload the required files")
          // setError("Please upload the required files");
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getAllMajors());
    setSystemOpenMajors(
      phoneValidate?.majors
        ?.filter((major) => major.name !== "NM" && major.name !== "GSE")
        .filter((major) =>
          form.register.values?.gender === "F"
            ? major.status === true
            : major.status_m === true
        )?.length
    );
  }, [phoneValidate?.majors]);

  const [maxmajors, setMaxMajors] = useState(
    form.register.values?.gender === "F"
      ? systemOpenMajors - 1
      : systemOpenMajors
  );
  useEffect(() => {
    setMaxMajors(
      form.register.values?.gender === "F"
        ? systemOpenMajors - 1
        : systemOpenMajors
    );
  }, [systemOpenMajors]);
  const [open, setOpen] = useState(false);
  const [selectedPrios, setSelectedPrios] = useState({});
  const [openPrios, setOpenPrios] = useState([]);
  const [lastPrio, setLastPrio] = useState(2);

  const openSweetAlert = (e) => {
    e.preventDefault();

    setOpen(true);
  };
  const handleChange = (prio) => {
    console.log('the prio', prio)
    // add the selected value to object for later validation
    setSelectedPrios({ ...selectedPrios, [`${prio.name}`]: +prio.value });
  };
  const handleDelPrio = () => {
    // get the deleted periority name
    let unWantedPrio = PRIOS.filter((prio) => prio.id === lastPrio);
    // delete from selected periorities
    delete selectedPrios[unWantedPrio[0].name];

    // update last prio
    let nextPrio = lastPrio > 1 && lastPrio - 1;
    setLastPrio(nextPrio);

    // remove last opened prio from the opened list
    let prios = openPrios.filter((prio) => prio !== lastPrio);
    setOpenPrios(prios);
  };
  const handleAddPrio = () => {
    // update last prio
    // let nextPrio = lastPrio < maxmajors && lastPrio + 1;
    let nextPrio = lastPrio < maxmajors && lastPrio + 1;
    setLastPrio(nextPrio);

    // add last opened prio to the opened list
    setOpenPrios([...openPrios, nextPrio]);
  };
  const handleCancel = () => setOpen(false);
  function renderMaxMajors() {
    dispatch(getAllMajors());
    let equ;
    // high school applicant
    if (form.register.values?.sat_score) {
      equ =
        form.register.values?.sat_score * 0.7 +
        form.register.values?.high_school_GPA * 0.3;
    } else {
      equ =
        form.register.values?.high_school_GPA &&
        form.register.values?.high_school_GPA * 0.3 +
          form.register.values?.qiyas_achievement * 0.4 +
          form.register.values?.qiyas_aptitude * 0.3;
    }

    // transfered applicant
    let previous_gpa = form.register.values?.previous_GPA;
    let max_gpa = form.register.values?.max_gpa;

    // Females are not allowed to choose Industrial Engineering
    // if (form.register.values?.gender === "F") {
    //   setMaxMajors(maxmajors - 1);
    // }

    // Tagsir Applicant
    if (form.register.values?.tagseer_department) {
      const availablePrios = phoneValidate?.majors
        ?.filter((prio) => [3, 4, 5, 6].includes(+prio.id))
        .filter((prio) =>
          form.register.values?.gender === "F"
            ? prio.status === true
            : prio.status_m === true
        )
        .filter((prio) => (prio.name !== "GSE"));
      return setMaxMajors(availablePrios?.length);
    }

    // check the previous gpa
    // if the GPA base is 4
    // Medicine requires 3.5/4 or higher , Pharmacy requires 2.75/4 or higher & Applied Science requires 2/4 or higher
    if (+max_gpa === 4) {
      if (+previous_gpa >= 3.5 || +equ >= 76) {
        setMaxMajors(maxmajors);
      } else if (+previous_gpa >= 2.75 || +equ >= 60) {
        setMaxMajors(maxmajors - 1);
      } else if (+previous_gpa >= 2 || +equ >= 55) {
        setMaxMajors(maxmajors - 2);
      } else {
        setMaxMajors(1);
      }
    } else if (+max_gpa === 5) {
      // if the GPA base is 5
      // Medicine requires 4.5/5 or higher , Pharmacy requires 3.75/5 or higher & Applied Science requires 3/5 or higher
      if (+previous_gpa >= 4.5 || +equ >= 76) {
        setMaxMajors(maxmajors);
      } else if (+previous_gpa >= 3.75 || +equ >= 60) {
        setMaxMajors(maxmajors - 1);
      } else if (+previous_gpa >= 3 || +equ >= 55) {
        setMaxMajors(maxmajors - 2);
      } else {
        setMaxMajors(1);
      }
    } else if (equ) {
      if (+equ >= 76) {
        setMaxMajors(maxmajors);
      } else if (+equ >= 60) {
        setMaxMajors(maxmajors - 1);
      } else if (+equ >= 55) {
        setMaxMajors(maxmajors - 2);
      } else {
        setMaxMajors(1);
      }
    } else {
      setMaxMajors(1);
    }
  }
  useEffect(() => {
    // calculate maximum priorites the applicant can select
    renderMaxMajors();
    // eslint-disable-next-line
  }, []);

  console.log('the ex', selectedPrios)

  return (
    <div>
      <div style={{ color: "red " }}>{error}</div>
      <form onSubmit={handleSubmit((values) => submit(values))}>
        {(!documentsOpen ) ? (
          <div className={classes.majorsInner}>
            <div className={classes.majorHeader}>
              <Typography variant="h5" align="center">
                {t("applicant.titles.prios")}
              </Typography>
            </div>
            {/* Mandatory Priorites */}
            {/* 2 required prios in general and 1 prio only for unqualified student! */}
           {form.register?.values?.degree === "UG" ?
           (
           <>
            {requiredPrios
              .filter((prio) => {
                if (maxmajors === 1) return prio.id === 1;
                return prio;
              })
              .map((prio, index) => {
                return (
                  <SelectMenu
                    key={index}
                    value={form.register.values?.majors}
                    user={{
                      ...form.register.values,
                      majors: phoneValidate?.majors?.sort(
                        (a, b) => a.id - b.id
                      ),
                      high_school: form.register.values?.high_school_GPA,
                      tahsily: form.register.values?.qiyas_achievement,
                      qudrat: form.register.values?.qiyas_aptitude,
                      GPA: form.register.values?.previous_GPA,
                      SAT: form.register.values?.sat_score,
                    }}
                    handleChange={handleChange}
                    name={prio.name}
                    selectedPrios={selectedPrios}
                    validate
                    form={form.register.values?.degree}
                    prefillValue={selectedPrios[prio.name]}
                  />
                );
              })}

            {/* Optional Priorites*/}
            {PRIOS.filter((prio) => openPrios.includes(prio.id)).map(
              (prio, index) => {
                return (
                  <div key={index} className={classes.selectContainer}>
                    <SelectMenu
                      user={{
                        ...form.register.values,
                        majors: phoneValidate.majors,
                        high_school: form.register.values?.high_school_GPA,
                        tahsily: form.register.values?.qiyas_achievement,
                        qudrat: form.register.values?.qiyas_aptitude,
                        GPA: form.register.values?.previous_GPA,
                        SAT: form.register.values?.sat_score,
                      }}
                      form={form.register.values?.degree}
                      handleChange={handleChange}
                      name={prio.name}
                      selectedPrios={selectedPrios}
                      validate
                      prefillValue={selectedPrios[prio.name]}
                    />

                    {prio.id === lastPrio && (
                      <Tooltip title={t("applicant.majors.tooltips.delPrio")}>
                        <DeleteForever
                          onClick={handleDelPrio}
                          className={classes.delete}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              }
            )}
            <IconButton
              disabled={lastPrio >= maxmajors}
              onClick={handleAddPrio}
              aria-label="add prio"
              className={classes.addPrio}
              style={{ marginTop: "15px" }}
            >
              <AddCircle
                className={`${classes.add} ${
                  lastPrio >= maxmajors ? classes.disableAdd : ""
                }`}
              />
            </IconButton>
           </>
           ):
           (
            <>
            {requiredPrios_masters
              .filter((prio) => {
                if (maxmajors === 1) return prio.id === 1;
                return prio;
              })
              .map((prio, index) => {
                return (
                  <SelectMenu
                    key={index}
                    value={form.register.values?.majors}
                    user={{
                      ...form.register.values,
                      majors: phoneValidate?.majors?.sort(
                        (a, b) => a.id - b.id
                      ),
                      high_school: form.register.values?.high_school_GPA,
                      tahsily: form.register.values?.qiyas_achievement,
                      qudrat: form.register.values?.qiyas_aptitude,
                      GPA: form.register.values?.previous_GPA,
                      SAT: form.register.values?.sat_score,
                    }}
                    handleChange={handleChange}
                    name={prio.name}
                    selectedPrios={selectedPrios}
                    validate
                    form={form.register.values?.degree}
                    prefillValue={selectedPrios[prio.name]}
                  />
                );
              })}
            </>
           )
           }
{(_.isEmpty(selectedPrios))  ? (
  <>
          <Button
              // disabled={(selectedPrios.first_periority !== 12 || selectedPrios.first_periority !== 0 ) ? false   : true}
              disabled ={true}
              onClick={() => setOpen(true)}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {t("applicant.majors.buttons.save")}
            </Button>
  </>
)
:
(
  <>
  <Button
              // disabled={(selectedPrios.first_periority !== 12 || selectedPrios.first_periority !== 0 ) ? false   : true}
              disabled ={(((selectedPrios.first_periority === 12 || selectedPrios.first_periority === 0 )) ? true : false)}
              onClick={() => setOpen(true)}
              className={classes.button}no firest priority
              variant="contained"
              color="primary"
            >
              {t("applicant.majors.buttons.save")}
            </Button>
  </>
)
}
           
            <ConfirmMajors
              open={open}
              confirm={() => {
                setDocumentsOpen(true);
                disableButton(false);
                dispatch(changeFieldValue("register", "majors", selectedPrios));
              }}
              cancel={handleCancel}
            />
          </div>
        ) : (
          <>
            {form.register.values?.applicantEducationState === 1 &&  form.register.values?.degree === "UG" &&(
              <NewDocuments />
            )}
            {form.register.values?.applicantEducationState === 2 &&  form.register.values?.degree === "UG" && (
              <TransferDocuments />
            )}
            {form.register.values?.applicantEducationState === 3 &&  form.register.values?.degree === "UG" && (
              <HealthDocuments />
            )}
            {form.register.values?.degree === "PG" && (
              <MastersDocuments selectedPrios={selectedPrios} />
            )}
            {/* {(form.register.values?.scoreType === 1 && (form.register.values?.applicantEducationState === 2 || form.register.values?.applicantEducationState === 3)) && (
          <><MuiUploadField
                  add={addFile}
                  remove={removeFile}
                  
                  name="quadrant_file"
                  label={"Quadrat File"}
                  // description={t("applicant.uploadForm.inputs.tooltips.ssn")}
                  file={form.register?.values?.quadrant_file} />
                  
                  <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    // required
                    name="tahisli_file"
                    label={"Tahsili File"}
                    // description={t("applicant.uploadForm.inputs.tooltips.ssn")}
                    file={form.register?.values?.tahisli_file} /></>
        )} */}
          </>
        )}
        <button
          disable
          type="submit"
          ref={submitRef}
          style={{ display: "none" }}
        ></button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ form, phoneValidate }) => ({ form, phoneValidate });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
  asyncValidate: asyncValidate.asyncIDValidate,
})(connect(mapStateToProps)(UploadDocumentsForm));
