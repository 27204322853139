import { combineReducers } from "redux";
import getStudentReducer from "./getStudentReducer";
import getStudentsReducer from "./getStudentsReducer";
import getDatesReducer from "./getDatesReducer";
import anncmntReducer from "./anncmntReducer";
import getPaidStudentsReducer from "./getPaidStudentsReducer";
import createUserReducer from "./createUserReducer";
import equationsReducer from "./equationsReducer";
import uploadReducer from "./uploadReducer";
import uploadOthersReducer from "./otherFilesReducer";
import getDocumentsReducer from "./getDocumentsReducer";

export default combineReducers({
  student: getStudentReducer,
  students: getStudentsReducer,
  paidStudents: getPaidStudentsReducer,
  dates: getDatesReducer,
  anncmnt: anncmntReducer,
  newUser: createUserReducer,
  equations: equationsReducer,
  upload: uploadReducer,
  uploadOthers: uploadOthersReducer,
  documentDetails: getDocumentsReducer,
});
