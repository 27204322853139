import { combineReducers } from "redux";
import getStudentReducer from "./getStudentReducer";
import getAllStudentsReducer from "./getAllStudentsReducer";
import getAllEquationsReducer from "./getAllEquationsReducer";
import getEquationReducer from "./getEquationReducer";

export default combineReducers({
  student: getStudentReducer,
  allStudents: getAllStudentsReducer,
  allEquations: getAllEquationsReducer,
  equation: getEquationReducer
});
