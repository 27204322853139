import React, { useState } from "react";
import "./landingPageCSS.css";
import logo from "../../assets/images/logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io5";
import { toast } from "react-toastify";
import { useStyles } from "./langStyle";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { APIUniv } from "../../constants";

const LandingPage = ({ history }) => {
  const classes = useStyles();
  // Language Control
  const { t, i18n } = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  // Language Control
  const handleClick = (lang) => {
    let msg = lang === "ar" ? "تم تغير اللغة الي" : "Language Changed to";
    let language = lang === "ar" ? "العربية" : "English";
    toast.success(`${msg} ${language}`, { autoClose: 3000 });
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  return (
    <div className="content">
      <header id="header" className="">
        <div className="header-logo">
          <img src={logo} alt="cover" />
        </div>

        <div className="translationButtons">
          <button onClick={() => handleClick("en")} className={`${classes.lang} ${selected === "en" && "selected"}`}>
            EN
          </button>
          <button onClick={() => handleClick("ar")} className={`${classes.lang} ${selected === "ar" && "selected"}`}>
            عربي
          </button>
        </div>
      </header>

      <section id="home" data-parallax="scroll" className="cover">
        <div className="home-content">
          <div dir={i18n.language === "ar" ? "rtl" : "ltr"} className="row contents">
            <div className="home-content-left">
              <h1 className="text-white font-weight-bold">{t("header.title")}</h1>

              <p className="col-md-3 lead text-white">{t("header.subtitle")}</p>

              <div>
                <Typography style={{ color: "white", fontSize: "1.2rem", marginBottom: "15px" }}>{t("header.loginTitle")}</Typography>
                <a href={APIUniv} target="_blank" rel="noopener noreferrer" referrerPolicy="no-referrer-when-downgrade">
                  <button className="button strokesolid btn-jittery">{t("header.login")}</button>
                </a>
              </div>
              <div>
                <Typography style={{ color: "white", fontSize: "1.2rem", marginBottom: "15px" }}>{t("header.signupTitle")}</Typography>
                <button onClick={() => history.push("/user")} className="button stroke btn-jittery">
                  {t("header.login")}
                </button>
              </div>
            </div>
          </div>

          <div className="social-media-icons">
            <ul className="home-social-list">
              <li className="home-social-list-fa">
                <a
                  href="https://www.facebook.com/AlMaarefa.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                  referrerPolicy="no-referrer-when-downgrade"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li className="home-social-list-lin">
                <a
                  href="https://www.linkedin.com/company/almaarefa"
                  target="_blank"
                  rel="noopener noreferrer"
                  referrerPolicy="no-referrer-when-downgrade"
                >
                  <IoLogoLinkedin />
                </a>
              </li>
              <li className="home-social-list-in ">
                <a href="https://www.instagram.com/almaarefa/" target="_blank" rel="noopener noreferrer" referrerPolicy="no-referrer-when-downgrade">
                  <IoLogoInstagram />
                </a>
              </li>
              <li className="home-social-list-tw">
                <a href="https://twitter.com/Almaarefa" target="_blank" rel="noopener noreferrer" referrerPolicy="no-referrer-when-downgrade">
                  <IoLogoTwitter />
                </a>
              </li>
              <li className="home-social-list-sn">
                <a
                  href="https://www.snapchat.com/add/Al-Maarefa"
                  target="_blank"
                  rel="noopener noreferrer"
                  referrerPolicy="no-referrer-when-downgrade"
                >
                  <FaSnapchatGhost />
                </a>
              </li>
              <li className="home-social-list-wh">
                <a
                  href="https://api.whatsapp.com/send?phone=966550365067"
                  target="_blank"
                  rel="noopener noreferrer"
                  referrerPolicy="no-referrer-when-downgrade"
                >
                  <IoLogoWhatsapp />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(LandingPage);
