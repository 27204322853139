import Types from "./types";

const INITIAL_STATE = {
  student: {},
  loaded: false,
  settingReview: false,
  errors: [],
  specialNeeds:[],
  notes:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_STUDENT_SUCCESS:
      return { ...state, loaded: true, student: action.payload, errors: [] };
    case Types.GET_STUDENT_ERROR:
      return { student: false, loaded: true, errors: action.payload };
    case Types.GET_STUDENT_PROGRESS:
      return { loaded: false };
    case Types.CHANGE_APP_STATE_SUCCESS:
      return { ...state, settingReview: false, errors: [] };
    case Types.GET_SPECIAL_NEED_STUDENT_SUCCESS:
      return { ...state, specialNeeds: action.payload, errors: [] };
    case Types.GET_SPECIAL_NEED_STUDENT_ERROR:
      return { specialNeeds: [], loaded: true, errors: action.payload };
    // change applicant info
    case Types.CHANGE_APP_INFO_SUCCESS:
      return {
        ...state,
        student: { ...state.student, register_data: action.payload },
        settingReview: false,
        errors: [],
      };
    case Types.CHANGE_APP_NOTES_SUCCESS:
      return {
        ...state,
        student: {
          ...state.student,
          profile: { ...state.student.profile, notes: action.payload },
        },
        settingReview: false,
        errors: [],
      };
    case Types.GET_NOTES_SUCCESS:
      return{
        ...state,
        notes:[...action.payload]
      };
    case Types.CHANGE_APP_SCORES_SUCCESS:
      return {
        ...state,
        student: {
          ...state.student,
          profile: { ...state.student.profile, ...action.payload },
        },
        settingReview: false,
        errors: [],
      };
    case Types.CHANGE_APP_STATE_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingReview: false,
      };
    case Types.CHANGE_APP_STATE_REQUEST:
      return { ...state, settingReview: true };
    case Types.CLEAN_ERROR:
      return { student: {}, errors: [], loaded: false };
    default:
      return state;
  }
};
