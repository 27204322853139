import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {  reduxForm, change as changeFieldValue } from "redux-form";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import EngDocumentState from "./EngDocumentState";

// import { useStyles } from "../uploadFormstyles";

const TransferDocuments = ({ submits,form, next }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  const onSubmit = (e) => {
    e.preventDefault();

    // add ther status in formdata objet with files
    // after passing all rquired fields upload formdata file
    dispatch(changeFieldValue("register", "applicant_type", "FS"))
next()
  };

  const addFile = (name, file) => {
    //   add to the form data object
    dispatch(changeFieldValue("register", name, file))

  };

  const removeFile = (name) => {
    dispatch(changeFieldValue("register", name, undefined))}


  const addScore = (e) =>{ 
  dispatch(changeFieldValue("register", "english_certf_score", e.target.value))

}

  const removeScore = () =>{
  dispatch(changeFieldValue("register", "english_certf_score",undefined))

}

  return (
    <div className={classes.form}>
      <form onSubmit={onSubmit} className={classes.formInner}>
        {/*<Typography align="center" variant="h5" className={classes.title}>
            {t("applicant.uploadForm.titles.TS")}
          </Typography>*/}

        <MuiUploadField
          add={addFile}
          remove={removeFile}
          required
          name="national_file"
          label={t("applicant.uploadForm.inputs.labels.ssn")}
          description={t("applicant.uploadForm.inputs.tooltips.ssn")}
          file={form.register?.values?.national_file}
        />

        <MuiUploadField
          required
          name="high_school"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.school")}
          description={t("applicant.uploadForm.inputs.tooltips.school")}
          file={form.register?.values?.high_school}

        />

        <MuiUploadField
          required
          name="transcript"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.transcript")}
          description={t("applicant.uploadForm.inputs.tooltips.transcript")}
          file={form.register?.values?.transcript}

        />

        {Boolean(form.register?.values?.tagseer_department) && (
          <MuiUploadField
            // required
            add={addFile}
            remove={removeFile}
            name="diploma"
            label={t("applicant.uploadForm.inputs.labels.diploma")}
            description={t("applicant.uploadForm.inputs.tooltips.diploma")}
          file={form.register?.values?.diploma}

          />
        )}

        {Boolean(form.register?.values?.tagseer_department) && (
          <MuiUploadField
            // required
            name="SCFHS"
            add={addFile}
            remove={removeFile}
            label={t("applicant.uploadForm.inputs.labels.SCFHS")}
            description={t("applicant.uploadForm.inputs.tooltips.SCFHS")}
          file={form.register?.values?.SCFHS}

          />
        )}

        <MuiUploadField
          name="courses"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.courses")}
          description={t("applicant.uploadForm.inputs.tooltips.courses")}
          file={form.register?.values?.courses}

        />

        {/* {auth.user.work && <MuiUploadField
          name="work_permit"
          add={addFile}
          remove={removeFile}
          label={t("applicant.uploadForm.inputs.labels.courses")}
          description={t("applicant.uploadForm.inputs.tooltips.courses")}
        />} */}

        {form.register?.values?.employment_state === "work" && (
          <MuiUploadField
            // required
            add={addFile}
            remove={removeFile}
            name="employee_card"
            label={t("applicant.uploadForm.inputs.labels.employee_card")}
            description={t("applicant.uploadForm.inputs.tooltips.employee_card")}
          file={form.register?.values?.employee_card}

          />
        )}

<MuiUploadField
            required
            add={addFile}
            remove={removeFile}
            name="photo"
            label={t("applicant.uploadForm.inputs.labels.photo")}
            description={t("applicant.uploadForm.inputs.tooltips.photo")}
          file={form.register.values?.photo}

          />

{(form.register.values?.scoreType === 1) && (
          <><MuiUploadField
                  add={addFile}
                  remove={removeFile}
                  
                  name="quadrant_file"
                  label={"Quadrat File"}
                  description={t('applicant.uploadForm.inputs.tooltips.qudrantFileUpload')}
                  file={form.register?.values?.quadrant_file} />
                  
                  <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    // required
                    name="tahisli_file"
                    label={"Tahsili File"}
                    description={t('applicant.uploadForm.inputs.tooltips.tahsiliFileUpload')}
                    file={form.register?.values?.tahisli_file} /></>
        )}
        {form.register.values?.scoreType === 2 && (
          <MuiUploadField
            add={addFile}
            remove={removeFile}
            name="SAT"
            label="SAT"
            // label={t("applicant.uploadForm.inputs.labels.score")}
            description={t("applicant.uploadForm.inputs.tooltips.score")}
          file={form.register.values?.SAT}

          />
        )}

        {/* <MuiUploadField
          add={addFile}
          remove={removeFile}
          name="mother_id"
          label="Mother`s Valid ID"
          description="for non Saudi Students from Saudi mothers"
        /> */}

        <EngDocumentState add={addFile} remove={removeFile} addScore={addScore} removeScore={removeScore} form={form} />

        {/* <div dir={i18n.language === "en" ? "ltr" : "rtl"}>
          <FormControlLabel
            className={classes.agree}
            required
            control={<Checkbox required checked={checked} onChange={handleChange} color="primary" />}
            label={<Typography variant="subtitle2">{t("applicant.uploadForm.inputs.tooltips.agree")}</Typography>}
          />
        </div> */}

        <button type="submit" ref={submits} className={classes.sub}></button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
    form: "register",
    destroyOnUnmount: false,
  })(connect(mapStateToProps)(TransferDocuments));