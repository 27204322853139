import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.STUD_PLEDGE_SUCCESS:
      return { errors: [], loading: false };
    case Types.STUD_PLEDGE_REQUEST:
      return { errors: [], loading: true };
    case Types.STUD_PLEDGE_ERR:
      return { errors: [...action.payload], loading: false };
    default:
      return state;
  }
};
