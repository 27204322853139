import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

let config = {
  apiKey: "AIzaSyC9JFtzymulzwcABAMrneHhPjRbSnFvClw",
  authDomain: "maarefa-university.firebaseapp.com",
  databaseURL: "https://maarefa-university.firebaseio.com",
  projectId: "maarefa-university",
  storageBucket: "maarefa-university.appspot.com",
  messagingSenderId: "792842610080",
  appId: "1:792842610080:web:0a076817d6853c5a70b0c5",
  measurementId: "G-TMEB41J4YS",
};

const app = firebase.initializeApp(config);
// Get the default database instance for an app
export const database = firebase.database(app);
export const storage = firebase.storage(app);


let studentFirebaseConfig = {
  apiKey: "AIzaSyC9JFtzymulzwcABAMrneHhPjRbSnFvClw",
  authDomain: "maarefa-university.firebaseapp.com",
  databaseURL: "https://maarefa-student.firebaseio.com",
  projectId: "maarefa-university",
  storageBucket: "maarefa-university.appspot.com",
  messagingSenderId: "792842610080",
  appId: "1:792842610080:web:0a076817d6853c5a70b0c5",
  measurementId: "G-TMEB41J4YS",
};

const studentApp = firebase.initializeApp(studentFirebaseConfig, 'other');
// Get the default database instance for an app
export const studentDatabase = firebase.database(studentApp);
export const studentStorage = firebase.storage(studentApp);