const GET_STUDENTS_SUCCESS = "get_eng_students_success";
const GET_STUDENTS_ERROR = "get_eng_students_error";
const GET_STUDENTS_PROGRESS = "get_eng_students_progress";

const GET_CERTF_STUDENTS_SUCCESS = "get_certf_eng_students_success";
const GET_CERTF_STUDENTS_ERROR = "get_certf_eng_students_error";
const GET_CERTF_STUDENTS_PROGRESS = "get_certf_eng_students_progress";

const GET_OTHER_CERTF_STUDENTS_SUCCESS = "get_other_certf_students_success";
const GET_OTHER_CERTF_STUDENTS_ERROR = "get_other_certf_students_error";
const GET_OTHER_CERTF_STUDENTS_PROGRESS = "get_other_certf_students_progress";

const GET_STUDENT_SUCCESS = "get_eng_student_success";
const GET_STUDENT_ERROR = "get_eng_student_error";
const GET_STUDENT_PROGRESS = "get_eng_student_progress";

const GET_CERTF_STUDENT_SUCCESS = "get_certf_student_success";
const GET_CERTF_STUDENT_ERROR = "get_certf_student_error";
const GET_CERTF_STUDENT_PROGRESS = "get_certfstudent_progress";

const SET_GRADE_ERROR = "set_student_grade_error";
const SET_GRADE_REQUEST = "set_student_grade_request";
const SET_FORM_VISIBILITY = "SET_FORM_VISIBILITY";
const SET_GRADE_SUCCESS = "set_student_grade_success";

const SET_STUDENT_CONFIRM = "set_student_confirm";
const SET_CERTF_STUDENT_CONFIRM = "set_cert_student_confirm";

const CLEAN_ERROR = "clean_error";

export default {
  SET_STUDENT_CONFIRM,
  SET_CERTF_STUDENT_CONFIRM,
  // students
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // certfied students
  GET_CERTF_STUDENTS_SUCCESS,
  GET_CERTF_STUDENTS_ERROR,
  GET_CERTF_STUDENTS_PROGRESS,
  // student
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  // certefied student
  GET_CERTF_STUDENT_SUCCESS,
  GET_CERTF_STUDENT_ERROR,
  GET_CERTF_STUDENT_PROGRESS,
  // setGrade
  SET_GRADE_ERROR,
  SET_GRADE_REQUEST,
  SET_FORM_VISIBILITY,
  SET_GRADE_SUCCESS,
  CLEAN_ERROR,
  // other certf students
  GET_OTHER_CERTF_STUDENTS_PROGRESS,
  GET_OTHER_CERTF_STUDENTS_ERROR,
  GET_OTHER_CERTF_STUDENTS_SUCCESS
};
