import Types from "./types";

const INITIAL_STATE = {
  student: false,
  loaded: false,
  errors: "",
  settingGrade: false,
  otherStudents:false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_CERTF_STUDENT_SUCCESS:
      return {
        loaded: true,
        student: action.payload,
        errors: "",
        settingGrade: false,
      };
    case Types.GET_CERTF_STUDENT_ERROR:
      return { ...state, loaded: true, errors: [...action.payload]};
    case Types.GET_CERTF_STUDENT_PROGRESS:
      return { ...state, loaded: false };
    case Types.GET_OTHER_CERTF_STUDENTS_SUCCESS:
      return{ ...state, loaded:true,otherStudents: action.payload, errors:"" }
    case Types.GET_OTHER_CERTF_STUDENTS_ERROR:
      return { ...state, loaded: true, errors: [...action.payload] , otherStudents:false};
    case Types.GET_OTHER_CERTF_STUDENTS_PROGRESS:
      return { ...state, loaded: false };
    case Types.SET_GRADE_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingGrade: false,
      };
    case Types.SET_GRADE_REQUEST:
      return { ...state, settingGrade: true };
    case Types.CLEAN_ERROR:
      return { student: false, loaded: false, errors: [], settingGrade: false };
    default:
      return state;
  }
};
