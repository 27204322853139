import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStyles } from "./majorsStyles";

const ConfirmMajors = ({ open, confirm, cancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SweetAlert
      show={open}
      info
      showCancel
      confirmBtnText="Confirm Date"
      confirmBtnBsStyle="danger"
      title={t("applicant.majors.alert.title")}
      onConfirm={confirm}
      onCancel={cancel}
      customButtons={
        <React.Fragment>
          <Button
            className={classes.cancel}
            variant="contained"
            onClick={cancel}
          >
            {t("applicant.majors.alert.cancel")}
          </Button>
          <Button
            className={classes.confirm}
            color="primary"
            variant="contained"
            onClick={confirm}
          >
            {t("applicant.majors.alert.confirm")}
          </Button>
        </React.Fragment>
      }
    >
      {t("applicant.majors.alert.msg")}
    </SweetAlert>
  );
};

export default ConfirmMajors;
