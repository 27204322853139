import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, Typography, Button, Slide } from "@material-ui/core";
import { successColor, whiteColor } from "../../../common/assets/jss/appStyles";
import { Paper } from "@material-ui/core";
import { CloudDownload, Input } from "@material-ui/icons";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LottieAnimation from "../../../app/common/lottie/LottieAnimation";
import Document from "../../../common/assets/images/lottie/success-animation.json";
import PDF from "../../../common/components/PDF/RegisterationPDF";
import { jsPDF } from "jspdf";
import compose from "recompose/compose";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px auto",
    padding: 20,
    maxWidth: 500,
    minHeight: 350,
    textAlign: "center",
    position: "relative"
  },
  iconContainer: {
    width: "100%",
    height: 270,
  },
  icon: {
    fontSize: "10rem",
    color: successColor,
    margin: "25px auto",
    display: "block",
  },
  btn: {
    maxWidth: 200,
    textTransform: "capitalize",
    fontWeight: "700",
    margin: "20px 5px",
    color: whiteColor,
    backgroundColor: successColor,
    "&:hover": {
      backgroundColor: "#1b3e1c",
    },
  },
  download: {
    maxWidth: 200,
    textTransform: "capitalize",
    fontWeight: "700",
    margin: "20px 5px",
    backgroundColor: "#CE2E2B",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#CE2E20",
    },
  },
  modal: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    width: "900px",
    margin: "auto",
    position: "absolute",
    top: "10%",
    left: "10%",
    height: "100%",
    maxHeight: 1000,
    display: "block",
  },
  paper_modal: {
    backgroundColor: theme.palette.background.paper,
    border: "3px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  // language
  div_lang: {
    position: "absolute",
    left: 10
  },
  lang: {
    margin: 5,
    color: whiteColor,
    backgroundColor: "#333542",
    border: "none",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    borderRadius: "4px",
    "&.selected": {
      backgroundColor: "#2196f3",
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      fontSize: 12,
    },
  },
}));

const RegisterComplete = ({ form, history }) => {
  const classes = useStyles();
  const [registerData, setRegisterData] = useState();
  const doc = new jsPDF({});
  // Language Control
  const {t, i18n} = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  const handleOpen = () => {
    window.open(doc.output("bloburl"), "_blank");
    doc.save(`${registerData?.first_name}.pdf`);
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("data"));
    if (data && Object.keys(data).length > 0) {
      setRegisterData(data);
    }
    return () => { };
  }, []);
  return (
    <>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Paper elevation={3} className={classes.container}>
          <div className={classes.div_lang}>
            <button
              onClick={() => handleClick("en")}
              className={`${classes.lang} ${selected === "en" && "selected"}`}
            >
              EN
          </button>
            <button
              onClick={() => handleClick("ar")}
              className={`${classes.lang} ${selected === "ar" && "selected"}`}
            >
              عربي
          </button>
          </div>
          <div className={classes.iconContainer}>
            {/*<CheckCircle className={classes.icon} />*/}
            <LottieAnimation lotti={Document} width={250} height={250} />
          </div>
         { registerData?.applicant_type !== "TS" && (<><Typography variant="h4" align="center" gutterBottom>
            {t("registred.congratulation")}
          </Typography><Typography variant="subtitle1" align="center">
              {t("registred.success")}.{" "}
              {registerData && (
                <strong>
                  {t("registred.downloaddata")}{" "}
                </strong>
              )}
            </Typography></>)}
          {registerData?.applicant_type === "TS" &&
          (
            <>
            <Typography variant="h4" align="center" gutterBottom>
            {t("registred.congratulation")}
          </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {i18n.language === "en" ? t("registred.transferStudentMessage"): (registerData?.gender === "F" ? t("registred.transferStudentMessageFemale") : t("registred.transferStudentMessageMale") )}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {t("registred.success")}.{" "}
              {registerData && (
                <strong>
                  {t("registred.downloaddata")}{" "}
                </strong>
              )}
            </Typography>
            </>
          )
          }
          <Button
            startIcon={<Input />}
            className={classes.btn}
            variant="contained"
            onClick={() => history.push("/applicant/login")}
          >
            {t("registred.login")}
          </Button>
          {registerData && (
            <Button
              onClick={handleOpen}
              startIcon={<CloudDownload />}
              variant="contained"
              className={classes.download}
            >
              {t("registred.download")}
            </Button>
          )}
        </Paper>
      </Slide>
      {registerData && <PDF
        doc={doc}
        data={registerData}
        read={true}
        major={registerData.first_periority}
      />}
    </>
  );
};

const mapStateToProps = ({ form }) => ({
  form,
});

export default compose(
  connect(mapStateToProps, {}),
  withRouter
)(RegisterComplete);
