import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  Slide,
} from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { successColor, whiteColor } from "../../../common/assets/jss/appStyles";
import { useTranslation } from "react-i18next";
import LottieAnimation from "../../../app/common/lottie/LottieAnimation";
import Document from "../../../common/assets/images/lottie/email-send.json";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px auto",
    padding: 20,
    maxWidth: 500,
    minHeight: 350,
    textAlign: "center",
    position: "relative"
  },
  iconContainer: {
    width: "100%",
    height: 270,
  },
  icon: {
    fontSize: "10rem",
    color: successColor,
    margin: "25px auto",
    display: "block",
  },
  link: {
    margin: 20,
    display: "block",
  },
  btn: {
    color: whiteColor,
    backgroundColor: successColor,
    "&:hover": {
      backgroundColor: "#1b3e1c",
    },
  },
  // language
  div_lang: {
    position: "absolute",
    left: 10
  },
  lang: {
    margin: 5,
    color: whiteColor,
    backgroundColor: "#333542",
    border: "none",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    borderRadius: "4px",
    "&.selected": {
      backgroundColor: "#2196f3",
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      fontSize: 12,
    },
  },
}));

const SentPW = () => {
  const classes = useStyles();
  // Language Control
  const [t, i18n] = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  return (
    <Slide direction="right" in mountOnEnter unmountOnExit>
      <Paper elevation={3} className={classes.container}>
        <div className={classes.div_lang}>
          <button
            onClick={() => handleClick("en")}
            className={`${classes.lang} ${selected === "en" && "selected"}`}
          >
            EN
        </button>
          <button
            onClick={() => handleClick("ar")}
            className={`${classes.lang} ${selected === "ar" && "selected"}`}
          >
            عربي
        </button>
        </div>
        <div className={classes.iconContainer}>
          <LottieAnimation lotti={Document} width={250} height={250} />
        </div>
        <Typography variant="h4" align="center" gutterBottom>
          {t("sentpw.thank")}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {t("sentpw.paswordreset")}
        </Typography>
        <Link to="/applicant/login" className={classes.link}>
          <Button
            startIcon={<InputIcon />}
            className={classes.btn}
            variant="contained"
          >
            {t("sentpw.login")}
          </Button>
        </Link>
      </Paper>
    </Slide>
  );
};

export default SentPW;
