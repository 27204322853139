import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  divContainer:{
    position:"relative",
    width: '100%',
    height:"100%",
    minHeight: "100vh",
    maxHeight:"1000px",
    backgroundColor:"#ffffff",
    margin:"auto",
    // marginTop:"40px",
    // paddingTop:"20px",
    // background: "rgb(255,255,255)",
    // background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 56%, rgba(221,244,250,1) 120%)",
  },
  time:{
    fontSize:'20px',
    fontWeight:'300',
    opacity:'0.15'
  },
  name:{
    fontWeight:"600",
    fontSize:"22px",
  },
  value:{
    fontWeight:"600",
    fontSize:"20px",
    fill:"#B8860B"
  }
}));
