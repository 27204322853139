import React, { Suspense, lazy, useEffect } from "react";
import { ROLES } from "../common/constants";
// import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import ensureAuth from "./ensureAuth";
import Loading from "./common/loading";
import NotFound from "./../common/components/notFound/NotFound";
import DB from "../firebase/services";
import { signOut } from "../auth/state/actions";
// import { toast } from "react-toastify";

const ApplicantLayout = lazy(() => import("./applicant"));
const AdmissionLayout = lazy(() => import("./admission"));
const RegviewLayout = lazy(() => import("./regView"));
const EnglishDeptLayout = lazy(() => import("./englishDept"));
const EnglishConfirmer = lazy(() => import("./englishConf"));
const SupervisorLayout = lazy(() => import("./supervisor"));
const ScholarLayout = lazy(() => import("./scholarship"));
const EquationLayout = lazy(() => import("./equation"));
const Dean = lazy(() => import("./deans"));
const HodLayout = lazy(() => import("./hod"));
const RegistrationLayout = lazy(() => import("./registration"))
const DeanOfGraduateStudiesLayout = lazy(() => import("./deanOfGraduateStudies"))

function App({ user, signOut, history }) {
  const defaultRoute = `/${ROLES[user.role]}` || "/admin/login";
  // const [state, setState] = useState({ RegistrationOpen: "", forceReload: "" });
  // const { t } = useTranslation();

  useEffect(() => {
    DB.getConstants().on("value", handleConstants);
    return () => {
      DB.getConstants().off("value", handleConstants);
    };
    // eslint-disable-next-line
  }, []);

  const handleLocalStorage = (filterSemester) => +user.role !== 1 && localStorage.setItem("filterSemester", JSON.stringify(filterSemester));

  const forceReloadHandler = (forceReload) => {
    if (+localStorage.getItem("RegistrationVersion") === +forceReload) localStorage.setItem("RegistrationVersion", +forceReload);
    else if (+localStorage.getItem("RegistrationVersion") !== +forceReload) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      localStorage.setItem("RegistrationVersion", +forceReload);
      window.location.reload(true);
    }
  };

  const forceReloadSpecificID = (forceReloadID) => {
    if (+localStorage.getItem(`forceReload${user.id}`) === +forceReloadID) localStorage.setItem(`forceReload${user.id}`, +forceReloadID);
    else if (+localStorage.getItem(`forceReload${user.id}`) !== +forceReloadID) { 
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      localStorage.setItem(`forceReload${user.id}`, +forceReloadID);
      window.location.reload(true);
    }
  };

  const handleConstants = (status) => {
    if (status.val()) {
      const { forceReload, filterSemester } = status.val();
      // setState(status.val());
      handleLocalStorage(filterSemester);
      // if ((RegistrationOpen === false && !history.location.pathname.includes("super_applicant")) && +user.role === 1) {
      //   toast.info(t("extra.registrationClosed"), { autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: true });
      //   signOut();
      // }
      forceReload && forceReloadHandler(forceReload);
      status.val()[user.id] !== undefined && forceReloadSpecificID(status.val()[user.id]);
    }
  };

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* {state.RegistrationOpen !== false && <Route path="/applicant" component={ApplicantLayout} />} */}
          <Route path="/applicant" component={ApplicantLayout} />
          {/* <Route path="/super" component={SupervisorLayout} /> */}
          <Route path="/super_applicant" component={ApplicantLayout} />
          <Route path="/registration" component={RegistrationLayout} />
          <Route path="/admission" component={AdmissionLayout} />
          <Route path="/deanGS" component={DeanOfGraduateStudiesLayout} />
          <Route path="/regview" component={RegviewLayout} />
          <Route path="/english" component={EnglishDeptLayout} />
          <Route path="/englishconf" component={EnglishConfirmer} />
          <Route path="/deanM" component={Dean} />
          <Route path="/deanPH" component={Dean} />
          <Route path="/deanAS" component={Dean} />
          <Route path="/hod" component={HodLayout} />
          <Route path="/supervisor" component={SupervisorLayout} />
          <Route path="/scholar" component={ScholarLayout} />
          <Route path="/equation" component={EquationLayout} />
          <Redirect from="/" to={defaultRoute} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default compose(connect("", { signOut }))(withRouter(ensureAuth(App)));
