import React from "react";
import { makeStyles, Typography, Paper, Slide } from "@material-ui/core";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import { tertiaryColor } from "../../../common/assets/jss/appStyles";

const useStyles = makeStyles(() => ({
  container: {
    width: "99%",
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    margin: "20px auto",
    padding: 20,
    maxWidth: 500,
    minHeight: 400,
    textAlign: "center",
  },
  iconContainer: {
    width: "100%",
    height: 200,
  },
  icon: {
    fontSize: "10rem",
    color: tertiaryColor,
    margin: "25px auto",
    display: "block",
  },
}));

const RegisterEnd = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Paper elevation={3} className={classes.innerContainer}>
          <div className={classes.iconContainer}>
            <TimerOffIcon className={classes.icon} />
          </div>
          <Typography variant="h6" align="center">
            نآسف تم إغلاق التسجيل للفصل الحالي شكرا لزيارتكم
          </Typography>
          <Typography variant="h6" align="center">
            We are sorry the registration is closed now Thank you for reaching
            out
          </Typography>
        </Paper>
      </Slide>
    </div>
  );
};

export default RegisterEnd;
