import { makeStyles } from "@material-ui/core/styles";
import {
  whiteColor,
  dangerColor,
  fontColor,
} from "../../../../common/assets/jss/appStyles";

const controls = {
  width: 100,
  margin: 10,
  color: whiteColor,
  textTransform: "capitalize",
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    maxWidth: 1100,
    height: "100%",
    margin: 30,
    borderRadius: 6,
    overflow: "hidden",
    position: "relative",
  },
  nextButton: {
    ...controls,
    position: "absolute",
    right: "0",
    bottom: 5,
  },
  backButton: {
    ...controls,
    position: "absolute",
    left: "0",
    bottom: 5,
  },
  form: {
    maxWidth: 400,
    margin: "auto",
    minHeight: 200,
    padding: 20,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 280,
      margin: "auto",
    },
  },
  formInner: {
    marginBottom: 50,
  },
  title: {
    marginBottom: 20,
    color: fontColor,
  },
  sub: {
    display: "none",
  },
  error: {
    color: dangerColor,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    width: 150,
  },
  agree: {
    margin: "20px auto",
  },
  nextButtonReg: {
    ...controls,
  },
  backButtonReg: {
    ...controls,
  },
}));
