import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../common/assets/jss/appStyles";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
// import MailIcon from "@material-ui/icons/Mail";
// import LockIcon from "@material-ui/icons/Lock";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyls = makeStyles(() => ({
  root: {
    margin: "10px auto",
    position:"relative",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& svg": {
      position:"absolute",
      right:15,
      top:15,
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
    "& .MuiOutlinedInput-adornedEnd":{
      padding:0
    },
    "& .MuiFormLabel-root.Mui-error":{
      fontSize:14
    },
    "& .MuiFormLabel-root.Mui-required":{
      fontSize:14
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" :{
      transform: "translate(14px, -6px) scale(0.87)"
    }
  },
   //eyeIcon
  eyeIcon: {
    "&:hover": {
      cursor: "pointer",
    }
  }
}));

const MuiTextField = ({
  label,
  type,
  name,
  input,
  disabled,
  multiline,
  required,
  placeholder,
  rows = 1,
  max,
  meta: { touched, invalid, error },
  dir
}) => {
  const classes = useStyls();
  const { t } = useTranslation();
  const [values, setValues] = useState({showPassword: false});

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <TextField
      dir={dir}
      name={name}
      placeholder={placeholder}
      className={classes.root}
      multiline={multiline}
      InputProps={{
        // startAdornment: 
        //   (type === "email" && <MailIcon />) ||
        //   (type === "number" && <MailIcon />) ||
        //   (type === "password" && <LockIcon />),
        endAdornment :
          type === "password" &&
          <div className={classes.eyeIcon} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
            {values.showPassword ? <Visibility /> : <VisibilityOff />}
          </div>,
      }}
      inputProps={
        type === "number" 
          ? {  
              inputMode: 'decimal',
              pattern: "[+]?([0-9]*[.])?[0-9]+",
              step: "0.01",
              min: "0",
              max
            } 
          : {}}
      rows={rows}
      label={label}
      disabled={disabled}
      type={type === "password" ? values.showPassword ? "text" : "password" : type}
      required={required}
      InputLabelProps={
        type === "date" || type === "time" || placeholder
          ? {
            shrink: true,
          }
          : null
      }
      margin="normal"
      variant="outlined"
      fullWidth
      helperText={touched && error && t(`validation.${error}`)}
      error={touched && invalid}
      {...input}
    />
  );
};

export default MuiTextField;