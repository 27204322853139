import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { reduxForm, change as changeFieldValue } from "redux-form";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import { validate } from "../../../../common/helper/validate";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import EngDocumentState from "./EngDocumentState";

const NewDocuments = ({ submits, next, form }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [scoreType, setScoreType] = useState(
    form.register.values?.scoreType ? form.register.values?.scoreType : ""
  );

  const [national_fileError, setnational_fileError] = useState();
  const[high_schoolError, sethigh_schoolError] = useState();
  const [GATError, setGATError] = useState();

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();

    // add ther status in formdata objet with files
    // after passing all rquired fields upload formdata file

    dispatch(changeFieldValue("register", "applicant_type", "FS"));
  
 
    // next();
  };

  const addFile = (name, file) => {
   
    dispatch(changeFieldValue("register", name, file));

  
  };

  const addScore = (e) => {
    dispatch(
      changeFieldValue("register", "english_certf_score", e.target.value)
    );
  };

  const removeScore = () => {
    dispatch(changeFieldValue("register", "english_certf_score", undefined));
  };

  const removeFile = (name) => {
    dispatch(changeFieldValue("register", name, undefined));
  };

  const changeScoreType = (event) => {
    dispatch(changeFieldValue("register", "scoreType", event.target.value));

    setScoreType(event.target.value);
  };
  return (
    <div className={classes.form}>
      <form onSubmit={onSubmit} className={classes.formInner}>
        {/*<Typography align="center" variant="h5" className={classes.title}>
          {t("applicant.uploadForm.titles.FS")}
        </Typography>*/}
        
        <MuiUploadField
          add={addFile}
          remove={removeFile}
          required
          name="national_file"
          label={t("applicant.uploadForm.inputs.labels.ssn")}
          description={t("applicant.uploadForm.inputs.tooltips.ssn")}
          file={form.register.values?.national_file}
        />
        <MuiUploadField
          required
          add={addFile}
          remove={removeFile}
          name="high_school"
          label={t("applicant.uploadForm.inputs.labels.school")}
          description={t("applicant.uploadForm.inputs.tooltips.school")}
          file={form.register.values?.high_school}
        />
        {/* {form.register.values?.scoreType === 1 && (
          <>
            <MuiUploadField
              add={addFile}
              remove={removeFile}
              name="GAT"
              label="GAT"
              // label={t("applicant.uploadForm.inputs.labels.score")}
              description={t("applicant.uploadForm.inputs.tooltips.score")}
              file={form.register.values?.GAT}
            />
            <MuiUploadField
              add={addFile}
              remove={removeFile}
              name="SAAT"
              label="SAAT"
              // label={t("applicant.uploadForm.inputs.labels.score")}
              description={t("applicant.uploadForm.inputs.tooltips.score")}
              file={form.register.values?.SAAT}
            />
          </>
        )} */}
        {form.register.values?.scoreType === 2 && (
          <MuiUploadField
            add={addFile}
            remove={removeFile}
            name="SAT"
            label="SAT"
            // label={t("applicant.uploadForm.inputs.labels.score")}
            description={t("applicant.uploadForm.inputs.tooltips.score")}
            file={form.register.values?.SAT}
          />
        )}
        {form.register?.values?.employment_state === "work" && (
          <MuiUploadField
            required
            add={addFile}
            remove={removeFile}
            name="employee_card"
            label={t("applicant.uploadForm.inputs.labels.employee_card")}
            description={t(
              "applicant.uploadForm.inputs.tooltips.employee_card"
            )}
            file={form.register.values?.employee_card}
          />
        )}
        <MuiUploadField
            required
            add={addFile}
            remove={removeFile}
            name="photo"
            label={t("applicant.uploadForm.inputs.labels.photo")}
            description={t("applicant.uploadForm.inputs.tooltips.photo")}
            file={form.register.values?.photo}
          />

{(form.register.values?.scoreType === 1) && (
          <><MuiUploadField
                  add={addFile}
                  remove={removeFile}
                  required
                  name="quadrant_file"
                  label={"Quadrat File"}
                  description={t('applicant.uploadForm.inputs.tooltips.qudrantFileUpload')}
                  file={form.register?.values?.quadrant_file} />
                  
                  <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    required
                    name="tahisli_file"
                    label={"Tahsili File"}
                    description={t('applicant.uploadForm.inputs.tooltips.tahsiliFileUpload')}
                    file={form.register?.values?.tahisli_file} /></>
        )}
        
        {/* <MuiUploadField
          add={addFile}
          remove={removeFile}
          name="mother_id"x
          label="Mother`s Valid ID"
          description="for non Saudi Students from Saudi mothers"
        /> */}
        <EngDocumentState
          form={form}
          add={addFile}
          remove={removeFile}
          addScore={addScore}
          removeScore={removeScore}
        />
        <button
          type="submit"
          ref={submits}
          style={{ display: "none" }}
        ></button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate
})(connect(mapStateToProps)(NewDocuments));
