import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./majorsStyles";
import { FormControl, InputLabel, Select, Typography } from "@material-ui/core";
import { getMajorLabels, getPrioLabel } from "./majorList";
// import { dangerColor } from "../../../../common/assets/jss/appStyles";

const SelectMenu = ({
  user,
  handleChange,
  name,
  selectedPrios,
  validate,
  prefillValue,
  form
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [option, setOption] = useState(prefillValue ? prefillValue : "");


  const change = (e) => {
    setOption(e.target.value);
    handleChange(e.target);
  };

  var equ;
  // high school applicant
  if (user.SAT && user.SAT >= 400) {
    equ = user.high_school * 0.3 + user.SAT * 0.7;
  } else if (user.tahsily && user.qudrat) {
    equ =
      user.high_school &&
      user.high_school * 0.3 + user.tahsily * 0.4 + user.qudrat * 0.3;
  } else {
    equ = user.high_school && user.high_school * 0.3;
  }

  // transfered applicant
  let previous_gpa = user.GPA;
  let max_gpa = user.max_gpa;



  // here is the priorities conditions
  const renderList = () => {
    
    if(form === "UG"){
      if (validate) {
        // ===> Tagsir Applicants
        if (user?.tagseer_department)
          return user?.majors
            ?.filter((prio) => {
              return (
                prio.id === 3 || prio.id === 4 || prio.id === 5 || prio.id === 6
              );
            })
            .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
            .filter((prio) => {
              if (user.gender === "F") return prio.status === true;
              else return prio.status_m === true;
            })
            .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS"))
            .map((prio, index) => {
              return (
                <option key={index} value={prio.id}>
                  {t(`applicant.majors.${prio.name}`)}
                </option>
              );
            });
  
        // new_change Gender filter removed
  
        return (
          user?.majors
            // ?.filter((prio) => {
            //   // Females are not allowed to choose Industrial Engineering
            //   if (user.gender === "F") {
            //     return prio.id !== 10;
            //   } else {
            //     return prio;
            //   }
            // })
            ?.filter((prio) => {
              // check the previous gpa
              // if the GPA base is 4
              // Medicine requires 3.5/4 or higher & Pharmacy requires 2.75/4 or higher
              // Medicine requires 76 or higher & Pharmacy requires 55 or higher
              if (max_gpa == 4) {
                if (previous_gpa >= 3.5 || equ >= 76) {
                  return (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (previous_gpa >= 2.75 || equ >= 60) {
                  return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (previous_gpa >= 2 || equ >= 55) {
                  return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else {
                  return prio.id === 12;
                }
              } else if (max_gpa == 5) {
                // if the GPA base is 5
                // Medicine requires 4.5/4 or higher & Pharmacy requires 3.75/4 or higher
                // Medicine requires 76 or higher & Pharmacy requires 55 or higher
                if (previous_gpa >= 4.5 || equ >= 76) {
                  return (prio.id !== 12)& (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (previous_gpa >= 3.75 || equ >= 60) {
                  return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (previous_gpa >= 3 || equ >= 55) {
                  return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else {
                  return prio.id === 12;
                }
              } else if (equ) {
                if (equ >= 76) {
                  return( prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (equ >= 60) {
                  return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else if (equ >= 55) {
                  return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
                } else {
                  return prio.id === 12;
                }
              } else {
                // in this case the student is unqualified so return no majors
                return prio.id === 12;
              }
            })
            .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
            .filter((prio) => {
              if (user.gender === "F") return prio.status === true;
              else return prio.status_m === true;
            })
            .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS") )
            .map((prio, index) => {
              return (
                <option key={index} value={prio.id}>
                  {t(`applicant.majors.${prio.name}`)}
                </option>
              );
            })
        );
      } else {
        console.log('the akeue ids low')
        return user.majors
          .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
          .filter((prio) => {
            if (user.gender === "F") return prio.status === true;
            else return prio.status_m === true;
          })
          .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS"))
          .map((prio, index) => {
            return (
              <option key={index} value={prio.id}>
                {t(`applicant.majors.${prio.name}`)}
              </option>
            );
          });
      }
    }
    else{
      return user.majors
      ?.filter((prio) => !Object.values(selectedPrios).includes(prio.id))
      ?.filter((prio) => {
        if (user.gender === "F") return prio.status === true;
        else return prio.status_m === true;
      })
      ?.filter((prio) => (prio.name === "MEDN") || (prio.name === "MCP") || (prio.name === "MCS") )
      ?.map((prio, index) => {
        return (
          <option key={index} value={prio.id}>
            {t(`applicant.majors.${prio.name}`)}
          </option>
        );
      });
    }
   
  };

  // const renderList = () => {
  //   if (validate) {
  //     // ===> Tagsir Applicants
  //     if (user?.tagseer_department)
  //       return user?.majors
  //         ?.filter((prio) => {
  //           return (
  //             prio.id === 3 || prio.id === 4 || prio.id === 5 || prio.id === 6
  //           );
  //         })
  //         .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
  //         .filter((prio) => {
  //           if (user.gender === "F") return prio.status === true;
  //           else return prio.status_m === true;
  //         })
  //         .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS"))
  //         .map((prio, index) => {
  //           return (
  //             <option key={index} value={prio.id}>
  //               {t(`applicant.majors.${prio.name}`)}
  //             </option>
  //           );
  //         });

  //     // new_change Gender filter removed

  //     return (
  //       user?.majors
  //         // ?.filter((prio) => {
  //         //   // Females are not allowed to choose Industrial Engineering
  //         //   if (user.gender === "F") {
  //         //     return prio.id !== 10;
  //         //   } else {
  //         //     return prio;
  //         //   }
  //         // })
  //         ?.filter((prio) => {
  //           // check the previous gpa
  //           // if the GPA base is 4
  //           // Medicine requires 3.5/4 or higher & Pharmacy requires 2.75/4 or higher
  //           // Medicine requires 76 or higher & Pharmacy requires 55 or higher
  //           if (max_gpa == 4) {
  //             if (previous_gpa >= 3.5 || equ >= 76) {
  //               return (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (previous_gpa >= 2.75 || equ >= 60) {
  //               return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (previous_gpa >= 2 || equ >= 55) {
  //               return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else {
  //               return prio.id === 12;
  //             }
  //           } else if (max_gpa == 5) {
  //             // if the GPA base is 5
  //             // Medicine requires 4.5/4 or higher & Pharmacy requires 3.75/4 or higher
  //             // Medicine requires 76 or higher & Pharmacy requires 55 or higher
  //             if (previous_gpa >= 4.5 || equ >= 76) {
  //               return (prio.id !== 12)& (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (previous_gpa >= 3.75 || equ >= 60) {
  //               return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (previous_gpa >= 3 || equ >= 55) {
  //               return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else {
  //               return prio.id === 12;
  //             }
  //           } else if (equ) {
  //             if (equ >= 76) {
  //               return( prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (equ >= 60) {
  //               return (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else if (equ >= 55) {
  //               return (prio.id !== 2) & (prio.id !== 1) & (prio.id !== 12) & (prio.id !== 13)  & (prio.id !== 14 ) & (prio.id !== 15);
  //             } else {
  //               return prio.id === 12;
  //             }
  //           } else {
  //             // in this case the student is unqualified so return no majors
  //             return prio.id === 12;
  //           }
  //         })
  //         .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
  //         .filter((prio) => {
  //           if (user.gender === "F") return prio.status === true;
  //           else return prio.status_m === true;
  //         })
  //         .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS") )
  //         .map((prio, index) => {
  //           return (
  //             <option key={index} value={prio.id}>
  //               {t(`applicant.majors.${prio.name}`)}
  //             </option>
  //           );
  //         })
  //     );
  //   } else {
  //     return user.majors
  //       .filter((prio) => !Object.values(selectedPrios).includes(prio.id))
  //       .filter((prio) => {
  //         if (user.gender === "F") return prio.status === true;
  //         else return prio.status_m === true;
  //       })
  //       .filter((prio) => (prio.name !== "GSE") & (prio.name !== "MCP") & (prio.name !== "MEDN") & (prio.name !== "MCS"))
  //       .map((prio, index) => {
  //         return (
  //           <option key={index} value={prio.id}>
  //             {t(`applicant.majors.${prio.name}`)}
  //           </option>
  //         );
  //       });
  //   }
  // };
  return (
    <React.Fragment>
      <Typography variant="subtitle1" align="center">
        {t("applicant.majors.inputs.select")}{" "}
        {t(`applicant.majors.inputs.${getPrioLabel(name)}`)}
      </Typography>

      <div className={classes.select}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-second-native-simple">
         
          </InputLabel>
          <Select
            native
            required
            value={option}
            onChange={change}
            label="select"
            inputProps={{
              name: name,
              id: "outlined-second-native-simple",
            }}
          >
            <option value={option}>
              {t(`applicant.majors.${getMajorLabels(option)}`)}
            </option>
            {/* Compare the major list with selected values, if the value in selectedPrios it will not be displayed */}
            {renderList()}
            <option className={classes.clear} value="">
              {t("applicant.majors.clear")}
            </option>
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
};

export default SelectMenu;
