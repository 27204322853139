import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Register from "./auth/components/register";
import Login from "./auth/components/login";
import RedirectReset from "./auth/components/messages/RedirectReset";
import RegisterEnd from "./auth/components/registerationEnd/RegisterEnd";
import AdminLogin from "./auth/components/adminLogin";
import RegisterComplete from "./auth/components/messages/Registred";
import SentPW from "./auth/components/messages/SentPW";
import Forgot from "./auth/components/forgot/Forgot";
import HappyEid from "./common/components/HappyEid/HappyEid";
import EidAladha from "./common/components/eid_alAdha/Eid_alAdha";
import RamadanMubark from "./common/components/ramadan_mubark/Ramadan_mubark";
import NoUserFound from "./common/components/noUserFound/NoUserFound";
import SecondDashBoard from "./app/common/dashBoard/secondDashBoard";
import DB from "./firebase/services";
// server maintain
// import Serverdown from "./app/common/serverdown/Serverdown";
import App from "./app/";
import Loading from "./app/common/loading";

const IndexRoutes = () => {
  const [state, setState] = useState({ RegistrationOpen: "", forceReload: "" });

  useEffect(() => {
    DB.getConstants().on("value", handleConstants);
    return () => {
      DB.getConstants().off("value", handleConstants);
    };
    // eslint-disable-next-line
  }, []);

  const forceReloadHandler = (forceReload) => {
    if (+localStorage.getItem("RegistrationVersion") === +forceReload)
      localStorage.setItem("RegistrationVersion", +forceReload);
    else if (+localStorage.getItem("RegistrationVersion") !== +forceReload) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      localStorage.setItem("RegistrationVersion", +forceReload);
      window.location.reload(true);
    }
  };

  const handleConstants = (status) => {
    if (status.val()) {
      setState(status.val());
      status.val().forceReload && forceReloadHandler(status.val().forceReload);
    }
  };

  const renderLayout = () => {
    switch (state.RegistrationOpen) {
      case true:
        return (
          <Switch>
            <Route exact path="/mcst/state" component={SecondDashBoard} />
            <Route path="/user" component={NoUserFound} />

            <Route path="/applicant/login" component={Login} />
            <Route path="/applicant/register/success" component={RegisterComplete}/>
            <Route path="/applicant/register" component={Register} />

            <Route path="/super_applicant/login" component={Login} />
            <Route path="/super_applicant/register/success" component={RegisterComplete}/>
            <Route path="/super_applicant/register" component={Register} />

            <Route path="/applicant/reset/password/:token" component={RedirectReset}/>
            <Route path="/admin/login" component={AdminLogin} />
            <Route path="/forgot/success" component={SentPW} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/eid" component={HappyEid} />
            <Route path="/adha" component={EidAladha} />
            <Route path="/ramadan_mubark" component={RamadanMubark} />
            <Route path="/" component={App} />
          </Switch>
        );
      case false:
        return (
          <Switch>
            <Route exact path="/mcst/state" component={SecondDashBoard} />
            <Route path="/user" component={NoUserFound} />
            <Route path="/forgot/success" component={SentPW} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/applicant/reset/password/:token" component={RedirectReset}/>
            <Route path="/admin/login" component={AdminLogin} />


            <Route path="/applicant/login" component={Login} />
            <Route path="/applicant/register/success" component={RegisterComplete} />
            <Route path="/applicant/register" component={RegisterEnd} />

            <Route path="/super_applicant/login" component={Login} />
            <Route path="/super_applicant/register/success" component={RegisterComplete}/>
            <Route path="/super_applicant/register" component={Register} />

            
            <Route path="/eid" component={HappyEid} />
            <Route path="/adha" component={EidAladha} />
            <Route path="/ramadan_mubark" component={RamadanMubark} />
            <Route path="/" component={App} />
            {/* Server maintaine Message */}
            {/* <Route path="/" component={Serverdown} /> */}
          </Switch>
        );
      default:
        return <Loading />;
    }
  };

  return <div>{renderLayout()}</div>;
};

export default IndexRoutes;
