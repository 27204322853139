import Types from "./types";

const INITIAL_STATE = {
  errors: [],
  settingMajors: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_MAJORS_SUCCESS:
      return { errors: [], settingMajors: false };
    case Types.SET_MAJORS_REQUEST:
      return { errors: [], settingMajors: true };
    case Types.SET_MAJORS_ERROR:
      return { errors: [...action.payload], settingMajors: false };
    default:
      return state;
  }
};
