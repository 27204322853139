const GET_STUDENTS_SUCCESS = "get_students_success";
const GET_STUDENTS_ERROR = "get_students_error";
const GET_STUDENTS_PROGRESS = "get_students_progress";

const GET_PAID_STUDENTS_SUCCESS = "get_paid_students_success";
const GET_PAID_STUDENTS_ERROR = "get_paid_students_error";
const GET_PAID_STUDENTS_PROGRESS = "get_paid_students_progress";

const GET_STUDENT_SUCCESS = "get_student_success";
const GET_STUDENT_ERROR = "get_student_error";
const GET_STUDENT_PROGRESS = "get_student_progress";

const CHANGE_APP_STATE_ERROR = "change_applicant_state_error";
const CHANGE_APP_STATE_SUCCESS = "change_applicant_state_success";
const CHANGE_APP_STATE_REQUEST = "change_applicant_state_request";

const GET_ENGDATES_SUCCESS = "get_eng_dates_success";
const GET_ENGDATES_ERROR = "get_eng_dates_error";
const GET_ENGDATES_PROGRESS = "get_eng_dates_progress";

const GET_INTRDATES_SUCCESS = "get_intrview_dates_success";
const GET_INTRDATES_ERROR = "get_intrview_dates_error";
const GET_INTRDATES_PROGRESS = "get_intrview_dates_progress";

const SET_NEW_USER_REQUEST = "set_new_user_request";
const SET_NEW_USER_SUCCESS = "set_new_user_success";
const SET_NEW_USER_ERROR = "set_new_user_error";

const SET_DATES_PROGRESS = "set_dates_progress";

const SET_ANNCMNT_REQUEST = "set_announcement_request";
const SET_ANNCMNT_SUCCESS = "set_announcement_success";
const SET_ANNCMNT_ERROR = "set_announcement_error";

const CLEAN_ERROR = "clean_error";
const CLEAN = "clean_students table";

export default {
  // *==> Students <==*
  // fetch applicant
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_PROGRESS,
  // fetch paid applicants
  GET_PAID_STUDENTS_SUCCESS,
  GET_PAID_STUDENTS_ERROR,
  GET_PAID_STUDENTS_PROGRESS,
  // fetch single applicant
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_STUDENT_PROGRESS,
  // change applicant initial state
  CHANGE_APP_STATE_ERROR,
  CHANGE_APP_STATE_SUCCESS,
  CHANGE_APP_STATE_REQUEST,
  // *==> Dates <==*
  SET_DATES_PROGRESS,

  // English
  GET_ENGDATES_SUCCESS,
  GET_ENGDATES_ERROR,
  GET_ENGDATES_PROGRESS,

  // Interview
  GET_INTRDATES_SUCCESS,
  GET_INTRDATES_ERROR,
  GET_INTRDATES_PROGRESS,

  // *==> Announcement <==*
  SET_ANNCMNT_REQUEST,
  SET_ANNCMNT_SUCCESS,
  SET_ANNCMNT_ERROR,

  // *==> Create New User <==*
  SET_NEW_USER_REQUEST,
  SET_NEW_USER_SUCCESS,
  SET_NEW_USER_ERROR,

  // *==> Clean <==*
  CLEAN_ERROR,
  CLEAN,
};
